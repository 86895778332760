import * as React from "react";
import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import tools from "../../../Utils/tools";


const types = {
    '{int}' : 'intergerProperties',
    '{string}' : 'stringProperties'
}


export function CardStepInputType(props) {


    const intergerProperties = ()=>{
        return {
            type: 'number',
            validation:'intergerValidation',
            defaultValue:0,
            color:"#00a3d5",
            prefix:'',
            suffix:''
        }
    }
    const intergerValidation = (newValue)=>{
        let isOk = false
        if(!tools.isEmpty(newValue)){
            const regex = /^\d+(\.\d+)?$/;
            isOk = regex.test(newValue)
        }
        return isOk
    }


    const stringProperties = ()=>{
        return {
            type: 'text',
            validation:'',
            defaultValue:'',
            color:"#19ab00",
            prefix:'"',
            suffix:'"'

        }
    }

    const thisPropoerties= eval(types[props.type])();
    const [value, setValue] = useState(props.value)

    const getValue = ()=>{
        return value === null ? thisPropoerties.defaultValue : value
    }

    const validation = (newValue)=>{
        let isValidate = true;
        if(thisPropoerties.validation !== ''){
            isValidate = eval(thisPropoerties.validation)(newValue)
        }
        return isValidate
    }

    const displayInformation = ()=>{
        if(props.isDisabled){
            return <span
                style={{
                    color:thisPropoerties.color
                }}
            >{
                thisPropoerties.prefix + getValue()+ thisPropoerties.suffix
            }</span>
        }
        else{
            return <TextField
                type={thisPropoerties.type}
                value={getValue()}
                onInput={(e)=>{
                    if(validation(e.target.value)){
                        setValue(e.target.value)
                        props.changeInfo(props.index, e.target.value)
                    }
                }}
                variant="standard"

            >

            </TextField>
        }
    }




    return (
    <>
        {displayInformation()}
    </>
    )
}