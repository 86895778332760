import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {useEffect} from "react";
import tools from "../../../Utils/tools";

/**
 * Retourne la liste des éléments qui ne sont pas en communs
 * @param listInfo
 * @param listSelected
 * @returns {*}
 */
function not(listInfo, listSelected) {
    // return a.filter((value) => b.indexOf(value) === -1);
    return listInfo.filter((value) => {

            let element = tools.getFirstElement(listSelected, (elem, i) => {
                return elem === value.code
            })
            return element === null
        }
    )
}


/**
 * Retourne la liste des éléments en communs
 * @param listInfo
 * @param listSelected
 * @returns {*}
 */
function intersection(listInfo, listSelected) {
    return listInfo.filter((value) => tools.getFirstElement(listSelected, (elem, i) => {
        return elem === value.code
    }));
}

export default function TransfertButtons(props) {

    const resetCheckboxLeft = () => {
        props.setResetCheckboxLeft(props.resetCheckboxLeft + 1)
    }

    const resetCheckboxRight = () => {
        props.setResetCheckboxRight(props.resetCheckboxRight + 1)

    }
    const handleAllRight = () => {
        props.setListRight(props.listRight.concat(props.listLeft))
        props.setListLeft([])
        props.setListLeftSelected([])

        resetCheckboxLeft()
    };


    const handleCheckedRight = () => {
        const langSelected = intersection(props.listLeft, props.listLeftSelected);
        const newListLeft = not(props.listLeft, props.listLeftSelected);

        props.setListRight(props.listRight.concat(langSelected))
        props.setListLeft(newListLeft)
        props.setListLeftSelected([])

        props.setDisableLeftToRight(true)

        resetCheckboxLeft()
    };

    const handleCheckedLeft = () => {
        const langSelected = intersection(props.listRight, props.listRightSelected);
        const newListRight = not(props.listRight, props.listRightSelected);
        props.setListLeft(props.listLeft.concat(langSelected))
        props.setListRight(newListRight)
        props.setListRightSelected([])

        props.setDisableRightToLeft(true)
        resetCheckboxRight()
    };

    const handleAllLeft = () => {
        props.setListLeft(props.listLeft.concat(props.listRight))

        props.setListRight([])
        props.setListRightSelected([])

        resetCheckboxRight()
    };


    return (

        <Grid container direction="column" alignItems="center">
            {
                props.allButtons === true && (
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={props.disableAllLeftToRight}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                )
            }
            <Button
                sx={{my: 0.5}}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={props.disableLeftToRight}
                aria-label="move selected right"
            >
                &gt;
            </Button>
            <Button
                sx={{my: 0.5}}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={props.disableRightToLeft}
                aria-label="move selected left"
            >
                &lt;
            </Button>
            {
                props.allButtons === true && (
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={props.disableAllRightToLeft}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                )
            }
        </Grid>
    );
}