import {createContext, useContext, useEffect, useReducer, useRef, useState} from 'react';

const WebSocketContext = createContext(null)
//                                                                         ready, value, send
// Make sure to put WebsocketProvider higher up in
// the component tree than any consumer.
let ws = null

function WebSocketProvider({children}) {

    // const [ws, setWs] = useState(null);
    const [lastMsg, setLastMsg] = useState(null);
    const [lastMsgSend, setLastMsgSend] = useState('');
    const [wsIsConnected, setWsIsConnected] = useState(false);
    const [userIsAfk, setUserIsAfk] = useState(false);
    const channels = useRef({})

    const send = (msgObject, callback) => {
        //create unique Id
        let uniqueId = msgObject.path + "_";
        uniqueId += Date.now() + "_";
        uniqueId += Math.random();
        console.log(uniqueId)
        msgObject["id"] = uniqueId;

        subscribe(msgObject.id, callback)
        ws.send(JSON.stringify(msgObject))

        return uniqueId
    }

    const subscribe = (id, callback) => {
        channels.current[id] = callback
    }

    /* remove callback  */
    const unsubscribe = (id) => {
        delete channels.current[id]
    }

    useEffect(() => {
        if (ws === null) {
            ws = (new WebSocket("ws://75.119.139.248:8082"))
            // ws = (new WebSocket("ws://localhost:8082"))
        } else {
            // checkInactivity();
        }
    }, [])

    useEffect(() => {
        if (ws !== null) {
            ws.onopen = () => {
                console.log("WebSocket connecté !");
                setWsIsConnected(true)
                setUserIsAfk(false)
            };
            ws.onclose = () => {
                console.log("WebSocket closed !");
                setWsIsConnected(false)
            };
            ws.onmessage = (msgString) => {
                const msg = JSON.parse(msgString.data)

                if (channels.current[msg.id]) {
                    channels.current[msg.id](msg)
                } else {
                    channels.current[msg.id]?.(msg)
                }
            }
            ws.onerror = (msg) => {
                console.error(msg)
            }
        }
    }, [ws])

    function wsSend(msg) {

        if (ws !== null && lastMsgSend !== msg) {
            setLastMsgSend(msg)
            ws.send(msg)
        }
    }

    function checkInactivity() {
        let lastActiveTime = Date.now();

        setInterval(() => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - lastActiveTime;

            if (elapsedTime > 10 * 60 * 1000) { // 10 minutes in milliseconds
                console.log('User is inactive');
                ws.close()
                setWsIsConnected(false)
                setUserIsAfk(true)
            }
        }, 1000); // Check every second
    }


    // async function lastMsg() {
    //     let msg = ''
    //     if (ws !== null && wsIsConnected) {
    //         console.log(ws.onmessage)
    //     }
    //     console.log(msg)
    //     return msg
    // }


    return (
        <WebSocketContext.Provider
            value={{ws, lastMsg, setLastMsg, subscribe, wsIsConnected, userIsAfk, send, unsubscribe}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export {WebSocketContext, WebSocketProvider};
