import {createContext, useContext, useEffect, useState} from 'react';
import {WebSocketContext} from "./WebSocketContext";
import tools from "../Utils/tools";

const LanguageContext = createContext(null)

function LanguageProvider({children}) {

    const _webSocketContext = useContext(WebSocketContext)

    const [lang, setLang] = useState(navigator.language);
    const [availableLangList, updateAvailableLangList] = useState([]);
    const [allLangList, setAllLangList] = useState([]);



    const setDefaultLang = (defaultLang) => {

        if(availableLangList.indexOf(defaultLang) ===-1){
            defaultLang = 'en'
            if(availableLangList.indexOf(defaultLang) ===-1){
                defaultLang = availableLangList[0]
            }
        }
        const updateDefaultLang = {
            path: 'global_configuration/update/default_language',
            info: {
                default_language: defaultLang
            }
        }

        const updateDefaultLangId = _webSocketContext.send(updateDefaultLang, (message)=>{ })

        setLang(defaultLang)
        return lang;
    }

    const setAvailableLangList = (languages) =>{
        updateAvailableLangList(languages)

        const updateAvailableLang = {
            path: 'global_configuration/update/available_language',
            info: {
                available_language: languages
            }
        }

        const updateAvailableLangId = _webSocketContext.send(updateAvailableLang, (message)=>{ })
        //_webSocketContext.unsubscribe(updateAvailableLangId)

    }

    /**
     * Retourne la liste des éléments qui ne sont pas en communs
     * @param listInfo
     * @param listSelected
     * @returns {*}
     */
    function not(listInfo, listSelected) {
        // return a.filter((value) => b.indexOf(value) === -1);
        return listInfo.filter((value) => {

                let element = tools.getFirstElement(listSelected, (elem, i) => {
                    return elem === value.code
                })
                return element === null
            }
        )
    }

    /**
     * Retourne la liste des éléments en communs
     * @param listInfo
     * @param listSelected
     * @returns {*}
     */
    function intersection(listInfo, listSelected) {
        return listInfo.filter((value) => tools.getFirstElement(listSelected, (elem, i) => {
            return elem === value.code
        }));
    }

    return (
        <LanguageContext.Provider value={{lang, setDefaultLang, availableLangList, setAvailableLangList, allLangList,setAllLangList, not , intersection}}>
            {children}
        </LanguageContext.Provider>
    );
}

export {LanguageContext, LanguageProvider};
