import {createContext, useContext, useEffect, useState} from 'react';
import {WebSocketContext} from "./WebSocketContext";

const LicenceContext = createContext(null)

function LicenceProvider({children}) {
    const _webSocketContext = useContext(WebSocketContext)

    const [licenceIsCreate, setLicenceIsCreate] = useState(false)
    const [globalDefaultLanguage, setGlobalDefaultLanguage] = useState('en')
    const [tutorial, setTutorial] = useState({})

    const [allInfo, setAllInfo] = useState(null)


    const finishFirstConnection = () => {
        setLicenceIsCreate(true)


        const tmpAllInfo = {
            ...allInfo,
            tutorial: {
                first_connection: {
                    finish: true,
                    step: tutorial.first_connection.step
                }
            }
        }

        setAllInfo(tmpAllInfo)

        const updateTuto = {
            path: 'global_configuration/update/tutorial',
            info: tmpAllInfo

        }

        const updateTutoId = _webSocketContext.send(updateTuto, (message) => {
        })
        //_webSocketContext.unsubscribe(updateTutoId)
    }

    const initialize = (info) => {
        setGlobalDefaultLanguage(info.default_language)
        setTutorial(info.tutorial)
        setLicenceIsCreate(info.tutorial.first_connection.finish)
        setAllInfo(info)
    }


    const changeStepFirstConfig = (step) => {
        let tuto = tutorial

        tuto.first_connection.step = step
        setTutorial(tuto)

        const tmpAllInfo = {
            ...allInfo,
            tutorial: {
                first_connection: {
                    finish: licenceIsCreate,
                    step: step
                }
            }
        }

        setAllInfo(tmpAllInfo)

        const updateTuto = {
            path: 'global_configuration/update/tutorial',
            info: tmpAllInfo
        }


        const updateTutoId = _webSocketContext.send(updateTuto, (message) => {
        })
        //_webSocketContext.unsubscribe(updateTutoId)
    }

    return (
        <LicenceContext.Provider value={{
            tutorial,
            changeStepFirstConfig,
            licenceIsCreate,
            globalDefaultLanguage,
            finishFirstConnection,
            initialize,
            allInfo
        }}>
            {children}
        </LicenceContext.Provider>
    );
}

export {LicenceContext, LicenceProvider};
