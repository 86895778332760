import Typography from "@mui/material/Typography";
import * as React from "react";
import {Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import Box from "@mui/material/Box";

import NumberTicker from "../Components/MagicUi/NumberTicker";
import {ProjectContext} from "../Context/Hook/ProjectContext";
import {useContext} from "react";
import {UserContext} from "../Context/UserContext";
import ReactEcharts from "echarts-for-react";
export function DashboardMain(props) {
    const _project = useContext(ProjectContext)
    const _userContext = useContext(UserContext)

    let data = []
    let legend = []
    let date = new Date()
    let dates = []
    let totalTime = 0
    let cumulTime = 0
    let medianTime = 0

    for (let i = 0; i < 30; i++) {
        const formattedDate = `${date.getDate() < 10 ? '0' + date.getDate(): date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
        dates.push(formattedDate)
        date.setDate(date.getDate() - 1)
    }
    dates =dates.reverse()

    _project.listProject.forEach((project) => {
        let projectData = []

        for (let i = 0; i < 30; i++) {
            // const time = 300
            const time = Math.floor(Math.random() * 500)
            projectData.push(time)
            totalTime++
            cumulTime+=time
        }

        data.push({
            name: project.lang[_userContext.userlang()],
            type: 'line',
            smooth: true,
            data: projectData,
        })

        legend.push(project.lang[_userContext.userlang()])
    })


    medianTime = cumulTime/totalTime






    const option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: legend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: dates
        },
        yAxis: {
            type: 'value'
        },
        series: data
    };



    return (
        <Box width={'100%'}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}

            >
                <Grid item lg={9} md={12} xs={12} flexGrow={1}>

                    <Stack
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                        width={'100%'}
                        spacing={2}
                    >
                        <Card
                            sx={{
                                // minHeight: '25vh',
                                width: '100%'
                            }}
                        >
                            <CardContent>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{
                                            width: '100%'
                                        }}
                                    >
                                        <Typography>
                                            Exécution du jour
                                        </Typography>

                                        <FormControl fullWidth={false}>
                                            <InputLabel id="label-exec-day">Tous</InputLabel>
                                            <Select
                                                labelId={'label-exec-day'}
                                                label={'test'}
                                                // value={age}
                                                // onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        // width={'100%'}
                                        flexGrow={1}
                                    >
                                        <Grid item xs
                                        >
                                            <Card
                                                sx={{
                                                    width: '150px',
                                                    height: '150px',
                                                    border: '1px solid blue',
                                                    margin: 'auto'
                                                }}>
                                                total exécuté
                                            </Card>
                                        </Grid>
                                        <Grid item xs
                                        >

                                            <Card
                                                sx={{
                                                    width: '150px',
                                                    height: '150px',
                                                    border: '1px solid blue',
                                                    margin: 'auto'
                                                }}>
                                                Total réussi
                                            </Card>
                                        </Grid>
                                        <Grid item xs>
                                            <Card
                                                sx={{
                                                    width: '150px',
                                                    height: '150px',
                                                    border: '1px solid blue',
                                                    margin: 'auto'
                                                }}>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography>
                                                        Temps moyen
                                                    </Typography>

                                                    <Box>
                                                        <NumberTicker
                                                            size={50}
                                                            value={medianTime}
                                                        />
                                                        s
                                                    </Box>

                                                </Stack>
                                            </Card>
                                        </Grid>
                                        <Grid item xs>
                                            <Card
                                                sx={{
                                                    width: '150px',
                                                    height: '150px',
                                                    border: '1px solid blue',
                                                    margin: 'auto'
                                                }}>
                                                Progression
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                width: '100%',
                                paddingBottom:'50px'
                            }}>

                            <ReactEcharts option={option} />
                        </Card>
                    </Stack>
                </Grid>
                <Grid item md={12} lg={3}  flexGrow={1}

                      sx={{
                          height: '100%'
                      }}>
                    <Card
                        sx={{
                            width: '100%',
                            height: '100%',
                            padding: 'auto'
                        }}
                    >

                        <CardContent
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Stack direction={'column'} spacing={2}>
                                <Typography style={{width:'100%', textAlign:'left'}}>
                                    Dernier projet exécuté
                                </Typography>
                                <Grid
                                    container
                                    sx={{
                                        width: '100%'
                                    }}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}

                                >
                                    <Grid item>
                                        <Card
                                            sx={{
                                                width: '150px',
                                                height: '150px',
                                                border: '1px solid blue'
                                            }}>
                                            Status dernier exécution
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card
                                            sx={{
                                                width: '150px',
                                                height: '150px',
                                                border: '1px solid blue'
                                            }}>
                                            Dermier log
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </CardContent>
                    </Card>

                </Grid>

            </Grid>
        </Box>

    )
}
