import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import DialogContext from "@mui/material/Dialog/DialogContext";
import Box from "@mui/material/Box";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {StepOne} from "./StepOne";
import Divider from "@mui/material/Divider";
import {StepTwo} from "./StepTwo";
import {StepThree} from "./StepThree";
import {StepFour} from "./StepFour";
import {TranslationContext} from "../../../../../Context/TranslationContext";
import {LicenceContext} from "../../../../../Context/LicenceContext";

export function StepMenu(props) {

    const {translation} = useContext(TranslationContext);
    const _licenceContext = useContext(LicenceContext)

    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'FirstConfig.json', variables)
    }


    const handleDialogClose = () => {
        props.setDialogOpen(false)
    }
const firstStep = ()=>{
    let step =_licenceContext.tutorial.first_connection.step

    return step ===-1 ? 0 : step
}
    const [activeStep, setActiveStep] = useState(firstStep())
    const [disableButtonNext, setDisableButtonNext] = useState(true)

    const changeToNextStep = () => {
        if (!disableButtonNext) {
            _licenceContext.changeStepFirstConfig(activeStep+1)
            if((activeStep+1) === steps.length){
                _licenceContext.finishFirstConnection()
                props.setLoadState(2)
            }
            setActiveStep(activeStep + 1)
            setDisableButtonNext(true)
        }
    }
    const changeToBackStep = () => {
        if (activeStep > 0) {
            _licenceContext.changeStepFirstConfig(activeStep-1)
            setActiveStep(activeStep - 1)
        }
    }

    const steps = [
        t('first_config.steps.choose_languages'),
        t('first_config.steps.default_language'),
        'Création des actions par défaut',
        t('first_config.steps.video'),
    ];

    const txtNextBtn = ()=>{
        let txt = translation('common.next', 'Common.json')

        if((activeStep+1) === steps.length){
            txt = translation('common.finish', 'Common.json')
        }
        return txt
    }


    const initFirstConfig = () => {

        switch (activeStep) {
            case 0:
                return <StepOne setDisableButtonNext={setDisableButtonNext}></StepOne>
            case 1:
                return <StepTwo setDisableButtonNext={setDisableButtonNext}></StepTwo>
            case 2:
                return <StepThree setDisableButtonNext={setDisableButtonNext}></StepThree>
            case 3:
                return <StepFour setDisableButtonNext={setDisableButtonNext}></StepFour>
        }

    }


    return (
        <>

            <DialogContent>
                <Box sx={{width: '100%'}}>
                    <Stepper activeStep={activeStep} alternativeLabel style={{marginBottom: '15px'}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Divider  style={{margin: '15px 20px'}} />
                    {initFirstConfig()}
                </Box>
            </DialogContent>
            <DialogActions  sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={changeToBackStep}
                    sx={{mr: 1}}
                    variant="outlined"
                >
                    {translation('common.back', 'Common.json')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    disabled={disableButtonNext}
                    onClick={changeToNextStep}
                    variant="outlined"
                    sx={{ mr: 1 }}

                >
                    {txtNextBtn()}
                </Button>
            </DialogActions>
        </>
    )

}
