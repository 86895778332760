import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {WebSocketContext} from "../../../Context/WebSocketContext";
import VideoPlayer from "../../Dialog/VideoPlayer/VideoPlayer";
import {useContext, useEffect, useState} from "react";

export default function DialogVideoRealTime(props) {
    const _webSocketcontext = useContext(WebSocketContext)
    const lastMsg = _webSocketcontext.lastMsg

    const [progressLoading, setProgressLoading] = useState(props.url === '' ? 0 : 100)
    const [playerUrl, setPlayerUrl] = useState(props.url)
    const [playerIsOpen, setPlayerIsOpen] = useState(props.url !== '')
    const [playerText, setPlayerText] = useState('')
    const [playerTextColor, setPlayerTextColor] = useState('grey')
    const [displayStep, setDisplayStep] = useState('')

    useEffect(() => {
        if(props.playTestWs !==null) {
            switch (props.playTestWs.code_execution) {
                case "0001": //create container
                    setPlayerIsOpen(false)
                    setDisplayStep('')
                    setPlayerUrl('')
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(14.3)
                    break; //get container info
                case "00011": //create container
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(28.6)
                    break; //get container info
                case "00012": //create container
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(42.9)
                    break; //get container info
                case "00013": //create container
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(57.2)
                    break; //get container info
                case "00014": //create container
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(71.5)
                    break; //get container info
                case "0002":
                    setPlayerText(props.playTestWs.message)
                    setPlayerUrl(props.playTestWs.info.live_url)
                    setProgressLoading(85.8)
                    break;
                case "00021":
                    setPlayerText(props.playTestWs.message)
                    setProgressLoading(95)
                    break;
                case "00022":
                    setProgressLoading( )
                    break;
                case "0003":
                    setPlayerIsOpen(true)
                    break;
                case "0005":
                    setDisplayStep(props.playTestWs.message)
                    break;
                case "0006":
                    setDisplayStep(props.playTestWs.message)
                    if (props.playTestWs.message === 'Success') {
                        setPlayerTextColor('green')
                    } else {
                        setPlayerTextColor('red')
                    }
                    break;
            }
        }
    }, [props.playTestWs]);

    useEffect(() => {
        setPlayerUrl(props.url)
    }, [props.url]);

    const handleClose = () => {
        setPlayerIsOpen(false)
        props.setIsOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={props.isOpen}
            onClose={handleClose}
        >
            <DialogTitle>Real Time execute</DialogTitle>
            <DialogContentText
                color={playerTextColor}
                style={{
                    textAlign: "center",
                    width: '100%',
                }}>{displayStep}</DialogContentText>
            <DialogContent
                style={{
                    textAlign: "center",
                    width: '100%'
                }}>

                <VideoPlayer
                    open={playerIsOpen}
                    text={playerText}
                    url={playerUrl}
                    progress={progressLoading}
                    type={'flv'}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}