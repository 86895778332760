import * as React from 'react';
import {Card} from "@mui/material";
import {useEffect, useState} from "react";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Typography from "@mui/material/Typography";


export default function StepVideo(props) {
    const [colorCss, setColorCss] = useState('greyTimeline')
    const [icon, setIcon] = useState(<></>)

    useEffect(() => {
        let color = 'greyTimeline';
        let iconInfo = <Brightness1Icon color={'greyTimeline'}/>;
        if (props.currentStep.toString() <= props.id.toString() && props.currentStep !== -1) {
            color = (props.currentStep.toString() === props.id.toString() ? 'info' : 'success')
            iconInfo = (props.currentStep.toString() === props.id.toString() ? <PlayArrowIcon color={"txtTimeline"}/> :
                <CheckIcon color={"txtTimeline"}/>)
        }
        setColorCss(color)
        setIcon(iconInfo)

    }, [props.currentStep]);

    const changeTimeVideo = (time) => {
        console.log(time)
        props.timeTo(time)
    }

    return (

        <TimelineItem>
            <TimelineOppositeContent style={{maxWidth: "10%", paddingLeft: '0px', paddingRight: '0px'}}
                                     color="text.secondary">
                {(props.time / 1000).toFixed(2) + ' s'}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color={colorCss}>
                    {icon}
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent onClick={() => {
                changeTimeVideo(props.time / 1000)
            }}>

                <Typography variant="h6" component="span">
                    Step
                </Typography>

                <Typography>{props.txt}</Typography>
            </TimelineContent>
        </TimelineItem>

    )
}