import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {useEffect} from "react";
import tools from "../../../Utils/tools";
import TransfertCheckbox from "./TransfertCheckbox";


export default function TransfertList(props) {

    function handleToggle(value, isChecked) {
        let newNbSelected = props.elemSelected;
        const currentIndex = newNbSelected.indexOf(value)


        if (isChecked && currentIndex === -1) {
            newNbSelected.push(value)
        } else if(currentIndex !== -1){
            newNbSelected.splice(currentIndex);
        }
        props.setElemSelected(newNbSelected)
    }


    return (
        <Paper sx={{width: '100%', height: '60vh', overflow: 'auto'}} >
            <List dense component="div" role="list">
                {
                    props.listElem.map((value) => {
                        const labelId = `transfer-list-item-${value.code}-label`;
                        return (
                            <TransfertCheckbox
                                resetCheckbox={props.resetCheckbox}
                                element={value}
                                label={labelId}
                                handleToggleCheck={handleToggle}
                                disabled={
                                    props.disabledCheckbox
                                }
                            />
                        );
                    })}
            </List>
        </Paper>
    );
}