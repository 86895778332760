import * as React from 'react';
import {Editor, useMonaco} from "@monaco-editor/react";
import "./developmentEditor.css";
import {useContext, useEffect, useRef, useState} from "react";
import {constrainedEditor} from "constrained-editor-plugin";
import ShowAutocompletion from "../../Utils/Monaco-HelperSuggest";
import Button from "@mui/material/Button";
import tools, {isEmpty} from "../../Utils/tools";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/material";
import {WebSocketContext} from "../../Context/WebSocketContext";
import {LanguageContext} from "../../Context/LanguageContext";
import {UserContext} from "../../Context/UserContext";


var canChangeVarFunction = false
var canChangeVarGlobal = false
export default function DevelopmentEditor(props) {

    const _webSocketContext = useContext(WebSocketContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)


    const [init, setInit] = useState(true)
    const [localVar, setLocalVar] = useState([])
    const [currentTxt, setCurrentTxt] = useState('')
    const [currentEdits, setCurrentEdit] = useState(null)
    const [key, setKey] = useState(0)

    let initialGlobalVar = '\n\n';
    if (props.varGlob.length > 0) {
        let vars = []
        props.varGlob.forEach((element)=>{
            vars.push(element.name)
        })

        initialGlobalVar = "import {global_variable} from './global_variables.js';\n\n"
    }
    const setSaveDevFunc = () => {
        let func = props.actionDev.function;
        let start = func.indexOf('{')
        let end = func.lastIndexOf('}')
        let code = func.slice(start + 2, end);
        let firstUnderLine = func.indexOf('\n')

        if (func.length === 0) {
            code = '    //TODO: Write your test code here.\n\n';
        }
        if (firstUnderLine !== 0) {
            code = '\n' + code;
        }
        return code
    }

    let txt = initialGlobalVar;
    txt += "const {By, Builder} = require('selenium-webdriver');\n";
    txt += "const {Helper} = require('./helper.js');\n";
    txt += '\n/**\n' +
        ' * Function that execute this test step.\n' +
        ' * @return void' +
        '\n*/';
    txt += '\nasync function test(' + localVar.join(', ') +') {' +
        '' +
        setSaveDevFunc() +
        '}';

    //


    useEffect(() => {
        if (init) {
            setInit(false)
            tools.sleep(500).then(() => {
                matchTitle()
            })

        }
    }, [])


    const editorRef = useRef(null);

    function handleEditorDidMount(editor, monaco, internal) {

        let startEditLine = 11
        let endEditLine = ((txt.match(new RegExp("\n", "g")) || []).length + 1)
        let restrictions = [];

        editorRef.current = editor;
        const constrainedInstance = constrainedEditor(monaco);
        const model = editor.getModel();
        constrainedInstance.initializeIn(editorRef.current);
        constrainedInstance.toggleDevMode();

        const lineVarGlobal = editorRef.current.getModel().getLineLength(1);
        const lineVarFunction = editorRef.current.getModel().getLineLength(10);

        /*
          Modification possible à partire de la ligne 2 colonne 1 jusqu'a ligne 2 colonne 13
         */
        restrictions.push({
                range: [startEditLine, 1, endEditLine, 1],
                allowMultiline: true,
                label: 'userRestriction'
            },

            {
                range: [10, 1, 10, lineVarFunction],
                allowMultiline: true,
                label: 'internalRestrictionLocal'
            });
        constrainedInstance.addRestrictionsTo(model, restrictions);

        ShowAutocompletion(monaco, {
            Helper: {
                clickById: "Click on element by CSS id.",
                clickByXpath: 'Click on element by CSS XPath.'
            }
        })
    }


    function onChange(newValue, e) {

        const returnLine = e.eol
        const lines = newValue.split(returnLine)

        let functionCore = 'async (' + localVar.join(', ') + ') => {\n';
        for (let i = 10; i < lines.length; i++) {
            functionCore += lines[i] + '\n';
        }

        let newActionDev = {...props.actionDev}
        newActionDev.function = functionCore
        console.log(newActionDev)
        props.setActionDev(newActionDev)


    }


    useEffect(() => {
        if (props.helperClickBtn) {
            addHelperText(props.helperClickBtnInfo)
            props.setHelperClickBtn(false)
        }
    }, [props.helperClickBtn]);

    function addHelperText(txt) {
        const position = editorRef.current.getPosition()
        const lastLine = editorRef.current.getModel().getLineCount();

        if (position.lineNumber > 10 && position.lineNumber < lastLine) {
            const edits = [{
                range: {
                    startLineNumber: position.lineNumber,
                    startColumn: position.column,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column,
                },
                text: txt,
                forceMoveMarkers: true
            }];
            editorRef.current.executeEdits('my-source', edits);
        }

    }

    // function changeVarGlobal() {
    //     let tmpGlobalVar = props.varGlob;
    //     // tmpGlobalVar.push('toto')
    //     // setGlobalVar(tmpGlobalVar)
    //
    //     // console.log(editorRef.current)
    //     // if(editorRef.current !== null) {
    //     //     canChangeVarGlobal = true
    //     //     const lineLength = editorRef.current.getModel().getLineLength(1);
    //     //     const edits = [{
    //     //         range: {
    //     //             startLineNumber: 1,
    //     //             startColumn: 1,
    //     //             endLineNumber: 1,
    //     //             endColumn: lineLength,
    //     //         },
    //     //         text: "import {" + tmpGlobalVar.join(', ') + "} from './global_variables.js'"
    //     //     }];
    //     //     editorRef.current.executeEdits('userRestriction', edits);
    //     // }
    // }


    const matchTitle = () => {
        const matchNameVar = props.actionDev.lang[_userContext.userlang().toLowerCase()].match(/{string}|{int}/gm)
        let variablesName = []
        if (matchNameVar !== null) {
            matchNameVar.forEach((v, i) => {

                let varName = ''
                switch (v) {
                    case '{string}' :
                        varName = 'string'
                        break;
                    case '{int}' :
                        varName = 'int'
                        break;
                }
                varName += (i + 1)
                variablesName.push(varName)
            })
        }
        setLocalVar(variablesName)
    }
    useEffect(() => {

        if (props.actionDev.lang[_languageContext.lang.toLowerCase()] !== currentTxt) {
            setCurrentTxt(props.actionDev.lang[_languageContext.lang.toLowerCase()])
            matchTitle()
        }

    }, [props.actionDev])

    useEffect(() => {
        if (editorRef.current !== null) {

            reloadEditor(localVar)
        }

    }, [localVar, init, props.varGlob])



    function reloadEditor(variablesName) {
                setKey(key+1)

        // canChangeVarFunction = true
        // const lineLength = editorRef.current.getModel().getLineLength(10);
        //
        // if (!(variablesName.length === 0 && lineLength !== 23)) {
        //     const edits = [{
        //         range: {
        //             startLineNumber: 10,
        //             startColumn: 1,
        //             endLineNumber: 10,
        //             endColumn: lineLength - 1,
        //         },
        //         text: 'async function test(' + variablesName.join(', ') + ') '
        //     }];
        //
        //     if (!init) {
        //
        //         setCurrentEdit(edits)
        //
        //         setKey(key+1)
        //     }
        // }
    }

    useEffect(() => {
        if (canChangeVarFunction && !isEmpty(currentEdits)) {

            tools.sleep(50).then(() => {
                // canChangeVarFunction = false
                editorRef.current.executeEdits('internalRestrictionLocal', currentEdits)
                canChangeVarFunction = false;
            })
        }
    }, [canChangeVarFunction]);

    return (
        <>
            <Editor
                key={key}
                defaultLanguage="javascript"
                defaultValue={txt}
                options={{
                    minimap: {enabled: false}

                }}
                onChange={onChange}
                onMount={handleEditorDidMount}

            />


        </>
    )
}