import React, {memo, useContext, useState} from 'react';
import {Position} from '@xyflow/react';
import NodeCustomHandle from '../NodeCustomHandle/NodeCustomHandle';
import {Card, Stack, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import tools from "../../../Utils/tools";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";

export const NodeCustomEndPath = (props) => {
    return (
        <Card className={'dndnode  react-react-flow__node-custom  aside dndnodeext endPath'} style={{
            minWidth: '150px',
            textAlign: 'center'
        }}>
            <NodeCustomHandle className={'target endPath'} type="target" position={Position.Top} isConnectable={1}
            />

            <Typography variant={'body1'} color={'white'}>
                {props.data.label}
            </Typography>
        </Card>
    );
};

export default memo(NodeCustomEndPath);
