import * as React from "react";
import {CardProject} from '../Components/Card/Project/CardProject';
import Box from "@mui/material/Box";
import {Card, Grid, Skeleton} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import tools from "../Utils/tools";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {CardNewProject} from "../Components/Card/Project/CarNewProject";
import {InputSearch} from "../Components/InputSearch";
import {WebSocketContext} from "../Context/WebSocketContext";
import {LanguageContext} from "../Context/LanguageContext";

export function Projects(props) {

    const [searchValue, setSearchValue] = useState('');
    const [createNewProject, setCreateNewProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectsFiltred, setProjectsFiltred] = useState(projects);
    const [pageIsLoading, setPageIsLoading] = useState(true);
    const [init, setInit] = useState(false);
    const _languageContext = useContext(LanguageContext)
    const _webSocketContext = useContext(WebSocketContext)


    const wsGetProjects = () => {


        const projectList = {
            path: 'project/list'
        }

        const projectListId = _webSocketContext.send(projectList, (message) => {
            setProjects(message.info.projects)
            setPageIsLoading(false)
        })
        //_webSocketContext.unsubscribe(projectListId)
    }

    if (!init) {
        wsGetProjects()
        setInit(true)
    }


    useEffect(() => {
        handleSearch(searchValue)
    }, [projects]);


    const handleSearch = (e) => {
        setSearchValue(e)
        let filteredData = projects;
        if (!tools.isEmptyOrUndefined(e)) {
            filteredData = projects.filter((item) => {
                let name = item.lang[_languageContext.lang.toLowerCase()]
                name = name === undefined ? '' : name
                return name.toLowerCase().includes(e.toLowerCase());
            });
        }
        setProjectsFiltred(filteredData)
    };

    const openNewProject = () => {
        setCreateNewProject(true)
    }

    const closeNewProject = () => {
        setCreateNewProject(false)
    }

    return (
        <Box
            width={'100%'}
        >
            <InputSearch handleSearch={handleSearch} searchValue={searchValue}  btnAdd={true} addElement={openNewProject}/>


            {/*<Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*}}>*/}
            {/*    <Box sx={{*/}
            {/*        display: 'block',*/}
            {/*        // alignItems: 'center',*/}
            {/*        width: '90%'*/}
            {/*    }}>*/}
            {/*        <InputSearch handleSearch={handleSearch} searchValue={searchValue}/>*/}
            {/*    </Box>*/}
            {/*    <Box sx={{margin: 'auto'}}>*/}
            {/*        <Button onClick={openNewProject}>Ajouter</Button>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            {/*<Divider*/}
            {/*    sx={{*/}
            {/*        margin: '10px'*/}
            {/*    }}/>*/}

            <Box>
                <Grid
                    container
                    spacing={3}
                    justifyContent={'left'}
                >
                    {
                        createNewProject && !pageIsLoading && (
                            <Grid item xs>
                                <CardNewProject setCurrentProject={props.setCurrentProject}></CardNewProject>
                            </Grid>
                        )
                    }
                    {
                        pageIsLoading && (
                            <>
                                <Grid item xs>
                                    <Skeleton variant="rounded" width={345} height={260}/>
                                </Grid>
                                <Grid item xs>
                                    <Skeleton variant="rounded" width={345} height={260}/>
                                </Grid>
                                <Grid item xs>
                                    <Skeleton variant="rounded" width={345} height={260}/>
                                </Grid>
                            </>
                        )
                    }

                    {
                        !pageIsLoading && (projectsFiltred.map((value, index) => (
                            <Grid item xs>
                                <CardProject
                                    title={value.lang}
                                    project_id={value._id}
                                    setCurrentProject={props.setCurrentProject}
                                />
                            </Grid>
                        )))}
                </Grid>
            </Box>

        </Box>
    )
}
