import * as React from "react";
import {createRef, useContext, useEffect, useState} from "react";
import {
    Card, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Tab,
    TextField
} from "@mui/material";
import CardDeveloper from "../../Components/Card/developer/CardDeveloper";


export function CategoryPanel(props) {

    const [categSelect, setCategSelect] = useState(null)
    return (
        <>
            <Grid container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="space-around"
            >
                <Grid Item  xs={3.5} minWidth={"200px"}>
                    <CardDeveloper card={'category_action'} ></CardDeveloper>
                </Grid>
                <Grid Item  xs={3.5} minWidth={"200px"}>
                    <CardDeveloper card={'category_test_case'}></CardDeveloper>
                </Grid>
            </Grid>
        </>
    )
}
