import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useNavigate} from "react-router";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import {useContext} from "react";
import {TranslationContext} from "../../../Context/TranslationContext";

export default function CardDeveloper(props) {
    const navigate = useNavigate();
    const {translation} = useContext(TranslationContext);

    const cards = {
        category : {
            title:'admin.panel.category',
            text: 'admin.panel.category.description',
            icon: <BookmarksOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></BookmarksOutlinedIcon>,
            href:'/category_panel',
            context:'DevPanel.json'
        },
        category_action : {
            title:'admin.panel.category_action',
            text: 'admin.panel.category_action.description',
            icon: <DataObjectOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></DataObjectOutlinedIcon>,
            href:'/category_list',
            context:'DevPanel.json'
        },
        category_test_case : {
            title:'admin.panel.category_test_case',
            text: 'admin.panel.category_test_case.description',
            icon: <ListOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}/>,
            href:'/category_list',
            context:'DevPanel.json'
        },
        action : {
            title:'admin.panel.action',
            text: 'admin.panel.action.description',
            icon: <DataObjectOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></DataObjectOutlinedIcon>,
            href:'/action_list',
            context:'DevPanel.json'
        },
        cicd : {
            title:'admin.panel.cicd',
            text: 'admin.panel.cicd.description',
            icon: <AllInclusiveOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></AllInclusiveOutlinedIcon>,
            href:'',
            context:'DevPanel.json'
        },
        task_planned : {
            title:'admin.panel.task_planned',
            text: 'admin.panel.task_planned.description',
            icon: <RotateLeftOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></RotateLeftOutlinedIcon>,
            href:'',
            context:'DevPanel.json'
        },
        doc_dev : {
            title:'admin.panel.doc_dev',
            text: 'admin.panel.doc_dev.description',
            icon: <InfoOutlinedIcon fontSize={"large"} sx={{color: "#FF7233"}}></InfoOutlinedIcon>,
            href:'',
            context:'DevPanel.json'
        },
        language : {
            title:'admin.panel.languages',
            text: 'admin.panel.languages.description',
            icon: <PublicIcon fontSize={"large"} sx={{color: "#FF7233"}}></PublicIcon>,
            href:'/update_language',
            context:'AdminPanel.json'
        },
        default_language : {
            title:'admin.panel.default_language',
            text: 'admin.panel.default_language.description',
            icon: <LanguageIcon fontSize={"large"} sx={{color: "#FF7233"}}></LanguageIcon>,
            href:'/update_default_language',
            context:'AdminPanel.json'
        },
    }

    const t = (translationKey, variables = []) => {

        return translation(translationKey, 'Sidebar.json', variables)
    }


    const cardToUse = cards[props.card]

    return (
        <Card sx={{ width:'100%', marginTop:3 }} elevation={2} onClick={()=>{
            navigate(cardToUse.href);
        }}>
            <CardActionArea sx={{paddingTop: 2}}>
                {cardToUse.icon}

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {translation(cardToUse.title, cardToUse.context)}
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary">
                        {translation(cardToUse.text, cardToUse.context)}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}