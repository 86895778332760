import * as React from 'react';
import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField
} from "@mui/material";

import {useContext, useEffect, useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import tools from "../../Utils/tools";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {WebSocketContext} from "../../Context/WebSocketContext";
import CardSentenceUpdate from "../../Components/Card/Sentence/CardSentenceUpdate";
import {useNavigate} from "react-router";
import {InputSearch} from "../../Components/InputSearch";
import {BackdropLoader} from "../../Components/BackdropLoader/BackdropLoader";
import {TranslationContext} from "../../Context/TranslationContext";
import {LanguageContext} from "../../Context/LanguageContext";
import {UserContext} from "../../Context/UserContext";

export default function DevelopmentActionList(props) {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [dialogInitDefaultSteps, setDialogInitDefaultSteps] = useState(false);
    const _webSocketContext = useContext(WebSocketContext)

    const [actions, setActions] = useState([])
    const [actionsFiltred, setActionsFiltred] = useState(actions);
    const [init, setInit] = useState(true);
    const [createAction, setCreateAction] = useState(false);
    const [createActionNameEmpty, setCreateActionNameEmpty] = useState(false);
    const [createActionName, setCreateActionName] = useState('');

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [backdropText, setBackdropText] = useState('');


    const {translation} = useContext(TranslationContext);
    const _languageContext = useContext(LanguageContext);
    const _userContext = useContext(UserContext);

    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'Common.json', variables)
    }

    props.setCurrentAction(null)

    useEffect(() => {
        if (init) {
            setInit(false);
            setOpenBackdrop(true);
            setBackdropText(translation("admin.panel.action.getting_list", 'DevPanel.json'));

            const getActionList = {
                path: 'action/list'
            }

            const getActionListId = _webSocketContext.send(getActionList, (message) => {
                setActions(message.info.actions)
                setActionsFiltred(message.info.actions)
                setDialogInitDefaultSteps(message.info.actions.length === 0)
                setOpenBackdrop(false)
            })
            //_webSocketContext.unsubscribe(getActionListId)

        }
    }, []);

    const loadDefaultTestCase = () => {

        setOpenBackdrop(true)
        setBackdropText(translation("admin.panel.action.create_default_actions", 'DevPanel.json'));

        const loadDefaultAction = {
            path: 'action/load_default'
        }

        const loadDefaultActionId = _webSocketContext.send(loadDefaultAction, (message) => {
            setActions(message.info.actions)
            setActionsFiltred(message.info.actions)
            setDialogInitDefaultSteps(message.info.actions.length === 0)
            setOpenBackdrop(false)
        })
        //_webSocketContext.unsubscribe(loadDefaultActionId)

    }


    const deleteAction = (id) => {

        const delAction = {
            path: 'action/delete',
            info: {
                id: id
            }
        }

        const delActionId = _webSocketContext.send(delAction, (message) => {
            // actions, setActions
            const newAction = actions.filter((value) => {
                return value._id !== id
            });
            setActions(newAction)
        })

    }


    const createActionWS = (name) => {
        setOpenBackdrop(true)
        setBackdropText(translation("admin.panel.action.create_action", 'DevPanel.json', [name]));


        const createActon = {
            path: 'action/create',
            info: {
                name: name,
                lang: _languageContext.lang.toLowerCase()
            }
        }

        const createActonId = _webSocketContext.send(createActon, (message) => {
            props.setCurrentAction(message.info.id)
            navigate("/update_action");
            setOpenBackdrop(false)
        })
        //_webSocketContext.unsubscribe(createActonId)

    }

    //todo:Update
    const handleSearch = (e) => {
        setSearchValue(e)
        let filteredData = actions;
        if (!tools.isEmptyOrUndefined(e)) {
            filteredData = actions.filter((item) => {
                return item.lang.FR.toLowerCase().includes(e.toLowerCase());
            });
        }
        setActionsFiltred(filteredData);
    };
    useEffect(() => {
        handleSearch(searchValue)
        if (actions.length === 0) {
            setDialogInitDefaultSteps(true)
        }
    }, [actions]);

    const closeDialogLoadTestCase = () => {
        setDialogInitDefaultSteps(false)
    };

    const checkCreateActionTextIsEmtpy = () => {
        let isEmpty = false;
        if (createActionName.trim() === '') {
            isEmpty = true;
        }
        setCreateActionNameEmpty(isEmpty)
        return isEmpty
    }


    const availableLangInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const infoLang = (lang) => {

        return tools.getFirstElement(availableLangInfo(), (availableLang, i) => {
            return lang === availableLang.code
        })
    }

    const displayActionList = () => {

        let actionsDisplay =
            <TableContainer component={Paper}>
                <Table aria-label="simple table">

                    <TableBody>
                        {
                            actionsFiltred.map((value, index) => (
                                <TableRow
                                    // key={currentTestCaseLang(value.lang)}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <CardSentenceUpdate info={value}
                                                            setCurrentAction={props.setCurrentAction}
                                                            infoLang={infoLang}
                                                            deleteAction={deleteAction}
                                        />

                                    </TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>
            </TableContainer>


        if (actions.length === 0 && !init) {
            actionsDisplay = <p>
                You don't have actions.
            </p>
        }

        return actionsDisplay
    }

    const addAction = () => {
        setCreateAction(true)
    }
    return (
        <>
            <Box sx={{width: '100%'}}>

                <InputSearch
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                    btnAdd={true}
                    addElement={addAction}
                />
                {displayActionList()}
            </Box>

            <Dialog
                open={dialogInitDefaultSteps}
                onClose={closeDialogLoadTestCase}
            >
                <DialogTitle>
                    {"Vous n'avez aucun cas de test !"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Vous n'avez aucun cas de test.<br/> Souhaitez-vous charger les cas de test par
                        défaut ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogLoadTestCase}>Non</Button>
                    <Button onClick={loadDefaultTestCase} autoFocus>
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={createAction}
                onClose={() => {
                    setCreateAction(false)
                }}
            >
                <DialogTitle>
                    {"Créer une nouvelle action"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nom de l'action
                    </DialogContentText>
                    <br/>
                    <TextField
                        error={createActionNameEmpty}
                        fullWidth={true}
                        label={_languageContext.lang.toUpperCase()}
                        onInput={(e) => {
                            setCreateActionName(e.target.value)
                            if (checkCreateActionTextIsEmtpy()) {
                                e.preventDefault()
                            } else {
                                setCreateActionNameEmpty(false)
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <img
                                    src={'data:image/png;base64, ' + infoLang(_languageContext.lang)?.image_binary}
                                    height={'25px'}
                                    alt={_languageContext.lang.toUpperCase()}
                                />
                            </InputAdornment>,
                        }}
                        helperText={createActionNameEmpty ? 'Le nom est vide' : ''}
                    />
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => {
                        setCreateActionName('')
                        setCreateActionNameEmpty(false)
                        setCreateAction(false)
                    }}>Annuler</Button>
                    <Button onClick={() => {
                        if (!checkCreateActionTextIsEmtpy()) {
                            setCreateActionNameEmpty(false)
                            setCreateAction(false)
                            createActionWS(createActionName)
                            setCreateActionName('')
                        }
                    }} autoFocus>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
            <BackdropLoader backdropOpen={openBackdrop} setBackdropOpen={(e) => {
            }} text={backdropText}/>
        </>
    )
}