import React, {memo} from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath, getSmoothStepPath,
    useReactFlow,
} from '@xyflow/react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './buttonedge.css';
import {Tooltip} from "@mui/material";
import {NodeCustomTemplate} from "../NodeCustom/NodeCustomTemplate";

export function ButtonEdge({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       style = {},
                                       markerEnd,
                                   }) {
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    <Tooltip title={'Remove edge'} placement={'right'}>
                        <CancelOutlinedIcon  onClick={onEdgeClick}
                                             className={'Md-CancelEdge'}
                        />
                    </Tooltip>
                    {/*<button className="edgebutton" onClick={onEdgeClick}>*/}
                    {/*    ×*/}
                    {/*</button>*/}
                </div>
            </EdgeLabelRenderer>
        </>
    );
}

export default memo(ButtonEdge);