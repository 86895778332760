import * as React from "react";
import {useContext, useEffect,} from "react";

import Box from "@mui/material/Box";
import "react-color-palette/css";
import Typography from "@mui/material/Typography";
import {
    Grid, MenuItem,
    Select, Stack,
} from "@mui/material";
import tools from "../../Utils/tools";
import {LanguageContext} from "../../Context/LanguageContext";


export function DefaultLanguageEditor() {

    const _langContext = useContext(LanguageContext)
    const allLangList = _langContext.allLangList

    const handleChange = (event) => {
        _langContext.setDefaultLang(event.target.value)
    }

    const listAvailableLang = () => {


        const availableLangInfo = _langContext.intersection(_langContext.allLangList, _langContext.availableLangList)

        if (tools.getFirstElement(availableLangInfo, (elem, i) => {
            return elem.code === _langContext.lang
        }) === null) {
            tryToSearchDefaultLang()
        }

        return availableLangInfo;
    }


    const tryToSearchDefaultLang = () => {
        const navLang = navigator.language.toLocaleLowerCase()
        let currentLang = tools.getFirstElement(_langContext.availableLangList, (elem, i) => {
            return elem.code === navLang || elem.parent_zone === navLang
        })

        if (currentLang !== null) {
            let setNewCurrent = currentLang.code === navLang ? currentLang.code : currentLang.parent_zone
            _langContext.setDefaultLang(setNewCurrent)

        } else {


            currentLang = tools.getFirstElement(_langContext.allLangList, (elem, i) => {
                return elem.parent_zone === navLang
            })

            if (currentLang !== null) {
                _langContext.setDefaultLang(currentLang.parent_zone)

            }
        }
    }

    return (
        <Box sx={{width: '100%'}}>
                    <Typography variant={'h6'}>
                        Choix de la langue par défaut :
                    </Typography>

                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={_langContext.lang}
                        onChange={handleChange}
                        sx={{width: '400px'}}
                    >
                        {
                            listAvailableLang().map((element) => (
                                <MenuItem value={element.code}>
                                    <Grid container direction="row" justifyContent={'center'}
                                          alignItems={'center'}>
                                        <Grid item xs={2}>
                                            <img
                                                src={'data:image/png;base64, ' + element.image_binary}
                                                height={'25px'}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            {element.name}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))
                        }

                    </Select>

        </Box>
    )
}
