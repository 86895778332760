import * as React from "react";

import {useContext, useEffect} from "react";
import {LanguageContext} from "../../../../../Context/LanguageContext";
import tools from "../../../../../Utils/tools";
import {DefaultLanguageEditor} from "../../../../../Pages/Admin/DefaultLanguageEditor";

export function StepTwo(props) {


    const _langContext = useContext(LanguageContext)



    useEffect(() => {
        props.setDisableButtonNext(tools.isEmptyOrUndefined(_langContext.lang))
    }, [_langContext.lang])

    return (
        <DefaultLanguageEditor></DefaultLanguageEditor>
    )

}
