import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {WebSocketContext} from "../WebSocketContext";
import tools, {isEmptyOrUndefined} from "../../Utils/tools";
import Box from "@mui/material/Box";
import * as React from "react";
import {styled} from "@mui/material/styles";
import OutlinedInput, {OutlinedInputProps} from "@mui/material/OutlinedInput/OutlinedInput";
import {Alert, Tooltip} from "@mui/material";
import {UserContext} from "../UserContext";
import Snackbar from "@mui/material/Snackbar";
import {cache} from 'react';

const TestCaseContext = createContext(null)

function TestcaseProvider({children}) {

    const _webSocketContext = useContext(WebSocketContext)
    const _userContext = useContext(UserContext)

    const [listTestCase, setListTestCase] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);



    const initialize = () => {
        const getAllTestCases = {
            path: 'test_case/list',
        }
        _webSocketContext.send(getAllTestCases, async (message) => {
            console.log('list')
            setListTestCase(message.info.test_cases)

        })

    }

    useEffect(() => {
        _webSocketContext.subscribe('hook/test_case/list/update', (message) => {
            updateList(message)
        })
        _webSocketContext.subscribe('hook/test_case/list/delete', (message) => {
            console.log("test delete",message)
            removeElement(message)
        })
    }, [listTestCase]);


    const removeElement = (message) => {

        const index = tools.getFirstIndexElement(listTestCase, (testCase, i) => {
            return testCase._id === message.info.delete
        })

        const list = [
            ...listTestCase
        ]
        console.log(index)
        if (index >= 0 && index !== null) {
            list.splice(index, 1);
        }
        console.log(list)
        setListTestCase(list)
    }

    const updateList = (message) => {

        const index = tools.getFirstIndexElement(listTestCase, (testCase, i) => {
            return testCase._id === message.info._id
        })

        const list = [
            ...listTestCase
        ]


        if (index >= 0) {
            list[index] = message.info
        } else {
            list.push(message.info)
        }

        setListTestCase(list)
    }


    const getTestCaseById = (idTestCase) => {
        return tools.getFirstElement(listTestCase, (testCase, i) => {
            return testCase._id === idTestCase
        })
    }


    function simultaneousTextField(testCaseId, initElement) {
        let elem = initElement

        if (!isEmptyOrUndefined(testCaseId) && initElement !== undefined) {
            const testCaseInfo = getTestCaseById(testCaseId)

            if (testCaseInfo.hasOwnProperty('simultaneousUpdate') &&
                testCaseInfo.simultaneousUpdate.hasOwnProperty(initElement.props.id)
            ) {
                const simultaneousInfo = testCaseInfo.simultaneousUpdate[initElement.props.id]
                if (simultaneousInfo.active && simultaneousInfo.userInfo._id !== _userContext.userInfo._id) {

                    const InfoBox = styled(Box)(({theme}) => ({
                        display: 'flex', // Align content horizontally
                        alignItems: 'center', // Center content vertically with image
                        padding: theme.spacing(-0.5), // Add some padding for better visuals
                        paddingLeft: theme.spacing(1.5), // Add some padding for better visuals
                        border: `1px solid ${theme.palette.primary.main}`, // Apply border on active
                        borderRadius: theme.shape.borderRadius, // Add rounded corners
                    }));

                    const spanColor = {
                        background: 'rgba(15,156,26,0.3)',
                    }
                    if(simultaneousInfo.start === simultaneousInfo.end){
                        spanColor['width'] = '3px'
                        spanColor['height'] = '24px'
                    }


                    elem = <>
                        <InfoBox onClick={() => {
                            setOpenSnackbar(true)
                        }}>
                            {initElement.props.InputProps?.startAdornment}
                            <pre>{initElement.props.value.substring(0, simultaneousInfo.start)}</pre>
                            <Tooltip title={simultaneousInfo.userInfo.ref} open={true} placement={"top"} sx={{
                                color: 'black'
                            }}>
                        <pre
                            style={spanColor}
                        >
                            {initElement.props.value.substring(simultaneousInfo.start, simultaneousInfo.end)}
                        </pre>
                            </Tooltip>
                            <pre>
                                {initElement.props.value.substring(simultaneousInfo.end, initElement.props.value.lenght)}
                            </pre>

                            {/*{initElement.props.InputProps?.endAdornment }*/}
                        </InfoBox>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {
                            setOpenSnackbar(false)
                        }}>
                            <Alert
                                onClose={() => {
                                    setOpenSnackbar(false)
                                }}
                                severity="error"
                                variant="filled"
                                sx={{width: '100%'}}
                            >
                                Vous ne pouvez pas modifier lorsque un autre utilisateur est en cours de modification
                            </Alert>
                        </Snackbar>
                    </>
                }
            }
        }

        return elem
    }

    function stopSelectionText(e) {
        return {start: 0, end: 0, active: false, element: e.target.id};
    }


    function getSelectionText(e) {
        const activeEl = e.target;
        let pos = {start: 0, end: 0, active: true, element: activeEl.id};

        let activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
        if (
            (activeElTagName === "textarea") || (activeElTagName === "input" &&
                /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
            (typeof activeEl.selectionStart == "number")
        ) {

            pos = {
                ...pos,
                start: activeEl.selectionStart,
                end: activeEl.selectionEnd
            };

        } else if (window.getSelection) {

            const rangePos = window.getSelection().anchorNode
            pos = {
                ...pos,
                start: rangePos.selectionStart,
                end: rangePos.selectionEnd
            };
        }
        return pos
    }

    return (
        <TestCaseContext.Provider value={{
            initialize,
            listTestCase,

            getTestCaseById,
            getSelectionText,
            stopSelectionText,
            simultaneousTextField
        }}>
            {children}
        </TestCaseContext.Provider>
    );
}


export {TestCaseContext, TestcaseProvider};
