import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {
    Alert,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Divider from "@mui/material/Divider";

import Tabs from '@mui/material/Tabs';
import CardSentence from "../Components/Card/Sentence/CardSentence";
import ListItem from "@mui/material/ListItem";
import List from '@mui/material/List';
import {CardStep} from "../Components/Card/Step/CardStep";
import tools from "../Utils/tools";

import '../Components/ZoneDropScenario/DropZoneScenario.css'
import './scenarioEditor.css'
import {WebSocketContext} from "../Context/WebSocketContext";
import {InputSearch} from "../Components/InputSearch";
import Snackbar from "@mui/material/Snackbar";
import {TestCaseTextField} from "../Components/TextField/TestCaseTextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {LanguageContext} from "../Context/LanguageContext";
import {UserContext} from "../Context/UserContext";
import Dropzone from "../Components/ZoneDropScenario/Dropzone";
import {TestCaseContext} from "../Context/Hook/TestCaseContext";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid2";


let lastUseInit = 0;
let pass = 0;

export function UpdateTestCase(props) {
    const _languageContext = useContext(LanguageContext)
    const _webSocketContext = useContext(WebSocketContext)
    const _userContext = useContext(UserContext)
    const _testCaseContext = useContext(TestCaseContext)

    const [currentUpdate, setCurrentUpdate] = useState({
        start: 0, end: 0, active: false
    });

    const [loadingPage, setLoadingPage] = useState(true);
    const [availableActionLoading, setAvailableActionLoading] = useState(true);
    const [actionStepLoading, setActionStepLoading] = useState(true);

    const [actionStep, setActionSteps] = useState([]);
    const [dropzone, setDropzone] = useState([]);
    const [openDialogAllLang, setOpenDialogAllLang] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [sentence, setSentence] = useState([])
    const [sentenceFiltred, setSentenceFiltred] = useState([])


    const [testCaseInfo, setTestCaseInfo] = useState({});
    const [newTestCaseNameIsEmpty, setNewTestCaseNameIsEmpty] = useState(false);
    const [counstTestCaseEmpty, setCountTestCaseEmpty] = useState(0);


    const boxPaginate = useRef(null);
    const rightHelper = useRef(null);
    const [activeRightHelper, setActiveRightHelper] = useState(true);

    const [maxItemsPaginate, setMaxItemsPaginate] = useState(0);
    const [offsetItemsPaginate, setOffsetItemsPaginate] = useState(0);
    const [paginateTestCases, setPaginateTestCases] = useState([]);
    const [paginateNextDisabled, setPaginateNextDisabled] = useState(false)
    const [paginateBeforeDisabled, setPaginateBeforeDisabled] = useState(true)
    const [removeStep, setRemoveStep] = useState(-1)

    const navigate = useNavigate();
    const [reloadActionsStepList, setReloadActionsStepList] = useState(0);

    const [keyWsChanges, setKeyWsChanges] = useState(0);

    const itemHeight = 150;
    const isDev = true;

    useEffect(() => {
        if (loadingPage && lastUseInit < Date.now() - 5000) {
            pass++
            lastUseInit = Date.now()

            if (props.currentTestCase !== null && pass < 5) {

                const actionList = {
                    path: 'action/list'
                }

                const actionListId = _webSocketContext.send(actionList, (message) => {
                    setSentence(message.info.actions)
                    if (tools.isEmpty(searchValue) && message.info.actions.length !== 0) {
                        setSentenceFiltred(message.info.actions)
                    }
                    setAvailableActionLoading(false)
                })


                // const getTestCase = {
                //     path: 'test_case/get',
                //     info: {
                //         id: props.currentTestCase
                //     }
                // }
                // const getTestCaseId = _webSocketContext.send(getTestCase, (message) => {
                //     setTestCaseInfo(message.info.test_case)
                //     initializeActions(message.info.test_case.actions)
                // })

                const testCase = _testCaseContext.getTestCaseById(props.currentTestCase)
                initializeActions(testCase.actions)

            } else {
                navigate('/list_test_case')
            }
        }

    }, []);

    useEffect(() => {
        const testCase = _testCaseContext.getTestCaseById(props.currentTestCase)
        setTestCaseInfo({
            ...testCase,
            fromWebsocket: Date.now()
        })


    }, [_testCaseContext.listTestCase])

    useEffect(() => {
        calculMaxItem()
    }, []);


    useEffect(() => {

        calculActivateRightHelper()

    }, [rightHelper.current]);

    const calculMaxItem = () => {
        if (boxPaginate.current) {
            const box = boxPaginate.current;
            const maxItems = Math.floor((box.offsetHeight) / itemHeight);
            setMaxItemsPaginate(maxItems)
        }
    }

    const calculActivateRightHelper = () => {
        if (rightHelper.current) {
            setActiveRightHelper(rightHelper.current.offsetHeight < 400)
            calculMaxItem()
        }
    }

    useEffect(() => {

        function handleResize() {
            calculActivateRightHelper()
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(
        () => {
            setPaginateTestCases(sentenceFiltred.slice(offsetItemsPaginate, offsetItemsPaginate + maxItemsPaginate));
        },
        [offsetItemsPaginate, maxItemsPaginate, sentenceFiltred]);

    const paginateNext = () => {
        const totalLength = sentenceFiltred.length;
        const offset = offsetItemsPaginate;
        if (totalLength > offsetItemsPaginate + maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setOffsetItemsPaginate(offset + maxItemsPaginate)
        }
        setPaginateNextDisabled(totalLength <= offset + (maxItemsPaginate * 2))
    }
    const paginateBefore = () => {
        if (0 < offsetItemsPaginate - maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setPaginateNextDisabled(false)
            setOffsetItemsPaginate(offsetItemsPaginate - maxItemsPaginate)
        } else {
            setOffsetItemsPaginate(0)
            setPaginateBeforeDisabled(true)
            setPaginateNextDisabled(false)
        }
    }


    useEffect(() => {
        if (!availableActionLoading && !actionStepLoading) {
            setLoadingPage(false)
        }
    }, [availableActionLoading, actionStepLoading]);


    const initializeActions = (actions) => {

        let newSteps = []

        actions.forEach((v, i) => {
            newSteps.push({
                ...v,
                variables: Object.hasOwn(v, 'variables') ? v.variables : [],
                lock: Object.hasOwn(v, 'lock') ? v.lock : false,
            })
        })
        setActionSteps(newSteps)
        setActionStepLoading(false)
    }


    useEffect(() => {

        if (!loadingPage) {
            if (!testCaseInfo?.fromWebsocket) {
                const keysInfo = Object.keys(testCaseInfo)
                if (
                    keysInfo.indexOf('lang') !== -1 &&
                    keysInfo.indexOf('_id') !== -1
                ) {


                    const info = {
                        ...testCaseInfo,
                        id: testCaseInfo._id,
                        actions: actionStep,
                    }

                    const updateTestCase = {
                        path: 'test_case/update',
                        info: info
                    }

                    const updateTestCaseId = _webSocketContext.send(updateTestCase, (message) => {
                        // props.setCurrentTestCase(message.info.test_case_id)
                    })
                    // _webSocketContext.unsubscribe(updateTestCaseId)
                }
            }
            let tmp = testCaseInfo
            tmp.fromWebsocket = false
            setTestCaseInfo(tmp)

        }

    }, [actionStep, testCaseInfo, currentUpdate]);


    const handleSearch = (e) => {
        setSearchValue(e)
        let filteredData = sentence;
        if (!tools.isEmptyOrUndefined(e)) {
            filteredData = sentence.filter((item) => {
                return item.action.toLowerCase().includes(e.toLowerCase());
            });
        }
        setSentenceFiltred(filteredData);
    };


    const handleCloseTestCaseNameIsEmpty = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNewTestCaseNameIsEmpty(false);
    };

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const readNameTestCaseByLang = (lang) => {
        let name = '';
        if (Object.keys(testCaseInfo).length !== 0 && testCaseInfo.lang[lang] !== undefined) {
            const projectName = testCaseInfo.lang[lang]
            name = projectName
        }

        return name;
    }

    const languageInput = (info) => {

        const textField = <TextField
            id={'update_Text_Case_By_Lang_' + info.code}
            value={
                readNameTestCaseByLang(info.code.toLowerCase())
            }
            onInput={(e) => {
                let newInfo = {
                    ...testCaseInfo
                }
                newInfo.lang[info.code.toLowerCase()] = e.target.value
                setTestCaseInfo(newInfo)

            }}
            onMouseUp={getUpdateSimultaneous}
            onKeyUp={getUpdateSimultaneous}
            onBlur={stopUpdateSimultaneous}
            InputProps={{
                startAdornment: <InputAdornment position="start">
                    <img
                        src={'data:image/png;base64, ' + info.image_binary}
                        height={'25px'}
                    />
                </InputAdornment>,
            }}
        />

        return <Grid item xs={4}>
            {_testCaseContext.simultaneousTextField(testCaseInfo?._id, textField)}
        </Grid>
    }

    function getUpdateSimultaneous(e) {
        const pos = _testCaseContext.getSelectionText(e)
        initUpdateSimultaneous(pos)
    }

    function stopUpdateSimultaneous(e) {
        const pos = _testCaseContext.stopSelectionText(e)
        initUpdateSimultaneous(pos)
    }

    function initUpdateSimultaneous(pos) {

        let tmpSimultaneousUpdate = {}
        if (testCaseInfo?.simultaneousUpdate) {
            tmpSimultaneousUpdate = {
                ...testCaseInfo.simultaneousUpdate,
            }
        }


        tmpSimultaneousUpdate[pos.element] = {
            userInfo: _userContext.userInfo,
            start: pos.start,
            end: pos.end,
            active: pos.active,
        }

        const newInfo = {
            ...testCaseInfo,
            simultaneousUpdate: tmpSimultaneousUpdate
        }
        setTestCaseInfo({...newInfo})
    }

    function getLocationDropzone(pos, location) {
        const positions = dropzone
        positions[pos] = location
        setDropzone(positions)
    }

    function pushToList(dropzoneId, info) {
        let tmp = [...actionStep]
        let tmpInfo = {
            ...info,
            variables: Object.hasOwn(info, 'variable') ? info.variables : [],
            lock: Object.hasOwn(info, 'lock') ? info.variables : false
        }
        tmp.splice(dropzoneId, 0, tmpInfo);

        setActionSteps(tmp)
        reload()

    }

    function moveToList(lastIndex, dropzoneId, info) {

        let tmp = [...actionStep]
        tmp.splice(lastIndex, 1);
        tmp.splice(dropzoneId, 0, info);
        setActionSteps(tmp)
        reload()
    }

    function removeToList(dropzoneId) {
        setRemoveStep(dropzoneId)
    }

    function confirmRemoveToList(dropzoneId) {
        if (dropzoneId !== -1) {
            let tmp = [...actionStep]
            tmp.splice(dropzoneId, 1);
            setActionSteps(tmp)
        }
        setRemoveStep(-1)
        reload()
    }

    const changeActionInfo = (indexAction, information) => {
        let actionList = [...actionStep]
        actionList[indexAction] = information;
        setActionSteps(actionList)
    }

    const reload = () => {
        setReloadActionsStepList('step' + reloadActionsStepList + 1)
    }

    const PaperRight = styled(Paper)(({theme}) => ({
        '& .MuiPaper-root ': {
            height: 'calc(100% - 30px)',
        }
    }));

    return (
        <>

            <Grid container
                  direction={'row'}
                  spacing={3}
                  className="drag-area" style={{
                width: '100%',
                height: '100%'
            }}>

                <Grid size={9} style={{
                    // marginRight: '26vw',
                    // minHeight: '80vh',
                    // marginBottom: '5vw'
                    height:'100%'
                }}
                >

                    <Paper style={{
                        padding: "35px",
                        textAlign: 'left',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'start',
                        overflowY:'auto',
                        height:props.parentRef ? props.parentRef : 'calc(100vh - 50px)'
                    }}
                    >

                        <Grid container spacing={1}
                              sx={{
                                  width: '100%',
                              }}
                              alignItems="center"
                        >

                            <Grid item xs={12} width={'100%'}>
                                <TestCaseTextField
                                    testCaseInfo={testCaseInfo}
                                    setTestCaseInfo={setTestCaseInfo}
                                    counstTestCaseEmpty={counstTestCaseEmpty}
                                    openDialogAllLang={openDialogAllLang}
                                    setOpenDialogAllLang={setOpenDialogAllLang}
                                />


                            </Grid>
                        </Grid>
                        <Divider style={
                            {
                                margin: '10px'
                            }
                        }/>

                        <Box key={'actStep' + reloadActionsStepList} overflow={'auto'}
                             style={{
                                 position:'sticky',
                                 overflow:'auto',
                                 display:'block'
                             }}>
                            <Dropzone
                                position={0}
                                setLocation={getLocationDropzone}
                                alone={actionStep.length === 0}
                            />
                            {
                                actionStep.map((action, i) => (
                                    <>
                                        <CardStep
                                            info={action}
                                            dropzoneLocation={dropzone}
                                            moveToList={moveToList}
                                            removeToList={removeToList}
                                            cardPos={i}
                                            changeActionInfo={changeActionInfo}
                                        />
                                        <Dropzone
                                            position={i + 1}
                                            setLocation={getLocationDropzone}
                                            alone={false}
                                        />
                                    </>
                                ))
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid size={3} >
                    <Paper
                        ref={rightHelper}
                            sx={{height:'100%'}}
                            // position: 'fixed',
                            // width: '25vw',
                            // right: '2vw',
                            // top: '90px',
                            // bottom: '250px',
                    >
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"

                        >

                            {/*<Grid item xs={2} width={'100%'}>*/}
                            {
                                !activeRightHelper && (
                                    <Box
                                        sx={{/*flexGrow: 1, */padding: 2}}
                                        style={{width: "100%"}}
                                    >
                                        <InputSearch handleSearch={handleSearch} searchValue={searchValue} btnAdd={false}/>

                                        {/*<Tabs*/}
                                        {/*    selectionFollowsFocus*/}
                                        {/*    variant="scrollable"*/}
                                        {/*    scrollButtons="auto"*/}
                                        {/*>*/}
                                        {/*    <Tab label="All" value="1"/>*/}
                                        {/*    <Tab label="Default" value="2"/>*/}
                                        {/*    <Tab label="Categ 1" value="3"/>*/}
                                        {/*    <Tab label="Categ 2" value="4"/>*/}
                                        {/*    <Tab label="Categ 3" value="5"/>*/}
                                        {/*    <Tab label="Categ 4" value="6"/>*/}
                                        {/*</Tabs>*/}
                                        <Divider/>
                                    </Box>
                                )
                            }

                            {/*</Grid>*/}
                            {/*<Grid item xs width={'100%'} ref={boxPaginate}>*/}
                            <Box ref={boxPaginate} height={"100%"} width={'100%'}>
                                {sentenceFiltred.length === 0 && !isDev && (

                                    <p>
                                        Aucune action disponible.<br/>
                                        Veuillez contacter un développeur afin qu'il créé les actions.
                                    </p>
                                )}
                                {sentenceFiltred.length === 0 && isDev && (
                                    <>
                                        <p style={{
                                            margin: '15px'
                                        }}>
                                            Aucune action disponible.<br/>
                                            Veuillez créer les actions afin de pouvoir réaliser vos permiers cas de
                                            test.
                                        </p>
                                        <Button variant="outlined" onClick={() => {
                                            navigate("/action_list")
                                        }}>
                                            Créer des actions
                                        </Button>
                                    </>
                                )}


                                {sentenceFiltred.length !== 0 && (
                                    <List sx={{width: "100%", height: '100%'}}>
                                        {

                                            paginateTestCases.map((item, index) => (
                                                <ListItem>
                                                    <CardSentence
                                                        info={item}
                                                        pushToList={pushToList}
                                                        dropzoneLocation={dropzone}
                                                    ></CardSentence>
                                                </ListItem>
                                            ))
                                        }

                                    </List>
                                )}
                            </Box>

                            {/*</Grid>*/}
                            {sentenceFiltred.length !== 0 && (

                                // <Grid item xs={1} width={'100%'}>

                                <Box sx={{alignItems: 'center', width: '100%'}}>
                                    <Divider/>
                                    <div style={{padding: "10px", textAlign:'center'}}>
                                        <IconButton aria-label="previous" fontSize="large" style={{
                                            color: paginateBeforeDisabled ? 'grey' : "#FF7233"
                                        }}
                                                    onClick={() => {
                                                        paginateBefore()
                                                    }}
                                                    disabled={paginateBeforeDisabled}
                                        >
                                            <NavigateBeforeIcon/>
                                        </IconButton>
                                        <span style={{width: "5vw", display: 'inline-block'}}/>
                                        <IconButton aria-label="next" style={{
                                            color: paginateNextDisabled ? 'grey' : "#FF7233"
                                        }}
                                                    onClick={() => {
                                                        paginateNext()
                                                    }}
                                                    disabled={paginateNextDisabled}>
                                            <NavigateNextIcon/>
                                        </IconButton>
                                    </div>
                                </Box>
                                // </Grid>
                            )}
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                open={openDialogAllLang}
                onClose={() => {
                    setOpenDialogAllLang(false)
                }}
            >
                <DialogTitle>Le nom du projet par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}
                    >
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialogAllLang(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={newTestCaseNameIsEmpty}
                onClose={handleCloseTestCaseNameIsEmpty}
                // TransitionComponent={}
                // key={state.Transition.name}
                autoHideDuration={5000}
            >

                <Alert
                    onClose={handleCloseTestCaseNameIsEmpty}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Le nom du cas de test est obligatoire !
                </Alert>
            </Snackbar>


            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={removeStep !== -1}
                onClose={() => {
                    setRemoveStep(-1)
                }}
            >
                <DialogTitle style={{color: 'red'}}>Supprimer</DialogTitle>
                <DialogContent dividers={true}>

                    Souhaitez-vous réellement supprimer l'action ?
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => {
                        confirmRemoveToList(removeStep)
                    }}>Supprimer</Button>
                    <Button onClick={() => {
                        setRemoveStep(-1)
                    }}>Annuler</Button>
                </DialogActions>
            </Dialog>


            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loadingPage}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    )
}
