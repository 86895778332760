import React, {memo, useContext} from 'react';
import {Position, Handle, getOutgoers, useReactFlow, getNodesBounds,} from '@xyflow/react';
import NodeCustomHandle from '../NodeCustomHandle/NodeCustomHandle';
import {Card, Stack, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {LanguageContext} from "../../../Context/LanguageContext";
import tools from "../../../Utils/tools";
import {UserContext} from "../../../Context/UserContext";

export const NodeCustomTemplate = (props) => {
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)


    const txtLang = (elem) => {
        let current = "";
        let name = <Box>{current}</Box>
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
            name = <Box>{current}</Box>
        }


        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = _languageContext.intersection(_languageContext.allLangList, [_languageContext.lang])[0]

            let flag = <></>

            if(_userContext.userlang() !== _languageContext.lang){
                flag =  <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            //Todo :  translate tooltips
            name = <Stack direction={'row'} spacing={1}
                          alignItems={'center'}>
                {flag}

                <Box>{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }



    return (
        <Card className={'dndnode  react-react-flow__node-custom  aside dndnodeext template'}  style={{
            minWidth:'150px',
            textAlign:'center'
        }}>
            <NodeCustomHandle className={'target'} type="target" position={Position.Top} isConnectable={1}/>
            <Box>
                {txtLang(props.data.label)}
            </Box>
            <NodeCustomHandle className={'source'} type="source" position={Position.Bottom} isConnectable={1} multiConnect={props?.data?.multiConnect === true}/>

        </Card>
    );
};

export default memo(NodeCustomTemplate);
