import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Card, SwipeableDrawer} from "@mui/material";
import SidebarContent from "../Content/SidebarContent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import SidebarComputer from "../Computer/SidebarComputer";

export default function SidebarMobile(props) {

    const drawerWidth = props.width;
    const toggleDrawer = (open) => () => {
        props.setIsOpen(open);
    };


    return (
        <>
            {
                !props.isOpen && (

                    <Card className={'Md-Sidebar-mobile'} elevation={5}
                          style={{
                              border: '1px solid #FF7233',
                              zIndex:99
                          }}
                          onClick={toggleDrawer(true)}>
                        <ChevronRightIcon
                            style={{
                                color: '#FF7233'
                            }}
                        />
                    </Card>
                )
            }
            <SwipeableDrawer
                open={props.isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box
                    sx={{width: drawerWidth + 'px'}}
                    role="presentation"
                    style={{
                        overflowX: 'hidden',
                        height: '100%'
                    }}
                >
                    <SidebarContent
                        width={props.width}
                        isOpen={props.isOpen}
                        setIsOpen={props.setIsOpen}>
                    </SidebarContent>
                </Box>
            </SwipeableDrawer>
        </>
    );
}