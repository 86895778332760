import * as React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router";
import tools from "../../../Utils/tools";
import {useContext, useState} from "react";
import {TranslationContext} from "../../../Context/TranslationContext";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";

export function CardProject(props) {
    const navigate = useNavigate();
    const {translation} = useContext(TranslationContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)

    const [openDialogLangNames, setOpenDialogLangNames] = useState(false)

    const languageInput = (info) => {

        return <Grid item xs={4}>
            <TextField
                aria-readonly={true}
                value={
                    tools.isEmptyOrUndefined(props.title[info.code.toLowerCase()]) ? "" : props.title[info.code.toLowerCase()]
                }
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <img
                            src={'data:image/png;base64, ' + info.image_binary}
                            height={'25px'}
                        />
                    </InputAdornment>,
                }}
            />
        </Grid>
    }

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const handleCloseDialogLangNames = () => {
        setOpenDialogLangNames(false)
    }
    return (
        <>
            <Card sx={{width: 345}}>
                <CardMedia
                    component="img"
                    alt="Project"
                    height="140"
                    image="https://www.liquidplanner.com/wp-content/uploads/2019/04/HiRes-17-1024x615.jpg"
                />
                <CardContent style={{textAlign: 'left'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {tools.isEmpty(props.title[_userContext.userlang()]) ? translation("project.no.name", 'Projects.json') : props.title[_userContext.userlang()]}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => {
                        props.setCurrentProject(props.project_id)
                        navigate("/update_project")
                    }}>Modifier</Button>
                    <Button size="small"
                            onClick={() => {

                            }}
                    >Supprimer</Button>

                    {/*//fixme: si plusieurs langue parametrées*/}
                    {
                        _languageContext.availableLangList.length > 1 && (
                            <Button size="small"
                                    onClick={() => {
                                        setOpenDialogLangNames(true)
                                    }}
                            >Autres langues</Button>
                        )
                    }
                </CardActions>
            </Card>


            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                open={openDialogLangNames}
                onClose={handleCloseDialogLangNames}
            >
                <DialogTitle>Le nom du projet par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}>
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogLangNames}>Close</Button>
                </DialogActions>
            </Dialog>
        </>


    )
}
