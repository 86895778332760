import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid, MenuItem, Select,
    Switch, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";


export function DialogUpdateProject(props) {

    return (
        <Dialog
            fullWidth={true}
            open={props.openSettings}
            onClose={props.handleSettingsClose}
            maxWidth={'xl'}
        >
            <DialogTitle>Réglages du projet</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <Grid container>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label="View cursor "
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<TextField
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />}
                                label="TimeOut"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={10}
                                        label="Age"
                                        // onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>}
                                label="Cursor size"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <TextField
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                            }
                                label="Delay"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleSettingsClose}>Appliquer</Button>
                <Button onClick={props.handleSettingsClose}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}
