import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './WelcomeInit.css'
import Button from "@mui/material/Button";
import {DialogContent} from "@mui/material";
import {useContext} from "react";
import {TranslationContext} from "../../../../../Context/TranslationContext";

export function WelcomeInit(props) {

    const {translation} = useContext(TranslationContext);

    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'FirstConfig.json', variables)
    }


    return (
        <DialogContent>
            <Box sx={{width: '100%'}}  textAlign={'center'}>
                <Typography id={'titleWelcomeInit'} variant={'h1'} >
                    {t('first_config.welcome')}
                </Typography>
                <Typography id={'bodyWelcomeInit'} variant={'body1'} marginTop={'25px'} marginBottom={'25px'}>
                    {t('first_config.welcome.description')}
                </Typography>
                <Button
                    id={'btnWelcomeInit'}
                    variant="outlined"
                    style={{
                        color:'#FF7233',
                        borderColor:'#FF7233'
                    }}
                    onClick={props.nextDialogInfo}
                >
                    {t('first_config.welcome.lets_go')}
                    </Button>
            </Box>
        </DialogContent>
    )

}
