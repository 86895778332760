import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {
    Alert,
    ButtonGroup,
    Grid, InputAdornment,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import {InputSearch} from "../../InputSearch";
import {
    ReactFlow,
    Background,
    ConnectionLineType, Controls,
    ReactFlowProvider,
    useEdgesState,
    useNodesState
} from "@xyflow/react";
import {useContext, useEffect, useRef, useState} from "react";
import {TranslationContext} from "../../../Context/TranslationContext";
import Box from "@mui/material/Box";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";
import {useNavigate} from "react-router-dom";
import {ProjectContext} from "../../../Context/Hook/ProjectContext";
import {WebSocketContext} from "../../../Context/WebSocketContext";
import {TestCaseContext} from "../../../Context/Hook/TestCaseContext";
import {NodeCustom} from "../../ReactFlowCustom/NodeCustom/NodeCustom";
import {NodeCustomNotExist} from "../../ReactFlowCustom/NodeCustom/NodeCustomNotExist";
import Snackbar from "@mui/material/Snackbar";
import {NodeCustomEndPath} from "../../ReactFlowCustom/NodeCustom/NodeCustomEndPath";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from "@mui/material/Typography";
import tools from "../../../Utils/tools";
export default function DialogTemplateList(props) {
    const _webSocketContext = useContext(WebSocketContext)
    const {translation, translationForce} = useContext(TranslationContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const _projectContext = useContext(ProjectContext)
    const navigate = useNavigate();

    const initialNodes = [
        {
            id: 'start',
            type: 'input',
            data: {label: translation('project.step.start', 'Projects.json')},
            deletable: false,
            position: {x: 250, y: 5},
        },
    ];


    const finalNode = {
        _id: 'END_TEST',
        type: 'endPathCustom',
        txt: translation('project.step.end', 'Projects.json'),
        deletable: true,
    }

    const nodeTypes = {
        custom: NodeCustom,
        endPathCustom: NodeCustomEndPath,
        notExist: NodeCustomNotExist,
    };


    const ref = useRef(null);
    const reactFlowWrapper = useRef(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const activeFlow = useMediaQuery(theme.breakpoints.up('md'));

    const [templateIsFinish, setTemplateIsFinish] = useState(false);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges] = useEdgesState([]);

    const [openDialogCreateTemplate, setOpenCreateTemplate] = useState(false);
    const [openDialogUpdateTemplate, setOpenDialogUpdateTemplate] = useState(false);


    const [createTemplateNameEmpty, setCreateTemplateNameEmpty] = useState(false);
    const [createTemplateName, setCreateTemplateName] = useState('');


    const templateArray = props.projectInfo.hasOwnProperty('template') ? Object.keys(props.projectInfo.template) : []
    const [templateFocus, setTemplateFocus] = useState(templateArray.length === 0 ? null : props.projectInfo.template[templateArray[0]]);



    const defaultLangInfo = () => {
        const currentLang = _languageContext.lang.toLowerCase()
        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }

    const getId = () => `dndnode_${Date.now() + Math.random()}`;
    const initializeNodesAndEdges = (steps) => {

        let initNodes = [];
        let initEdges = [];
        let containNotExist = false;
        const initPos = {
            x: 200,
            y: 50,
        }

        let lastLineId = 'start';

        steps.forEach((step, i) => {
            const newNodeId = getId()
            let tc = props.testCases.filter((testCase) => testCase._id === step.key);
            let newNode = {}

            if (step.type !== finalNode.type && tc.length === 0) {
                newNode = {
                    id: newNodeId,
                    type: 'notExist',
                    data: {
                        label: 'Not exist',
                        internalCaseId: 'NaN',
                    },
                    deletable: true,
                    position: {
                        x: step.position.x, y: step.position.y
                    }
                }
                containNotExist = true
            } else {
                if (step.key === finalNode._id || step.type === finalNode.type) {
                    newNode = {
                        id: newNodeId,
                        type: finalNode.type,
                        data: {
                            label: finalNode.type,
                            internalCaseId: tc.length > 0 ? tc[0]._id : finalNode.type,
                            txt: finalNode.txt
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        }
                    }
                } else {
                    newNode = {
                        id: newNodeId,
                        type: 'custom',
                        data: {
                            label: tc[0].lang,
                            internalCaseId: tc[0]._id,
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        }
                    }
                }
            }
            initNodes.push(newNode)

            let newEdge = {
                id: 'e' + lastLineId + '-' + newNodeId,
                source: lastLineId,
                target: newNodeId,
                type: 'buttonEdge',
                deletable: true,
                style: {color: '#b2b2b2', width: '40px'},
                animated: false,
                markerEnd: {type: 'arrow', color: '#b2b2b2', width: '30px', height: '30px'},
            }
            initEdges.push(newEdge)

            lastLineId = newNodeId
        })

        let nodesInit = initialNodes.concat(initNodes)


        return {
            nodes: nodesInit,
            edges: initEdges
        }
    }

    useEffect(() => {
        if(templateFocus !== null){
            const info = initializeNodesAndEdges(templateFocus.steps)

            setNodes(info.nodes)
            setEdges(info.edges)

            if (info.nodes.length > 0 && info.nodes[info.nodes.length - 1].type === finalNode.type) {
                setTemplateIsFinish(true)
            } else {
                setTemplateIsFinish(false)
            }
        }
    }, [templateFocus]);


    const defaultLangInformation = defaultLangInfo();

    const handleClose = () => {
        props.setOpen(false);
    };

    const checkCreateTemplateTextIsEmtpy = () => {
        let isEmpty = false;
        if (createTemplateName.trim() === '') {
            isEmpty = true;
        }
        setCreateTemplateNameEmpty(isEmpty)
        return isEmpty
    }

    const createNewTemplate = () => {

        const createTemplate = {
            path: 'project/template/create',
            info: {
                project_id: props.projectInfo._id,
                template_name: createTemplateName,
                lang:_languageContext.lang
            }
        }
        _webSocketContext.send(createTemplate, (message) => {

            if (message.code_execution === 'PROJECT_TEMPLATE_CREATE_0001') {
                navigate('/update_template', {
                    state: {
                        project_key: props.projectInfo._id,
                        template_key: message.info.create
                    }
                })
            }
        })


    }

    const getTemplates = () => {
        let templates = []
        if (props.projectInfo.hasOwnProperty('template')) {
            templates = Object.keys(props.projectInfo.template)
        }
        return templates
    }

    const [templateList, setTemplateList] = useState(getTemplates());

    useEffect(() => {
        setTemplateList(getTemplates())
    }, [props.projectInfo]);

    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        let name = <Box>{current}</Box>

        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = _languageContext.intersection(_languageContext.allLangList, [_languageContext.lang.toLowerCase()])[0]
            let flag = <></>

            if (_userContext.userlang() !== _languageContext.lang) {
                flag = <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            name = <Stack direction={'row'} spacing={2}>
                {flag}

                <Box
                    style={{userSelect: 'none'}}
                >{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }

    const cellTemplateIsFinish = (templateInfo)=>{
        return templateInfo.steps.length > 0 && templateInfo.steps[templateInfo.steps.length - 1].type === finalNode.type
    }



    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={fullScreen ? false : 'xl'}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="dialog-templateList"
            >
                <DialogTitle id="responsive-dialog-title">
                    Liste des templates
                </DialogTitle>
                <DialogContent>
                    <Grid container
                          justifyContent="space-between"
                    >
                        <Grid item xs md={6}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <InputSearch handleSearch={() => {
                                }} searchValue={''} addElement={() => {
                                    setOpenCreateTemplate(true)
                                }} btnAdd={true}/>
                                <TableContainer component={Paper}>
                                    <Table sx={{width: '100%'}} aria-label="simple table">
                                        <TableBody>
                                            {
                                                templateList.map((template) => (
                                                    <TableRow
                                                        key={template}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        onClick={() => {
                                                            setTemplateFocus(props.projectInfo.template[template])
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row" onClick={() => {
                                                            setTemplateFocus(props.projectInfo.template[template])
                                                        }}>
                                                            <Stack
                                                            direction={"row"}
                                                            spacing={0.5}
                                                            >
                                                                <Typography variant={'body1'} >{txtLang(props.projectInfo.template[template].lang)}</Typography>
                                                                {!cellTemplateIsFinish(props.projectInfo.template[template]) && (
                                                                    <ErrorOutlineIcon style={{color:'red'}}></ErrorOutlineIcon>
                                                                )}
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" component="th" scope="row">
                                                            <ButtonGroup>
                                                                <Button
                                                                    onClick={() => {
                                                                        setOpenDialogUpdateTemplate(true)
                                                                        navigate('/update_template', {
                                                                            state: {
                                                                                project_key: props.projectInfo._id,
                                                                                template_key: template
                                                                            }
                                                                        })
                                                                    }}
                                                                >Modifier</Button>
                                                                <Button>Autres langues</Button>
                                                                <Button>Supprimer</Button>
                                                            </ButtonGroup>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>

                        </Grid>
                        {activeFlow && templateFocus !== null && (
                            <Grid item xs={5} style={{borderRadius: '25px', minHeight: '50vh'}}>
                                <div className="reactflow-wrapper force-full-height" ref={reactFlowWrapper}>
                                    <ReactFlow
                                        ref={ref}
                                        nodes={nodes}
                                        edges={edges}
                                        onNodesChange={()=>{

                                            reactFlowInstance?.fitView();
                                        }}
                                        onInit={setReactFlowInstance}
                                        fitView
                                        className="touchdevice-flow"
                                        connectionLineType={ConnectionLineType.SmoothStep}

                                        nodeTypes={nodeTypes}
                                        edgesUpdatable={false}
                                        edgesFocusable={false}
                                        nodesDraggable={false}
                                        nodesConnectable={false}
                                        nodesFocusable={false}
                                        elementsSelectable={false}
                                    >

                                        <Box style={{position: 'absolute', fontSize: '20px'}} marginLeft={'20px'}
                                             marginTop={"5px"}>Visualisateur</Box>
                                        <Background/>
                                        <Controls showInteractive={true} position={'top-right'}/>
                                        {!templateIsFinish &&(
                                            <Alert
                                                severity="error"
                                                variant="filled"
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    margin: '2px',
                                                    zIndex: '99'
                                                }}
                                            >
                                                Le template n'est pas terminé !
                                            </Alert>
                                        )}
                                    </ReactFlow>


                                </div>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openDialogCreateTemplate}
                onClose={() => {
                    setOpenCreateTemplate(false)
                }}
            >
                <DialogTitle>
                    {"Créer un nouveau template"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nom du template dans la langue par défaut
                    </DialogContentText>
                    <br/>
                    <Stack spacing={2} direction={'column'}>
                        <TextField
                            error={createTemplateNameEmpty}
                            fullWidth={true}
                            label={defaultLangInformation.code.toUpperCase()}
                            required={true}
                            onInput={(e) => {
                                setCreateTemplateName(e.target.value)
                                if (checkCreateTemplateTextIsEmtpy()) {
                                    e.preventDefault()
                                } else {
                                    setCreateTemplateNameEmpty(false)
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <img
                                        src={'data:image/png;base64, ' + defaultLangInformation.image_binary}
                                        height={'25px'}
                                    />
                                </InputAdornment>,
                            }}
                            helperText={createTemplateNameEmpty ? 'Le nom par defaut est vide' : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCreateTemplateName('')
                        setCreateTemplateNameEmpty(false)


                        setOpenCreateTemplate(false)
                    }}>Annuler</Button>
                    <Button onClick={() => {
                        if (!checkCreateTemplateTextIsEmtpy()) {
                            setCreateTemplateNameEmpty(false)
                            setCreateTemplateName('')
                            setOpenCreateTemplate(false)
                            createNewTemplate()
                        }
                    }} autoFocus>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}