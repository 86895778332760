import * as React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import DialogContext from "@mui/material/Dialog/DialogContext";
import Box from "@mui/material/Box";
import {LanguageEditor} from "../../../../../Pages/Admin/LanguageEditor";
import {useContext, useEffect} from "react";
import {LanguageContext} from "../../../../../Context/LanguageContext";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import tools from "../../../../../Utils/tools";
import {UsersEditor} from "../../../../../Pages/Admin/UsersEditor";
import Button from "@mui/material/Button";

export function StepThree(props) {



        props.setDisableButtonNext(false)
    return (
        <Box sx={{width: '100%', margin:'0 20px'}}>
            <Typography variant={'h6'} sx={{marginBottom:'15px'}}>
                Souhaitez-vous créer les actions par défaut ?
            </Typography>
            <Typography variant={'body1'} sx={{marginBottom:'15px'}}>
                Les actions préparametrés permettent la créations de cas de test basique. <br/>
                Si vous ne souhaitez pas les créer maintenant, vous pouvez les créer dans la rubrique "Mode Développeur"
            </Typography>
            <Button>Créer les actions</Button>
        </Box>
    )

}
