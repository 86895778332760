import * as React from "react";
import {useContext, useEffect,} from "react";

import Box from "@mui/material/Box";
import "react-color-palette/css";
import Typography from "@mui/material/Typography";
import {
    Card, CardContent,
    Grid, MenuItem,
    Select, Stack,
} from "@mui/material";
import tools from "../../Utils/tools";
import {LanguageContext} from "../../Context/LanguageContext";
import {DefaultLanguageEditor} from "./DefaultLanguageEditor";


export function UpdateDefaultLang() {


    return (
        <Stack spacing={2} width={'100%'}>
            <DefaultLanguageEditor />
            <Card style={{textAlign:'left', background:'rgba(211,12,12,0.2)'}}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Attention !
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        La modification de la langue par défaut peut entrainer des erreurs d'affichage si tous les champs ne sont pas traduits.
                    </Typography>
                </CardContent>
            </Card>
        </Stack>
    )
}
