import {createContext, useContext, useEffect, useState} from 'react';
import {LanguageContext} from "./LanguageContext";
import tools from "../Utils/tools";
import {UserContext} from "./UserContext";
import {WebSocketContext} from "./WebSocketContext";


const TranslationContext = createContext(null)

function TranslationProvider({children}) {
    const _webSocketContext = useContext(WebSocketContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const [language, setLanguage] = useState(_userContext.userlang)
    const [translationCache, setTranslationCache] = useState({})
    const translationConfig = require('../Translation/translations_lang.json')

    useEffect(() => {
        setLanguage(_userContext.userlang)
    }, [_userContext.userlang])

    /**
     * Translate
     * @param translationKey
     * @param context translation filename with extention
     * @param variable
     */
    const translation = (translationKey, context, variable = []) => {
        let cache = translationCache

        let currentLang = (language).toLocaleLowerCase()
        const isPrincipalLang = translationConfig.available_language.indexOf((language).toLocaleLowerCase()) !== -1

        if (!isPrincipalLang) {
            const principalLang = tools.getFirstElement(_languageContext.allLangList, (elem, i) => {
                return (elem.code === (language).toLocaleLowerCase())
            })

            if (principalLang !== null && principalLang.parent_zone !== "") {
                currentLang = principalLang.parent_zone
            }

        }

        const canTranslate = translationConfig.available_language.indexOf(currentLang) !== -1
        const lang = canTranslate ? (currentLang).toUpperCase() : 'EN'

        if (
            !(lang in cache &&
                context in cache[lang])
        ) {
            cache[lang] = {}
            cache[lang][context] = require('../Translation/' + lang + '/' + context)
            setTranslationCache(cache)
        }

        if (
            lang in cache &&
            context in cache[lang] &&
            translationKey in cache[lang][context]
        ) {
            return replaceVar(cache[lang][context][translationKey], variable)
        } else {
            return translationKey
        }
    }

    /**
     * Translate
     * @param langF
     * @param translationKey
     * @param context translation filename with extention
     * @param variable
     */
    const translationForce = (langF,translationKey, context, variable = []) => {
        let cache = translationCache
        let currentLang = (langF).toLocaleLowerCase()
        const isPrincipalLang = translationConfig.available_language.indexOf((langF).toLocaleLowerCase()) !== -1

        if (!isPrincipalLang) {
            const principalLang = tools.getFirstElement(_languageContext.allLangList, (elem, i) => {
                return (elem.code === (langF).toLocaleLowerCase())
            })

            if (principalLang !== null && principalLang.parent_zone !== "") {
                currentLang = principalLang.parent_zone
            }

        }

        const canTranslate = translationConfig.available_language.indexOf(currentLang) !== -1
        const lang = canTranslate ? (currentLang).toUpperCase() : 'EN'

        if (
            !(lang in cache &&
                context in cache[lang])
        ) {
            cache[lang] = {}
            cache[lang][context] = require('../Translation/' + lang + '/' + context)
            setTranslationCache(cache)
        }

        if (
            lang in cache &&
            context in cache[lang] &&
            translationKey in cache[lang][context]
        ) {
            return replaceVar(cache[lang][context][translationKey], variable)
        } else {
            return translationKey
        }
    }

    const replaceVar = (text, variables) => {
        let txt = text
        variables.forEach((variable) => {
            txt = txt.replace('{var}', variable)
        })
        return txt
    }

    return (
        <TranslationContext.Provider value={{translation,translationForce}}>
            {children}
        </TranslationContext.Provider>
    );
}

export {TranslationContext, TranslationProvider};
