import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {Stack, TextField} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import tools from "../../Utils/tools";
import {LanguageContext} from "../../Context/LanguageContext";
import Typography from "@mui/material/Typography";
import {LicenceContext} from "../../Context/LicenceContext";
import {UserContext} from "../../Context/UserContext";

export function TestCaseTextField(props) {

    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const _licenceContext = useContext(LicenceContext)


    const [haveError, setHaveError] = useState(false)
    const [txtError, setTxtError] = useState('')

    useEffect(()=>{
        if(_languageContext.availableLangList.length >1){
            if (props.counstTestCaseEmpty > 0 && tools.isEmpty(props.newTestCaseName)) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        }
    }, [props.counstTestCaseEmpty, props.newTestCaseName])

    useEffect(()=>{
        if(haveError){
            setTxtError("Le nom du cas de test est obligatoire.")
        }
    }, [haveError])


    const textLang = (elem)=>{

        let text = ""
        if(Object.keys(elem).indexOf('lang') !==-1){
            if(Object.keys(elem.lang).indexOf(_userContext.userlang().toLowerCase()) !==-1){
                text = elem.lang[_userContext.userlang().toLowerCase()]
            }
        }
        return text
    }



    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems={'center'}
            width={'100%'}
        >
            <TextField component="div"
                       required={_languageContext.availableLangList.length === 1}
                       aria-readonly={_languageContext.availableLangList.length > 1}
                       fullWidth={true}
                       error={haveError}
                       helperText={txtError}
                       label="Nom du cas de test"
                       value={textLang(props.testCaseInfo)}
                       onInput={(e)=>{
                           if(_languageContext.availableLangList.length === 1) {
                               let txt = e.target.value;
                               const newInfo = {
                                   ...props.testCaseInfo
                               }
                               newInfo.lang[_userContext.userlang().toLowerCase()] = txt
                               props.setTestCaseInfo(newInfo)
                               // props.setNewTestCaseName(txt)
                           }
                       }}

            />
            {
                _languageContext.availableLangList.length > 1 && (
                    <Typography color={'blue'} style={{
                        cursor: 'pointer',
                        textDecoration: 'underline blue',
                    }}
                                onClick={()=>{
                                    props.setOpenDialogAllLang(true)
                                }}
                    >
                        {_userContext.userlang().toUpperCase()}
                    </Typography>
                )
            }
        </Stack>
    )
}
