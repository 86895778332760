import React, {useCallback, useEffect, useState} from 'react';
import {getConnectedEdges, useReactFlow, Node, addEdge, useEdges, useNodes} from '@xyflow/react';
import './style.css';
import {Card} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import tools from '../../Utils/tools'
import {useNavigate} from "react-router-dom";
import ContextMenuPane from "./ContextMenuPane";

export default function ContextMenuNode({
                                            id,
                                            top,
                                            left,
                                            right,
                                            bottom,
                                            onLayout,
                                            ...props
                                        }) {


    return (
        <Card elevation={15}
              style={{top, left}}
              className="context-menu"
              {...props}
        >
            <ContextMenuPane
            id={id}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            onLayout={onLayout}
            setOpenDialogExecuteAll={props.setOpenDialogExecuteAll}
            />
        </Card>
    )
        ;
}
