
const searchInfo = async (stepsRestriction, projectInfo, schema) => {
    let path = []

    if (projectInfo) {
        path = searchPathEndParent('start', [], [],projectInfo, schema, stepsRestriction)
    }

    return path
}

const searchPathEndParent = (source, currentPathNode, paths, projectInfo, schema, includeEnd) => {


    const edgesConnected = schema.edges.filter((edge) => edge.source === source)
    const currentNode = getFirstElement(schema.nodes, (node) => node.id === source)

    if (source !== 'start' && currentNode !== null && currentNode.type !== 'endPathCustom') {

        if (currentNode.type === 'templateCustom') {

            // const template = projectModel.get(`template.${currentNode.data.internalCaseId}`);

            if (projectInfo.template.indexOf(currentNode.data.internalCaseId) !== -1) {
                const template = projectInfo.template[currentNode.data.internalCaseId];
                template.steps.forEach((step, i) => {

                    if (step.type === 'custom') {
                        currentPathNode.push(step.key)
                    }
                })
            }
        } else {
            currentPathNode.push(currentNode.data.internalCaseId)
        }
    }

    // console.log(currentNode)
    if (edgesConnected.length !== 0) {
        edgesConnected.forEach((edge, i) => {
                paths = searchPathEndParent(edge.target, [...currentPathNode], paths, projectInfo, schema, includeEnd)
            }
        )
    } else if (currentNode.type === 'endPathCustom' && (includeEnd.includes(currentNode.id) || includeEnd.length === 0)) {

        paths.push({
            id: currentNode.id,
            title: currentNode.data.label,
            steps: currentPathNode
        })
    }
    return paths;
}

const searchStepInfo = async (stepsToExecute) => {
    let allStepsId = []
    stepsToExecute.forEach((stepsIds) => {
        allStepsId = allStepsId.concat(stepsIds.steps)
    })


    //TODO : use context for get id testCase
}

const searchActionInfo = async (stepsInfo) => {

    let allActions = []
    stepsInfo.forEach((steps) => {
        let actionIds = []
        steps.actions.forEach((action) => {
            actionIds.push(action._id)
        })

        allActions = allActions.concat(actionIds)
    })

    //TODO : use context for get id actions

}


/**
 * Return random number between two number
 * @param min included number
 * @param max excluded number
 * @returns {number}
 */
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

/**
 * Mix values élément in array
 * @param array
 * @returns array
 */
function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

/**
 * Check if value is empty
 * @param value
 * @returns {boolean}
 */
function isEmpty(value) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
}

/**
 * Check if value is empty or undefined
 * @param value
 * @returns {*|boolean}
 */
function isEmptyAndUndefined(value) {
    return (isEmpty(value) || value === undefined);
}

/**
 * Search object key by value
 * @param object
 * @param value
 * @returns {string}
 */
function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

/**
 * Get element in array
 * @param arr
 * @param callback
 * @returns mixed
 */
function getFirstElement(arr, callback){
    if(typeof arr !== "object"){
        throw new Error('First parameter is not an object')
    }

    let i =0;
    let keys = Object.keys(arr);
    let values = Object.values(arr);
    let result = null;

    while (i < keys.length && result === null){
        let index = keys[i];
        let val = values[i];

        if(callback(val, index))
            result = val
        i++;
    }

    return result;
}

function getFirstIndexElement(arr, callback){
    if(typeof arr !== "object"){
        throw new Error('First parameter is not an object')
    }

    let i =0;
    let keys = Object.keys(arr);
    let values = Object.values(arr);
    let result = -1;

    while (i < keys.length && result === -1){
        let index = keys[i];
        let val = values[i];

        if(callback(val, index))
            result = index
        i++;
    }

    return result;
}


// const ABS_PATH = path.resolve("./")
function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}


module.exports = {
    getRandomInt,
    shuffle,
    getFirstElement,
    getFirstIndexElement,
    isEmpty,
    isEmptyOrUndefined: isEmptyAndUndefined,
    getKeyByValue,
    sleep,
    searchInfo,
    searchPathEndParent,
    searchStepInfo,
    searchActionInfo
}