import * as React from 'react';
import Grid from '@mui/material/Grid';
import TransfertList from "./Component/TransfertList";
import TransfertButtons from "./Component/TransfertButtons";
import {useEffect} from "react";


export default function BasicTransferListLang(props) {


    const [leftSelected, setLeftSelected] = React.useState([]);
    const [rightSelected, setRightSelected] = React.useState([]);

    const [disableLeftToRight, setDisableLeftToRight] = React.useState(true);
    const [disableRightToLeft, setDisableRightToLeft] = React.useState(true);
    const [disableCheckbox, setDisableCheckbox] = React.useState(false);

    const [resetCheckboxLeft, setResetCheckboxLeft] = React.useState(0);
    const [resetCheckboxRight, setResetCheckboxRight] = React.useState(0);


    function updateLeftListSelected(val) {
        setLeftSelected(val)
        setDisableLeftToRight(val.length === 0)
        updateAvailableLang()
    }

    function updateRightListSelected(val) {

        setRightSelected(val)
        setDisableRightToLeft(val.length === 0)
    }

    function updateAvailableLang() {
        const maxAvailableLang = props.maxLang - (leftSelected.length + props.right.length)
        props.setAvailableLang(maxAvailableLang)
        setDisableCheckbox(maxAvailableLang <= 0)
    }


    useEffect(() => {
        updateAvailableLang()
    }, [leftSelected,props.right]);
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center"
              style={{width: '100%', height: '100%'}}>
            <Grid item xs={5}>
                <TransfertList
                    // key={'ll'+reloadLeft}
                    listElem={props.left}
                    setElemSelected={updateLeftListSelected}
                    elemSelected={leftSelected}
                    disabledCheckbox={disableCheckbox}
                    resetCheckbox={resetCheckboxLeft}
                />
            </Grid>
            <Grid item xs={2}>
                <TransfertButtons
                    allButtons={false}
                    maxLang={props.maxLang}

                    resetCheckboxLeft={resetCheckboxLeft}
                    setResetCheckboxLeft={setResetCheckboxLeft}
                    resetCheckboxRight={resetCheckboxRight}
                    setResetCheckboxRight={setResetCheckboxRight}

                    disableAllLeftToRight={props.left.length === 0}
                    disableAllRightToLeft={props.right.length === 0}
                    disableLeftToRight={disableLeftToRight}
                    disableRightToLeft={disableRightToLeft}

                    setDisableLeftToRight={setDisableLeftToRight}
                    setDisableRightToLeft={setDisableRightToLeft}

                    listLeftLength={props.left.length}
                    nbSelectedLeft={leftSelected.length}

                    listRightLength={props.right.length}
                    nbSelectedRight={rightSelected.length}

                    listLeft={props.left}
                    setListLeft={props.setLeft}
                    listLeftSelected={leftSelected}
                    setListLeftSelected={updateLeftListSelected}

                    listRight={props.right}
                    setListRight={props.setRight}
                    listRightSelected={rightSelected}
                    setListRightSelected={updateRightListSelected}
                />
            </Grid>
            <Grid item xs={5}>
                <TransfertList
                    listElem={props.right}
                    setElemSelected={updateRightListSelected}
                    elemSelected={rightSelected}
                    disabledCheckbox={false}
                    resetCheckbox={resetCheckboxRight}
                />
            </Grid>
        </Grid>
    );
}