import * as React from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import SidebarContent from '../Content/SidebarContent';
import Box from "@mui/material/Box";
import {Card} from "@mui/material";
import {useEffect, useRef, useState} from "react";

export default function SidebarComputer(props) {

    const drawerWidth = props.width;
    const ref = useRef()
    const [classNameSide, setClassNameSide] = useState('Md-Sidebar-Computer activated')

    useEffect(()=>{

        let className = 'Md-Sidebar-Computer '

        className += props.isOpen ? 'activated' : 'disactivated'
        setClassNameSide(className)
    }, [props.isOpen])




    return (
        <Card open={props.isOpen} className={classNameSide} ref={ref}>
            <SidebarContent

                isOpen={props.isOpen}
                setIsOpen={props.setIsOpen}
                isComputer={props.isComputer}
            >
            </SidebarContent>
        </Card>
    );
}