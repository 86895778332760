import React, {useContext, useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Collapse, Stack, Tooltip} from "@mui/material";
import {LanguageContext} from "../../../Context/LanguageContext";
import List from "@mui/material/List";
import {useLocation} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {CardStepInputType} from "../Step/CardStepInputType";
import tools from "../../../Utils/tools";
import {UserContext} from "../../../Context/UserContext";

export const CardCollapse = ({info}) => {
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const { state } = useLocation();



    function getStrsPos(txt, variables) {

        const regex = /{string}|{int}/gm
        let matches = [...txt.matchAll(regex)];

        let finalTxt = []
        if (matches.length !== 0) {
            let lastIndex = 0
            matches.forEach((match, index) => {
                finalTxt.push(txt.substring(lastIndex, match.index))
                const value = variables.length > index ? variables[index] : null
                finalTxt.push(<CardStepInputType
                    type={match[0]}
                    isDisabled={true}
                    value={value}
                    index={index}
                />)
                lastIndex = match.index + match[0].length
            });
        } else {
            finalTxt.push(txt)
        }

        return finalTxt
    }


    /**
     *
     * @returns {{}|null}
     */
    const defaultLangInfo = () => {
        const currentLang = _languageContext.lang.toLowerCase()
        //allLangList intersection

        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }


    const txtLang = (elem, variables) =>{
        const allDefaultLangInfo = defaultLangInfo()
        const defaultFlag = allDefaultLangInfo.image_binary

        let testCaseLang = <Stack direction="row" spacing={2}>
            <Tooltip title={"Langue : " + allDefaultLangInfo.name} placement={'top'}>
                <img
                    src={'data:image/png;base64, ' + defaultFlag}
                    height={'25px'}
                />
            </Tooltip>
            <Box>{elem[_languageContext.lang.toLowerCase()]}</Box>
        </Stack>;

        if (Object.keys(elem).indexOf(_userContext.userlang().toLowerCase()) !== -1){
            const testCaseLangTmp = elem[_userContext.userlang().toLowerCase()]
            if(!tools.isEmptyOrUndefined(testCaseLang)){
                testCaseLang =  <>{getStrsPos(testCaseLangTmp, variables)}</>
            }
        }
        return testCaseLang
    }

    const [open, setOpen] = useState(false)
    return (
            <Card
                sx={{width:'100%'}}
            >
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        onClick={()=>{
                            setOpen(!open)
                        }}>
                        <Typography variant={"subtitle1"}>
                            {
                                txtLang(info.lang)
                            }
                        </Typography>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </Stack>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Divider variant="middle" flexItem style={{margin: '15px 0'}} />
                        <List component="div" >
                            {info.actions.map((element, i)=>(

                                <ListItem>
                                    {txtLang(element.lang, element.variables)}
                                </ListItem>
                                )
                            )}
                        </List>
                    </Collapse>
                </CardContent>
            </Card>
    );
};

