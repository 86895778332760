import * as React from "react";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Alert,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    InputAdornment,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import tools from '../../Utils/tools'

import {
    ReactFlow,
    addEdge,
    Background,
    ConnectionLineType,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    Panel,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from '@xyflow/react';


import '@xyflow/react/dist/style.css';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import dagre from 'dagre';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {NodeCustomEnd} from "../../Components/ReactFlowCustom/NodeCustom/NodeCustomEnd";
import {LanguageContext} from "../../Context/LanguageContext";
import {UserContext} from "../../Context/UserContext";
import {TranslationContext} from "../../Context/TranslationContext";
import {WebSocketContext} from "../../Context/WebSocketContext";
import Typography from "@mui/material/Typography";
import {CardDraggableReactFlow} from "../../Components/ReactFlowCustom/CardDraggableReactFlow";
import {ProjectContext} from "../../Context/Hook/ProjectContext";
import {useLocation} from "react-router-dom";
import {TestCaseContext} from "../../Context/Hook/TestCaseContext";
import {NodeCustomNotExist} from "../../Components/ReactFlowCustom/NodeCustom/NodeCustomNotExist";
import Snackbar from "@mui/material/Snackbar";
import NodeCustomTemplate from "../../Components/ReactFlowCustom/NodeCustom/NodeCustomTemplate";
import ButtonEdge from "../../Components/ReactFlowCustom/EdgeCustom/ButtonEdge";
import {NodeCustomEndPath} from "../../Components/ReactFlowCustom/NodeCustom/NodeCustomEndPath";
import {NodeCustom} from "../../Components/ReactFlowCustom/NodeCustom/NodeCustom";


const nodeTypes = {
    custom: NodeCustom,
    endPathCustom: NodeCustomEndPath,
    notExist: NodeCustomNotExist,
};
const edgeTypes = {
    buttonEdge: ButtonEdge,
};


const getId = () => `dndnode_${Date.now() + Math.random()}`;


export function UpdateTemplate(props) {
    const {state} = useLocation();
    const navigate = useNavigate();

    const _languageContext = useContext(LanguageContext)
    const {translation, translationForce} = useContext(TranslationContext)
    const _userContext = useContext(UserContext)
    const _webSocketContext = useContext(WebSocketContext)
    const _projectContext = useContext(ProjectContext)
    const _testCaseContext = useContext(TestCaseContext)

    const [testCase, setTestCase] = useState(_testCaseContext.listTestCase)
    const [openSnackbarNotExist, setOpenSnackbarNotExist] = useState(false);

    useEffect(() => {
        setTestCase(_testCaseContext.listTestCase)
    }, [_testCaseContext.listTestCase])


    const initialNodes = [
        {
            id: 'start',
            type: 'input',
            data: {label: translation('project.step.start', 'Projects.json')},
            deletable: false,
            position: {x: 250, y: 5},
        },
    ];

    const finalNode = {
        _id: 'END_TEST',
        type: 'endPathCustom',
        label: translation('project.step.end', 'Projects.json'),
        deletable: true,
    }

    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [projectInfo, setProjectInfo] = useState(_projectContext.getProjectExecutedById(state.project_key))


    const [interactibility, setInteractibility] = useState(true);

    const [loadEdgeAndNode, setLoadEdgeAndNode] = useState(true);
    const ref = useRef(null);

    const boxPaginate = useRef(null);
    const rightHelper = useRef(null);

    const [flowIsLoad, setFlowIsLoad] = useState(false);
    const [pageIsLoad, setPageIsLoad] = useState(false);

    const [openDialogTranslationName, setOpenDialogTranslationName] = useState(false);

    const [maxItemsPaginate, setMaxItemsPaginate] = useState(0);
    const [offsetItemsPaginate, setOffsetItemsPaginate] = useState(0);
    const [paginateTestCases, setPaginateTestCases] = useState([]);
    const [paginateNextDisabled, setPaginateNextDisabled] = useState(false)
    const [paginateBeforeDisabled, setPaginateBeforeDisabled] = useState(true)


    const [windowDimensions, setWindowDimensions] = useState({})


    const getCurrentTemplate = () => {
        const template = projectInfo.hasOwnProperty('template') ? projectInfo.template : []
        return tools.getFirstElement(template, (element, i) => {
            return i === state.template_key
        })
    }


    const [currentTemplate, setCurrentTemplate] = useState(getCurrentTemplate())

    useEffect(() => {
        console.log('update', currentTemplate)
        if (reactFlowInstance !== null) {

            const updateProject = {
                path: 'project/template/update',
                info: {
                    project_id: state.project_key,
                    template_id: state.template_key,
                    update: currentTemplate
                }
            }
            const updateProjectId = _webSocketContext.send(updateProject, (message) => {
                console.log(message)
            })
        }
    }, [currentTemplate])

    let schemaLasted = [];
    const itemHeight = 48;


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {

        setNodes((nds) =>
            nds.map((node) => {

                if (node.id === 'start') {
                    // it's important that you create a new object here
                    // in order to notify react flow about the change
                    node.data = {
                        ...node.data,
                        label: translationForce(_userContext.userlang(), 'project.step.start', 'Projects.json'),
                    };

                }
                return node;

            })
        );

    }, [_userContext, setNodes]);


    const calculMaxItem = () => {
        if (boxPaginate.current !== null && rightHelper.current != null) {
            const box = boxPaginate.current;
            const maxItems = Math.floor(box.offsetHeight / itemHeight);

            setMaxItemsPaginate(maxItems)
        }
    }


    useEffect(() => {
        if (rightHelper.current) {
            calculMaxItem()
        }
    }, [rightHelper, windowDimensions]);


    useEffect(() => {

        function handleResize() {
            calculMaxItem()
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        calculMaxItem()
    }, []);


    useEffect(() => {
        setPaginateTestCases(testCase.slice(offsetItemsPaginate, offsetItemsPaginate + maxItemsPaginate))
        calculMaxItem()
    }, [offsetItemsPaginate, maxItemsPaginate, testCase]);


    const paginateNext = () => {
        const totalLength = testCase.length;
        const offset = offsetItemsPaginate;
        if (totalLength > offsetItemsPaginate + maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setOffsetItemsPaginate(offset + maxItemsPaginate)
        }
        setPaginateNextDisabled(totalLength <= offset + (maxItemsPaginate * 2))
    }
    const paginateBefore = () => {
        if (0 < offsetItemsPaginate - maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setPaginateNextDisabled(false)
            setOffsetItemsPaginate(offsetItemsPaginate - maxItemsPaginate)
        } else {
            setOffsetItemsPaginate(0)
            setPaginateBeforeDisabled(true)
            setPaginateNextDisabled(false)
        }
    }

    const handleCloseDialogLangNames = () => {
        setOpenDialogTranslationName(false)
    }


    useEffect(() => {
        if (!flowIsLoad) {
            setPaginateNextDisabled(maxItemsPaginate >= testCase.length)
            setPageIsLoad(true);
            console.debug(currentTemplate.steps)
            initializeNodesAndEdges(currentTemplate.steps)
        }
    }, [flowIsLoad]);


    const onConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge({
                        ...params,
                        type: 'buttonEdge',
                        deletable: true,
                        style: {color: '#b2b2b2', width: '40px'},
                        animated: false,
                        markerEnd: {type: 'arrow', color: '#b2b2b2', width: '30px', height: '30px'},

                    },
                    eds
                )
            ),
        [setEdges]
    );

    const initializeNodesAndEdges = (steps) => {

        let initNodes = [];
        let initEdges = [];
        let containNotExist = false;
        const initPos = {
            x: 200,
            y: 50,
        }

        let lastLineId = 'start';

        steps.forEach((step, i) => {
            const newNodeId = getId()
            let tc = testCase.filter((testCase) => testCase._id === step.key);
            let newNode = {}

            if (tc.length === 0 && step.type !== finalNode.type && step.type !== 'templateCustom' || (step.type === 'templateCustom' && Object.keys(projectInfo.template).indexOf(step.key) === -1)) {
                console.log(step)
                newNode = {
                    id: newNodeId,
                    type: 'notExist',
                    data: {
                        label: 'Not exist',
                        internalCaseId: 'NaN',
                    },
                    deletable: true,
                    position: {
                        x: step.position.x, y: step.position.y
                    }
                }
                containNotExist = true
            } else {
                if (step.type === finalNode.type) {
                    newNode = {
                        id: newNodeId,
                        type: finalNode.type,
                        data: {
                            label: finalNode.label,
                            internalCaseId: newNodeId,
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        }
                    }
                } else if (step.type === 'templateCustom') {
                    newNode = {
                        id: newNodeId,
                        type: 'templateCustom',
                        data: {
                            label: projectInfo.template[step.key].lang,
                            internalCaseId: step.key,
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        }
                    }
                } else {
                    newNode = {
                        id: newNodeId,
                        type: 'custom',
                        data: {
                            label: tc[0].lang,
                            internalCaseId: tc[0]._id,
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        }
                    }
                }
            }
            initNodes.push(newNode)

            let newEdge = {
                id: 'e' + lastLineId + '-' + newNodeId,
                source: lastLineId,
                target: newNodeId,
                type: 'buttonEdge',
                deletable: true,
                style: {color: '#b2b2b2', width: '40px'},
                animated: false,
                markerEnd: {type: 'arrow', color: '#b2b2b2', width: '30px', height: '30px'},
            }
            initEdges.push(newEdge)

            lastLineId = newNodeId
        })

        let nodesInit = initialNodes.concat(initNodes)

        setNodes(nodesInit)
        setEdges(initEdges)
        setLoadEdgeAndNode(false)
        setOpenSnackbarNotExist(containNotExist)
    }

    useEffect(() => {

        if (reactFlowInstance && !loadEdgeAndNode) {
            onLayout('TD')
            tools.sleep((nodes.length * 500)).then(() => {
                reactFlowInstance?.fitView();
                setFlowIsLoad(true)
            })
        }
    }, [loadEdgeAndNode, reactFlowInstance]);


    const onDrop = useCallback(
        (event, isComputer, type) => {
            event.preventDefault();

            let pos = {}

            if (isComputer) {
                pos.x = event.clientX;
                pos.y = event.clientY;

            } else {
                pos.x = event.changedTouches[0].clientX;
                pos.y = event.changedTouches[0].clientY;
            }

            const refX = ref.current.offsetLeft;
            const refWidth = ref.current.offsetWidth;
            const refY = ref.current.offsetTop;
            const refHeight = ref.current.offsetHeight;

            if (!(pos.x >= refX && pos.x < refX + refWidth && pos.y >= refY && pos.y < refY + refHeight)) {
                return null
            }


            if (type === null) {
                return;
            }

            type = JSON.parse(type)
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
            // and you don't need to subtract the reactFlowBounds.left/top anymore
            // details: https://reactflow.dev/whats-new/2023-11-10
            const position = reactFlowInstance.screenToFlowPosition(pos);

            let newNode = {
                id: getId(),
                type: 'custom',
                position,
                data: {
                    label: type.lang,
                    internalCaseId: type._id,
                },
            };

            if (type._id === "END_TEST") {
                newNode = {
                    ...type,
                    id: 'END',
                    type: finalNode.type,
                    position: {...position},
                    data: finalNode
                }

            }
            setNodes((nds) => nds.concat(newNode));


        },
        [reactFlowInstance],
    );


    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onNodesDelete = useCallback(
        (deleted) => {
            setEdges(
                deleted.reduce((acc, node) => {
                    const incomers = getIncomers(node, nodes, edges);
                    const outgoers = getOutgoers(node, nodes, edges);
                    const connectedEdges = getConnectedEdges([node], edges);

                    const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

                    const createdEdges = incomers.flatMap(({id: source}) =>
                        outgoers.map(({id: target}) => ({id: `${source}->${target}`, source, target}))
                    );

                    return [...remainingEdges, ...createdEdges];
                }, edges)
            );
        },
        [nodes, edges]
    );

    const getLayoutedElements = (nodes, edges) => {
        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({rankdir: 'TB', edgesep: 50, ranksep: 100, nodesep: 100});

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, {width: node.measured.width, height: node.measured.height});
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);


        const newNodes = nodes.map((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            const newNode = {
                ...node,
                targetPosition:  'top',
                sourcePosition:  'bottom',
                // We are shifting the dagre node position (anchor=center center) to the top left
                // so it matches the React Flow node anchor point (top left).
                position: {
                    x: nodeWithPosition.x - node.measured.width,
                    y: nodeWithPosition.y - node.measured.height / 2,
                },
            };

            return newNode;
        });

        return { nodes: newNodes, edges };
    };

    const onLayout = useCallback(
        (direction) => {
            const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
                nodes,
                edges,
                direction
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );


    const searchPathChild = (source, currentPath, paths, allInfo) => {
        const edgesConnected = allInfo.edges.filter((edge) => edge.source === source)
        const currentNode = allInfo.nodes.filter((node) => node.id === source)
        if (source !== 'start' && currentNode.length > 0) {
            currentPath.push({
                type: currentNode[0].data.type ? currentNode[0].data.type : currentNode[0].type,
                key: currentNode[0].data.internalCaseId,
                position: {
                    x: currentNode[0].position.x,
                    y: currentNode[0].position.y,
                }
            })
        }

        if (edgesConnected.length !== 0) {
            edgesConnected.forEach((edge, i) => {
                    paths = searchPathChild(edge.target, [...currentPath], paths, allInfo)
                }
            )
        } else {
            paths = currentPath
        }

        return paths;
    }

    const onSave = useCallback(() => {

        if (reactFlowInstance) {

            const flow = reactFlowInstance.toObject();

            let schema = [];
            const edgesConnected = flow.edges;
            edgesConnected.forEach((edgeInfo, i) => {

                if (edgeInfo.source === 'start') {
                    schema = searchPathChild('start', '', [], flow)
                }
            })

            //fixme change projectInfo
            console.log(schema)
            if (schema.toString() != schemaLasted.toString()) {
                schemaLasted = schema
                const tmp = {
                    ...currentTemplate,
                    steps: schema,
                    description: {}
                }
                console.log(tmp)

                setCurrentTemplate(tmp)
            }


        }
    }, [reactFlowInstance]);


    const readNameProject = () => {
        let name = '';
        const currentLang = _userContext.userlang().toLowerCase()

        //Fixme
        if (Object.keys(projectInfo).length !== 0 && projectInfo.lang[currentLang] !== undefined) {
            name = projectInfo.lang[currentLang]
        }
        return name;
    }


    useEffect(() => {
        if (pageIsLoad) {
            onSave()
        }
    });

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const readNameProjectByLang = (lang) => {
        let name = '';
        //Fixme
        // const projectInfo = projectInfo
        // if (Object.keys(projectInfo).length !== 0 && projectInfo.lang[lang] !== undefined) {
        //     const projectName = projectInfo.lang[lang]
        //     name = projectName === '' ? '' : projectName
        // }

        return name;
    }

    const languageInput = (info) => {

        return <Grid item xs={4}>

            <TextField
                value={
                    readNameProjectByLang(info.code.toLowerCase())
                }
                onInput={(e) => {
                    //fixme change projectInfo
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <img
                            src={'data:image/png;base64, ' + info.image_binary}
                            height={'25px'}
                        />
                    </InputAdornment>,
                }}
            />
        </Grid>
    }


    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }

        return current
    }


    const projectNameAndUpdate = () => {
        let elem = <>
            <Grid item xs={11}>
                <TextField fullWidth={true} readOnly={true} variant={'outlined'} value={readNameProject()}></TextField>
            </Grid>
            <Grid item alignItems={"center"} xs={1}

                  onClick={() => {
                      setOpenDialogTranslationName(true)
                  }}


            >
                <Typography color={'blue'} style={{
                    cursor: 'pointer',
                    textDecoration: 'underline blue',
                }}>{_userContext.userlang().toUpperCase()}</Typography>
            </Grid>
        </>

        if (_languageContext.availableLangList.length === 1) {

            elem = <>
                <Grid item>
                    <Input value={readNameProject()}
                           onInput={(e) => {
                               //FIXME : cahnge projectInfo

                           }}
                    ></Input>

                </Grid>
            </>
        }


        return <Grid container justifyContent={"center"} spacing={2}
                     alignItems="center"
        >
            {elem}
        </Grid>

    }


    const actions = [
        {
            icon: <AccountTreeOutlinedIcon/>, name: 'Reorder', condition: true, function: () => {
                onLayout('TB')
            }
        },
        {
            icon: <ZoomInIcon/>,
            name: 'Zoom in',
            condition: true,
            function: () => reactFlowInstance?.zoomIn({duration: 400})
        },
        {
            icon: <ZoomOutIcon/>,
            name: 'Zoom Out',
            condition: true,
            function: () => reactFlowInstance?.zoomOut({duration: 400})
        },
        {
            icon: <CropFreeOutlinedIcon/>, name: 'focus', condition: true, function: () => {
                const {x, y, zoom} = reactFlowInstance.getViewport()
                reactFlowInstance.fitView({x, y, zoom})
            }
        },
        {
            icon: <LockOutlinedIcon/>, name: 'Lock', condition: interactibility, function: () => {
                setInteractibility(false)

            }
        },
        {
            icon: <LockOpenOutlinedIcon/>, name: 'Unlock', condition: !interactibility, function: () => {
                setInteractibility(true)
            }
        },
        {
            icon: <DescriptionOutlinedIcon/>, name: 'Documentation', condition: true, function: () => {
                //todo
            }
        },

    ];

    return (
        <Box
            style={{width: '100%'}}>

            <Grid
                container
                justifyContent={'center'}
                spacing={2}

                style={{height: '100%'}}
            >
                <Grid item xs>

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{width: '100%', height: '100%'}}
                        spacing={1}
                    >
                        <Grid item
                              style={{width: '100%'}}>

                            <Card sx={{padding: '10px'}}>
                                <Stack
                                    style={{width: '100%'}}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button
                                        variant={'text'}
                                        style={{color: '#FF7233'}}
                                        onClick={() => {
                                            navigate('/update_project')
                                        }}
                                    >
                                        Revenir sur le projet
                                    </Button>
                                    {projectNameAndUpdate()}
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xs style={{
                            height: '100%',
                            width: '100%'
                        }}>
                            <Card
                                style={{
                                    height: '100%'
                                }}>
                                <ReactFlowProvider>
                                    <div className="reactflow-wrapper dialog" ref={reactFlowWrapper}

                                    >
                                        <ReactFlow
                                            ref={ref}
                                            nodes={nodes}
                                            edges={edges}
                                            onNodesChange={onNodesChange}
                                            onEdgesChange={onEdgesChange}
                                            onConnect={onConnect}
                                            nodeTypes={nodeTypes}
                                            edgeTypes={edgeTypes}
                                            onInit={setReactFlowInstance}
                                            onDrop={onDrop}
                                            onNodesDelete={onNodesDelete}
                                            onDragOver={onDragOver}
                                            fitView
                                            className="touchdevice-flow"
                                            connectionLineType={ConnectionLineType.SmoothStep}

                                            edgesUpdatable={interactibility}
                                            edgesFocusable={interactibility}
                                            nodesDraggable={interactibility}
                                            nodesConnectable={interactibility}
                                            nodesFocusable={interactibility}
                                            elementsSelectable={interactibility}
                                        >
                                            <Panel position="top-right">

                                                <SpeedDial
                                                    ariaLabel="SpeedDial basic example"
                                                    direction={'down'}
                                                    icon={<SpeedDialIcon/>}
                                                >
                                                    {actions.map((action) => {
                                                        if (action.condition) {
                                                            return <SpeedDialAction
                                                                key={action.name}
                                                                icon={action.icon}
                                                                tooltipTitle={action.name}
                                                                onClick={action.function}
                                                            />
                                                        }
                                                    })}
                                                </SpeedDial>
                                            </Panel>
                                            <Background/>

                                        </ReactFlow>
                                    </div>
                                </ReactFlowProvider>
                            </Card>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={3} minWidth={330} style={{height: '100%'}}>
                    <Card sx={{padding: '2px'}}
                        // height={'100%'}
                          style={{height: '100%'}}
                        // style={{height:'95vh'}}
                          ref={rightHelper}>

                        <Stack
                            direction="column"
                            justify="space-between"
                            alignItems="center"
                            width={'100%'}

                            style={{height: '100%'}}
                        >
                            {/*<Box width={'100%'}>*/}


                            {/*    <Box sx={{*/}
                            {/*        display: 'block',*/}
                            {/*        margin: '5px'*/}
                            {/*    }}>*/}
                            {/*        /!*<InputSearch/>*!/*/}

                            {/*    </Box>*/}
                            {/*</Box>*/}
                            <Box width={'100%'}
                                 style={{height: '100%'}}>
                                <CardDraggableReactFlow
                                    dataInfo={JSON.stringify(finalNode)}
                                    height={itemHeight + 'px'}
                                    className="dndnodeext"
                                    onDrop={onDrop}
                                    cardType='END'
                                >
                                    {
                                        translation('project.step.ending', 'Projects.json')
                                    }
                                </CardDraggableReactFlow>
                                <Divider/>
                                <Box ref={boxPaginate} width={'100%'}
                                     style={{height: '100%'}}>
                                    {
                                        paginateTestCases.map((v, i) => (

                                            <CardDraggableReactFlow
                                                dataInfo={JSON.stringify(v)}
                                                height={itemHeight + 'px'}
                                                className="dndnodeext"
                                                onDrop={onDrop}
                                                cardType='STEP'
                                            >
                                                {
                                                    txtLang(v.lang)
                                                }
                                            </CardDraggableReactFlow>
                                        ))
                                    }

                                </Box>
                            </Box>
                            <Box width={'100%'}>
                                <Box sx={{display: 'block', alignItems: 'center', width: '100%'}} bottom={0}>
                                    <Divider/>
                                    <div style={{padding: "10px",}}>
                                        <IconButton
                                            aria-label="previous"
                                            fontSize="large"

                                            style={{
                                                color: paginateBeforeDisabled ? 'grey' : "#FF7233"
                                            }}
                                            onClick={() => {
                                                paginateBefore()
                                            }}
                                        >
                                            <NavigateBeforeIcon/>
                                        </IconButton>
                                        <span style={{width: "5vw", display: 'inline-block'}}/>
                                        <IconButton aria-label="next"
                                                    style={{
                                                        color: paginateNextDisabled ? 'grey' : "#FF7233"
                                                    }}
                                                    onClick={() => {
                                                        paginateNext()
                                                    }}
                                                    disabled={paginateNextDisabled}
                                        >
                                            <NavigateNextIcon/>
                                        </IconButton>
                                    </div>
                                </Box>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>

            <Snackbar open={openSnackbarNotExist} autoHideDuration={6000} onClose={
                () => {
                    setOpenSnackbarNotExist(false)
                }
            }>
                <Alert
                    onClose={() => {
                        setOpenSnackbarNotExist(false)
                    }}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Des case de test n'existent plus, veuillez corriger les cheminements pour pouvoir re-exécuter le
                    cheminement en erreur.
                </Alert>
            </Snackbar>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                open={openDialogTranslationName}
                onClose={handleCloseDialogLangNames}
            >
                <DialogTitle>Le nom du projet par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}>
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogLangNames}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>

    )

}
