import * as React from 'react';
import Box from '@mui/material/Box';
import {
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid, InputAdornment,
    Stack, TextField,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";

import {motion} from "framer-motion"
import {useContext, useRef, useState} from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";
import tools from "../../../Utils/tools";

export default function CardSentenceUpdate(props) {
    const navigate = useNavigate();
    const _languageContext = useContext(LanguageContext);
    const _userContext = useContext(UserContext);
    const [openLangDialog, setOpenLangDialog] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)


    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        let name = <Box>{current}</Box>

        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = props.infoLang(_languageContext.lang)
            let flag = <></>

            if (_userContext.userlang() !== _languageContext.lang) {
                flag = <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            name = <Stack direction={'row'} spacing={2}>
                {flag}

                <Box>{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const readNameProjectByLang = (lang) => {
        let name = '';

        if (Object.keys(props.info).length !== 0 && props.info.lang[lang] !== undefined) {
            const projectName = props.info.lang[lang]
            name = projectName
        }

        return name;
    }

    const languageInput = (info) => {

        return <Grid item xs={4}>

            <TextField
                aria-readonly={true}
                value={
                    readNameProjectByLang(info.code.toLowerCase())
                }
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <img
                            src={'data:image/png;base64, ' + info.image_binary}
                            height={'25px'}
                        />
                    </InputAdornment>,
                }}
            />
        </Grid>
    }

    // draggableVariant
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    {txtLang(props.info.lang)}
                </Grid>

                <Grid item textAlign={'right'}>

                    <ButtonGroup variant="outlined" size={'small'}>
                        <Button
                            style={{
                                fontSize: "12px"
                            }}
                            onClick={() => {
                                props.setCurrentAction(props.info._id);
                                navigate('/update_action')
                            }}
                        >Modifier</Button>
                        {
                            _languageContext.availableLangList.length > 1 && (
                                <Button
                                    style={{
                                        fontSize: "12px"
                                    }}
                                    onClick={() => {
                                        setOpenLangDialog(true)
                                    }}>Autre langues</Button>
                            )
                        }
                        <Button
                            style={{
                                fontSize: "12px"
                            }}
                            onClick={() => {
                                // deleteOneTestCase(value._id)
                            }}
                        >Duppliquer</Button>
                        <Button
                            style={{
                                fontSize: "12px"
                            }}
                            onClick={() => {
                                setOpenDeleteDialog(true)
                            }}
                        >Supprimer</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>



            {/*<Card style={{userSelect: 'none', cursor: "pointer", width: '100%'}}>*/}
            {/*    <CardContent sx={{width: '100%'}}>*/}
            {/*        <Stack sx={{width: '100%'}} direction={'column'} spacing={2}>*/}
            {/*            <Stack sx={{width: '100%'}} direction={'row'} justifyContent={"space-between"}>*/}
            {/*                <Typography component="div" style={{fontSize: "10px"}}>*/}
            {/*                    Id : {props.info._id}*/}
            {/*                </Typography>*/}
            {/*                <Typography component="div" style={{*/}
            {/*                    fontSize: "9px",*/}
            {/*                    border: '1px solid #FF7233',*/}
            {/*                    borderRadius: '10%',*/}
            {/*                    padding: '2px',*/}
            {/*                    color: props.color,*/}
            {/*                    width: 'fit-content'*/}
            {/*                }}>*/}
            {/*                    /!*{props.info.categ}*!/*/}
            {/*                    default*/}
            {/*                </Typography>*/}
            {/*            </Stack>*/}
            {/*            <Grid container justifyContent={"space-between"}>*/}
            {/*                <Grid item sx={8}>*/}
            {/*                    <Typography justifyContent={"flex-center"} style={{fontSize: "18px"}}*/}
            {/*                                color="text.primary"*/}
            {/*                                component="div">*/}
            {/*                        {txtLang(props.info.lang)}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid item sx={4}>*/}
            {/*                    <ButtonGroup variant="outlined" aria-label="actions">*/}
            {/*                        <Button onClick={() => {*/}
            {/*                            props.setCurrentAction(props.info._id);*/}
            {/*                            navigate('/update_action')*/}
            {/*                        }}*/}
            {/*                        >*/}
            {/*                            Modifier*/}
            {/*                        </Button>*/}
            {/*                        {*/}
            {/*                            _languageContext.availableLangList.length > 1 && (*/}
            {/*                                <Button onClick={() => {*/}
            {/*                                    setOpenLangDialog(true)*/}
            {/*                                }}>Autre langues</Button>*/}
            {/*                            )*/}
            {/*                        }*/}
            {/*                        <Button onClick={() => {*/}
            {/*                            setOpenDeleteDialog(true)*/}
            {/*                        }}>Supprimer</Button>*/}
            {/*                    </ButtonGroup>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Stack>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                open={openLangDialog}
                onClose={() => {
                    setOpenLangDialog(false)
                }}
            >
                <DialogTitle>Le nom du projet par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}>
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenLangDialog(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                scroll={'paper'}
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                }}
            >
                <DialogTitle>
                    <Typography variant={"h5"} color={'red'}>
                        Attention
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    Vous êtes sur le point de supprimer cette action.
                    <br/>
                    Souhaitez vous continuer la suppression ?


                </DialogContent>
                <DialogActions>
                    <Button color="error" variant={"outlined"} onClick={() => {
                        props.deleteAction(props.info._id)
                        setOpenDeleteDialog(false)
                    }}>Supprimer</Button>
                    <Button onClick={() => {
                        setOpenDeleteDialog(false)
                    }}>Annuler</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}