import {createContext, useContext, useEffect, useState} from 'react';
import {WebSocketContext} from "./WebSocketContext";
import tools from "../Utils/tools";
import {LanguageContext} from "./LanguageContext";

const UserContext = createContext(null)

function UserProvider({children}) {

    const _languageContext = useContext(LanguageContext)
    const _webSocketContext = useContext(WebSocketContext)
    const webSocket = _webSocketContext.ws
    const lastMsg = _webSocketContext.lastMsg

    // const [lang, setLang] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const userlang = ()=>{

        const lang = userInfo=== null ? '': userInfo.default_lang

        return tools.isEmptyOrUndefined(lang)  ? _languageContext.lang: lang
    }

    const setUserLang = (lang)=>{
        const availableLangs = _languageContext.availableLangList
        const defaultLang = _languageContext.lang;
        const browserLang = navigator.language

        let language = lang
        if(availableLangs.indexOf(lang) === -1){
            language = browserLang
            if(availableLangs.indexOf(browserLang) === -1){
                language = defaultLang
            }
        }

        const newUserInfo = {
            ...userInfo,
            default_lang:language
        }
        setUserInfo(newUserInfo)
        const updateUserLang = {
            path: 'user/update',
            info: newUserInfo

        }
        const updateUserLangId = _webSocketContext.send(updateUserLang, (message) => {})
    }

    return (
        <UserContext.Provider value={{userlang, setUserLang, setUserInfo, userInfo}}>
            {children}
        </UserContext.Provider>
    );
}

export {UserContext, UserProvider};
