import * as React from "react";
import {Card, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import './CardStep.css'
import {createRef, useContext, useEffect, useRef, useState} from "react";
import tools from "../../../Utils/tools";
import {LanguageContext} from "../../../Context/LanguageContext";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import {CardStepInputType} from "./CardStepInputType";
import {UserContext} from "../../../Context/UserContext";

let lastDropzone = -1
let scrollingPx = 0
export function CardStep(props) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [scrollPosition, setScrollPosition] = useState(getScroll);
    const [newPosition, setNewPosition] = useState({x: 0, y: 0});
    const [initPos, setInitPos] = useState({x: 0, y: 0});
    const [cssPosition, setCssPosition] = useState('relative');
    const ref = useRef()

    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)

    // let arrayInputVal = []
    const [lockStep, setLockStep] = useState(props.info.lock)
    const [action, setAction] = useState(<></>)

    useEffect(() => {
        function handleScroll() {
            setScrollPosition(getScroll())
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const infoPosition = ref.current.getBoundingClientRect()
        setInitPos({
            x: infoPosition.x + (infoPosition.width / 2),
            y: infoPosition.y + (infoPosition.height / 2)
        })

    }, [windowDimensions.width, windowDimensions.height])

    useEffect(() => {
        const infoPosition = ref.current.getBoundingClientRect()
        setInitPos({
            x: infoPosition.x + (infoPosition.width / 2),
            y: infoPosition.y + (infoPosition.height / 2)
        })

    }, [scrollPosition.scrollY])



    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    function getScroll() {
        const {scrollY: scrollY} = window;
        return {
            scrollY
        };
    }

    function mouseDown(e) {
        if (!lockStep) {
            document.addEventListener('mousemove', mouseMove)
            document.addEventListener('touchmove', touchMove)
            document.addEventListener('pointerup', pointerUp)
            document.addEventListener('touchend', pointerUp)
        }
    }

    const movement = (newX, newY ) => {

        const initY = initPos.y
        const index = tools.getFirstIndexElement(props.dropzoneLocation, (positionsDrop, i) => {

            if (parseInt(i) === lastDropzone && lastDropzone !== -1) {
                return newX >= positionsDrop.xStart - 150 && newX <= positionsDrop.xEnd + 150
                    && newY >= positionsDrop.yStart - 50 && newY <= positionsDrop.yStart + 150

            } else {

                return newX >= positionsDrop.xStart - 150 && newX <= positionsDrop.xEnd + 150
                    && newY >= positionsDrop.yStart - 50 && newY <= positionsDrop.yEnd + 50
            }


        })

        let addTopHover = 0
        const parsedIndex = parseInt(index)
            if (parsedIndex !== -1) {

                if(parsedIndex !== props.cardPos ){
                    lastDropzone = parseInt(parsedIndex)
                    // setLastDropzone(parseInt(index))
                    const element = props.dropzoneLocation[parsedIndex].current
                    removeHoverCss()
                    if (element.classList.contains('DropZoneScenario')) {
                        element.classList.add("hoverY");
                        element.classList.remove('endHover')
                    }
                }
                if (parsedIndex <= props.cardPos) {
                    addTopHover = 150
                }
            } else {

                if (lastDropzone !== -1) {

                    removeHoverCss()
                }
                lastDropzone = -1
            }

        setNewPosition({
            x: (0),
            // y: (newY - initY )
            y: (newY - initY - addTopHover + (scrollingPx))
        })
    }

    function mouseMove(e) {
        let newX = e.clientX
        let newY = e.clientY

        movement(newX, newY)

    }

    function touchMove(e) {
        let newY = e.touches[0].clientY
        document.body.style.overflow = 'hidden';

        setCssPosition('absolute')
        scrollOnTouch(newY)
        movement(0, newY)

    }



    const scrollOnTouch = (userY)=>{
        const windowH = windowDimensions.height

        let scrolling = 0

        if(userY < (windowH /5)){
            scrolling = -20
        }
        if(userY > ((windowH /5)*4)){
            scrolling = +20
        }
        scrollingPx =window.scrollY  + scrolling
        window.scrollTo({top: scrollingPx, behavior: "smooth" })
    }

    const removeListerner = () => {
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('touchmove', touchMove)
        document.removeEventListener('pointerup', pointerUp)
        document.removeEventListener('touchend', pointerUp)
    }


    const pointerUp = (e) => {
        removeListerner()
        setCssPosition('relative')
        document.body.style.overflow = 'visible';
        scrollingPx = 0
        if (lastDropzone !== -1) {

            removeHoverCss()
            props.moveToList(props.cardPos, lastDropzone, props.info)
            lastDropzone = -1
        }
        setNewPosition({
            x: (0),
            y: (0)
        })
    }


    const removeHoverCss = () => {

        props.dropzoneLocation.forEach((element, i) => {
            const current = element.current

            current.classList.add("endHover");
            current.classList.remove("hoverY");
        })
    }


    useEffect(() => {
            const infoPosition = ref.current.getBoundingClientRect()
            setInitPos({
                x: infoPosition.x + (infoPosition.width / 2),
                y: infoPosition.y + (infoPosition.height / 2)
            })


    }, [])


    const changeInfo = (index, newValue) => {
        let newInfo = {...props.info}
        newInfo.variables[index] = newValue

        props.changeActionInfo(props.cardPos, newInfo)
    }


    function getStrsPos(txt) {

        const regex = /{string}|{int}/gm
        let matches = [...txt.matchAll(regex)];

        let finalTxt = []
        if (matches.length !== 0) {
            let lastIndex = 0
            matches.forEach((match, index) => {
                finalTxt.push(txt.substring(lastIndex, match.index))
                const value = props.info.variables.length > index ? props.info.variables[index] : null
                finalTxt.push(<CardStepInputType
                    type={match[0]}
                    isDisabled={lockStep}
                    value={value}
                    index={index}
                    changeInfo={changeInfo}
                />)
                lastIndex = match.index + match[0].length
            });
        } else {
            finalTxt.push(txt)
        }


        return finalTxt
    }


    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        return current
    }

    // useEffect(() => {
    //     console.log(props.info)
    //     let info = {
    //         pos: props.info.pos,
    //         step: props.info._id,
    //         key: props.info.key,
    //         variables: {
    //             language: _languageContext.lang,
    //             values: inputValues
    //         }
    //     }
    //
    //     // props.setDataStepInfo(info )
    // }, [uniqueModif]);


    useEffect(() => {
        setAction(getStrsPos(txtLang(props.info.lang)))
    }, [props.info, _userContext.userlang(), lockStep]);


    const toggleLockStep = () => {
        setLockStep(!lockStep)
    }
    const toggleLockStepDisplay = () => {
        return lockStep ? <LockOutlinedIcon onClick={toggleLockStep}/> :
            <LockOpenOutlinedIcon onClick={toggleLockStep}/>
    }


    return (
        <Card
            style={{
                margin: '2vh 0px',
                transform: `translate(${newPosition.x}px, ${newPosition.y}px)`,
                position:cssPosition,
                height:'120px'
            }}
            elevation={4}
            ref={ref}
        >
            <CardContent>
                <Stack
                    direction='row'
                    alignItems="center"
                    width={'100%'}
                    spacing={2}
                >

                    <Stack
                        direction='row'
                        alignItems="center"
                        spacing={2}
                        width={'100%'}
                    >
                        <DragIndicatorOutlinedIcon
                            style={{userSelect: 'none', cursor: "pointer"}}
                            onMouseDown={mouseDown} onTouchStart={mouseDown}
                        />


                        <Typography justifyContent={"flex-center"} style={{fontSize: "18px"}} color="text.primary"
                                    className={'textStep'}
                                    component="div"
                            // dangerouslySetInnerHTML={{__html: action}}
                                    width={'100%'}
                        >
                            {action}
                        </Typography>

                    </Stack>
                    <Stack
                        direction="column"
                        alignItems={'flex-end'}
                        spacing={1}
                    >


                        <Typography style={{
                            fontSize: "12px",
                            border: '1px solid #FF7233',
                            borderRadius: '10%',
                            color: props.info.color,
                            width: "fit-content"
                        }}>
                            default
                            {/*{props.info.categ}*/}
                        </Typography>
                        <Stack
                            direction='row'
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems="center"

                            width={'100%'}
                        >

                            {toggleLockStepDisplay()}

                            <DeleteOutlineOutlinedIcon className={'trash'} onClick={() => {
                                props.removeToList(props.cardPos)
                            }}/>
                        </Stack>


                    </Stack>
                </Stack>


            </CardContent>
        </Card>
    )
}