import {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../../Context/WebSocketContext";
import {BackdropLoader} from "../../Components/BackdropLoader/BackdropLoader";
import {LicenceContext} from "../../Context/LicenceContext";
import {LanguageContext} from "../../Context/LanguageContext";
import tools from "../../Utils/tools";
import {UserContext} from "../../Context/UserContext";
import {ProjectContext} from "../../Context/Hook/ProjectContext";
import {TestCaseContext} from "../../Context/Hook/TestCaseContext";

export function LoadingInitAllInfo(props) {

    const _webSocketContext = useContext(WebSocketContext)
    const _languageContext = useContext(LanguageContext)
    const _licenceContext = useContext(LicenceContext)
    const _projectExecutionContext = useContext(ProjectContext)
    const _testCaseContext = useContext(TestCaseContext)
    const _userContext = useContext(UserContext)

    const wsIsConnected = _webSocketContext.wsIsConnected


    const [init, setInit] = useState(false)
    const [loadState, setLoadState] = useState(0)

    const msgLoad = [
        'Demande de récupération des informations',
        'Récupération des informations',
        'Chargement de l\'application'
    ]


    const getInitialInfo = () => {

        const msgGlobalConfig = {
            path: 'global_configuration',
        }

        const msgGlobalConfigId =_webSocketContext.send(msgGlobalConfig, (message) => {
            loadApp(message.info.globalConfiguration)
            console.log(message.info.globalConfiguration)
            _languageContext.setAvailableLangList(message.info.globalConfiguration.available_language)
        })
        //_webSocketContext.unsubscribe(msgGlobalConfigId)
        setLoadState(1)

        const allLangWithImg = {
            path: 'language/list/all_with_img',
        }
        const allLangWithImgId = _webSocketContext.send(allLangWithImg, (message) => {
            _languageContext.setAllLangList(message.info.langs)
        })
        //_webSocketContext.unsubscribe(allLangWithImgId)

        const getOrCreateUser = {
            path: 'user/get_or_create',
            info: {
                ref: (typeof InstallTrigger !== 'undefined') ? 'test' : ' other'
            }
        }

        const getOrCreateUserId = _webSocketContext.send(getOrCreateUser, (message) => {
            console.log(message)
            _userContext.setUserInfo(message.info.user)
        })

        _projectExecutionContext.initialize()
        _testCaseContext.initialize()

    }

    useEffect(() => {
        if (!init && wsIsConnected) {
            setInit(true)
            getInitialInfo()
        }
    }, [init, wsIsConnected]);


    const loadApp = (info) => {
        setLoadState(2)
        // props.setIsLoad(true)
        _licenceContext.initialize(info)
        if (!info.tutorial.first_connection.finish) {
            props.setLoadState(1)
        } else {
            props.setLoadState(2)
        }
    }

    return (
        <BackdropLoader
            lockBackdropClose={true}
            backdropOpen={true}

            text={msgLoad[loadState]}
        />
    )

}
