import React, {useContext, useEffect, useState} from 'react';

import List from "@mui/material/List";
import {useLocation} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import {CardCollapse} from "../../Components/Card/Collapse/CardCollapse";
import {WebSocketContext} from "../../Context/WebSocketContext";

export const ReadTestTrace = (props) => {
    const { state } = useLocation();
    const [init, setInit] = useState(false);
    const [testActions, setTestActions] = useState([]);



    const _webSocketContext = useContext(WebSocketContext)

    useEffect(() => {
        if(!init){
            let ids = []

            state.steps.forEach((info) => {
                console.log(info)
               if(info.internalCaseId !== 'END_TEST' &&  ids.indexOf(info.internalCaseId) === -1) {
                   ids.push(info.internalCaseId)
               }

            })

            const listActionTestCase = {
                path: 'action/list/test_case',
                info: {
                    list:ids
                }
            }

            const listActionTestCaseId = _webSocketContext.send(listActionTestCase, (message) => {
                setInit(true)
                setTestActions(message.info.test_cases)
            })
            //_webSocketContext.unsubscribe(listActionTestCaseId)

        }
    }, []);

    return (
        <List sx={{ width: '100%'}}>
            {testActions.map((element)=>(
            <ListItem>
                    <CardCollapse
                        info={element}
                    />
            </ListItem>
            ))}

        </List>
    );
};

