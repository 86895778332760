import {createContext, useContext, useEffect, useState} from 'react';
import {WebSocketContext} from "../WebSocketContext";
import tools from "../../Utils/tools";

const ProjectContext = createContext(null)

function ProjectProvider({children}) {

    const _webSocketContext = useContext(WebSocketContext)

    const [listProject, setListProject] = useState([]);

    const initialize = ()=>{
        const getAllProjects = {
            path: 'project/list/all_info',
        }
        _webSocketContext.send(getAllProjects, (message)=>{
            setListProject(message.info.projects)
        })

    }

    useEffect(()=>{
        _webSocketContext.subscribe('hook/project/list/update', (message)=>{
            updateList(message)
        })
    },[listProject])

    const updateList = (message)=>{

        let list = [
            ...listProject,
        ]
        const index = tools.getFirstIndexElement(listProject, (project,i)=>{
            return project._id === message.info._id
        })

        if(index >= 0){
            list[index] = message.info
        }
        else{
            list.push(message.info)
        }
        setListProject(list)
    }


    const getProjectExecutedById = (idProject) =>{
        return tools.getFirstElement(listProject, (project,i)=>{
            return project._id === idProject
        })
    }

    return (
        <ProjectContext.Provider value={{initialize, listProject, getProjectExecutedById}}>
            {children}
        </ProjectContext.Provider>
    );
}

export {ProjectContext, ProjectProvider};
