import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import {DialogActions, DialogContent, DialogTitle, Grid2, LinearProgress, setRef} from "@mui/material";
import {WebSocketContext} from "../../../Context/WebSocketContext";
import {useContext, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import tools from "../../../Utils/tools";
import StepVideo from "../VideoPlayer/StepVideo";
import {Timeline, timelineOppositeContentClasses} from "@mui/lab";

export default function VideoDialog(props) {

    const [progressLoading, setProgressLoading] = useState(0)
    const [playerIsOpen, setPlayerIsOpen] = useState(true)
    const [playerText, setPlayerText] = useState('')
    const [currentStep, setCurrentStep] = useState(-1)
    const playerRef = useRef()

    // const videoStepReverse = props.projectInfo.videos[props.stepId].actions
    const videoStep = [...props.projectInfo.videos[props.stepId].actions]
    const videoStepReverse = [...videoStep].reverse()
// console.log(videoStep, props.projectInfo.videos[props.stepId].actions)

    const handleClose = () => {
        props.setIsOpen('');
    };

    const setTime= (time)=>{
        if(playerIsOpen && playerRef?.current){
            playerRef.current.seekTo(time, 'seconds')
        }
    }


    let display = <Box sx={{width: '100%'}}>
        {playerText}
        <LinearProgress variant="determinate" value={progressLoading}/>
    </Box>
    if (playerIsOpen) {

        display = <ReactPlayer
            ref={playerRef}
            playing
            url={`http://75.119.139.248:8080/api/${props.projectInfo.id}/getVideo/${props.stepId}`}
            width={'100%'}
            height={'100%'}
            controls={true}
            style={{
                border: "1px solid grey",
                margin: '0 auto'
            }}
            se

            onProgress={(e) => {
                const index = tools.getFirstIndexElement(videoStepReverse, (value, i) => {

                    console.log(i, e.playedSeconds, (value.timeStamp / 1000))
                    return e.playedSeconds > (value.timeStamp / 1000)
                })

                if (index != currentStep) {
                    setCurrentStep(index)
                }

                const lastIndex = videoStepReverse.length-1
                const lastElement = videoStepReverse[lastIndex]

                // console.log(e.playedSeconds > (lastElement.timeStamp/ 1000) , currentStep != lastIndex)
                // if(e.playedSeconds >  (lastElement.timeStamp/ 1000)  && currentStep != lastIndex){
                //     setCurrentStep(lastIndex)
                // }
            }}
            progressInterval={20}
        />
    }

    const blockText = () => {


        return <Timeline style={{width:"100%" }}
                         sx={{
                             [`& .${timelineOppositeContentClasses.root}`]: {
                                 flex: 0.2,
                             },
                         }}>
                {
                    videoStep.map((v, i) => (
                        <div>
                            <StepVideo currentStep={currentStep} id={ videoStep.length-(1+i)} time={v.timeStamp} txt={v.lang.fr} timeTo={setTime}></StepVideo>
                        </div>
                    ))
                }
            </Timeline>
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={props.isOpen}
            onClose={handleClose}
        >
            <DialogTitle>Restitution</DialogTitle>

            <DialogContent
                style={{
                    textAlign: "center",
                    width: '100%',
                }}>
                <Grid2
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-around"

                    style={{
                        width: '100%',
                    }}
                    // style={{ minHeight: '100vh' }}
                >
                    <Grid2 size={6}>
                        {display}
                    </Grid2>
                    <Grid2 size={5}>
                        {blockText()}
                    </Grid2>
                </Grid2>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}