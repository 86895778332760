import * as React from 'react';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import {useEffect, useState} from "react";

export default function TransfertCheckbox({element, disabled, handleToggleCheck, label, resetCheckbox}) {
    const [checked, setChecked] = useState(false);

    const handleToggle = (value) => () => {
        let isChecked = checked;
        if(!disabled || isChecked) {

            isChecked = !isChecked
            setChecked(isChecked)
            handleToggleCheck(value, isChecked)
        }
    };

    useEffect(() => {
        setChecked(false)
    }, [resetCheckbox]);

    return (
        <ListItemButton
            key={element.code}
            role="listitem"
            onClick={handleToggle(element.code)}
        >
            <ListItemIcon>
                <Checkbox
                    checked={
                        checked
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                        'aria-labelledby': label,
                    }}
                    disabled={
                        disabled && !checked
                    }
                />
            </ListItemIcon>
            <Grid container direction="row" justifyContent={'center'}
                  alignItems={'center'}>
                <Grid item xs={2}>
                    <img
                        src={'data:image/png;base64, ' + element.image_binary}
                        height={'25px'}
                    />
                </Grid>
                <Grid item xs={10}>
                    {element.name}
                </Grid>
            </Grid>
        </ListItemButton>
    );
}