import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {Card, Paper} from "@mui/material";
import ReactMarkdown from "react-markdown";

export default function ChangeLog() {

    const [changeLog, setChangeLog] = useState('')

    useEffect(() => {
        if (changeLog === '') {
            fetch('http://75.119.139.248:8080/api/changelog').then((result) => {
                result.text().then((txt) => {
                    setChangeLog(txt)
                })
            })
        }
    }, []);


    return (
        <Card sx={{textAlign: 'left', padding: '2%', width: '100%', minHeight: '100%'}}>

            <ReactMarkdown

            >
                {changeLog}
            </ReactMarkdown>
        </Card>
    )
}