import {CssBaseline, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {DashboardMain} from "../Pages/dashbord";
import {ListTestCase} from "../Pages/listTestCase";
import DevelopmentPanel from "../Pages/developmentPanel";
import {Projects} from "../Pages/projects";
import {UpdateProject} from "../Pages/Project/UpdateProject/UpdateProject";
import DevelopmentActionList from "../Pages/Development/DevelopmentActionList";
import DevelopementActionUpdate from "../Pages/Development/developementActionUpdate";
import {CategoryPanel} from "../Pages/Category/categoryPanel";
import {CategoryActionList} from "../Pages/Category/CategoryActionList";
import AdminPanel from "../Pages/AdminPanel";
import {LanguageEditor} from "../Pages/Admin/LanguageEditor";
import * as React from "react";
import SidebarComputer from "./sidebar/Computer/SidebarComputer";
import SidebarMobile from "./sidebar/Mobile/SidebarMobile";

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {ReadTestTrace} from "../Pages/Project/ReadTestTrace";
import {UpdateDefaultLang} from "../Pages/Admin/UpdateDefaultLang";
import {UpdateTestCase} from "../Pages/updateTestCase";
import {UpdateTemplate} from "../Pages/Project/UpdateTemplate";
import ChangeLog from "../Pages/ChangeLog";


const sidebarWidthPx = 275;
const sidebarClosedWidthPx = 65;

export function RouterApp(props) {

    const isComputer = useMediaQuery('(min-width:900px)');
    const hexPrimaryColor = '#FF7233';
    const rgbPrimaryColor = '255,114,51';
    const [sidebarIsOpen, setSidebarIsOpen] = React.useState(isComputer);
    const [currentProject, setCurrentProject] = React.useState(null);
    const [currentTestCase, setCurrentTestCase] = React.useState(null);
    const [currentAction, setCurrentAction] = React.useState(null);
    const [token, setToken] = React.useState('');


    let sidebar;

    if (isComputer) {
        sidebar = <SidebarComputer
            isOpen={sidebarIsOpen}
            setIsOpen={setSidebarIsOpen}
            width={sidebarWidthPx}
            widthClosed={sidebarClosedWidthPx}
            isComputer={isComputer}
        >
        </SidebarComputer>
    } else {
        sidebar = <SidebarMobile
            isOpen={sidebarIsOpen}
            setIsOpen={setSidebarIsOpen}
            width={sidebarWidthPx}
            widthClosed={sidebarClosedWidthPx}
            isComputer={isComputer}
        >
        </SidebarMobile>
    }

    const getContainerClass = ()=>{
        let classes = []
        if(!isComputer){
            classes.push("mobile")
        }
        else{
            const animation = sidebarIsOpen ? 'activated' : 'disactivated'
            classes.push(animation)
        }

        return classes.join(' ')
    }


    return (
        <Router>

            {/*<Menu*/}
            {/*    isOpen={sidebarIsOpen}*/}
            {/*    setIsOpen={setSidebarIsOpen}*/}
            {/*    width={sidebarWidthPx}*/}
            {/*    isComputer={isComputer}*/}
            {/*>*/}
            {/*</Menu>*/}
            {sidebar}
            <CssBaseline/>
            <Box id='Md-Container' overflow={'hidden'} className={getContainerClass()}>
                <Routes>
                    <Route exact path="/" element={<DashboardMain/>}/>
                    <Route exact path="/update_testcase" element={<UpdateTestCase
                        isOpen={sidebarIsOpen}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                        setCurrentTestCase={setCurrentTestCase}
                        currentTestCase={currentTestCase}
                    />}/>

                    <Route exact path="/list_test_case" element={<ListTestCase
                        isOpen={sidebarIsOpen}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                        setCurrentTestCase={setCurrentTestCase}
                    />}/>

                    <Route exact path="/develop_panel" element={<DevelopmentPanel
                        isOpen={sidebarIsOpen}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/projects" element={<Projects isOpen={sidebarIsOpen}
                                                                     setCurrentProject={setCurrentProject}/>}/>

                    <Route exact path="/update_project" element={<UpdateProject
                        isOpen={sidebarIsOpen}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                        currentProject={currentProject}
                    />}/>

                    <Route exact path="/action_list" element={<DevelopmentActionList
                        isOpen={sidebarIsOpen}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                        setCurrentAction={setCurrentAction}
                    />}/>

                    <Route exact path="/update_action" element={<DevelopementActionUpdate
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/category_panel" element={<CategoryPanel
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/category_list" element={<CategoryActionList
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/admin_panel" element={<AdminPanel
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/update_language" element={<LanguageEditor
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/update_default_language" element={<UpdateDefaultLang
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    <Route exact path="/read_step_trace" element={<ReadTestTrace
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>
                    <Route exact path="/update_template" element={<UpdateTemplate
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>
                    <Route exact path="/changelog" element={<ChangeLog
                        isOpen={sidebarIsOpen}
                        currentAction={currentAction}
                        setCurrentAction={setCurrentAction}
                        isComputer={isComputer}
                        widthSidebar={sidebarWidthPx}
                        widthClosedSidebar={sidebarClosedWidthPx}
                    />}/>

                    {/*<Route exact path="/error500" element={<Error500*/}
                    {/*/>}/>*/}
                </Routes>
            </Box>

        </Router>
    )
}
