import React, {useCallback, useEffect, useState} from 'react';
import {getConnectedEdges, useReactFlow, Node, addEdge, useEdges, useNodes} from '@xyflow/react';
import './style.css';
import {Card} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import tools from '../../Utils/tools'
import {useNavigate} from "react-router-dom";

export default function ContextMenuPane({
                                            id,
                                            top,
                                            left,
                                            right,
                                            bottom,
                                            onLayout,
                                            ...props
                                        }) {

    const navigate = useNavigate();
    const {getNode, setNodes, addNodes, setEdges, getEdges, addEdges} = useReactFlow();
    const [changesLayout, setChangeLayout] = useState(0)

    const edges = useEdges();

    const duplicateNode = useCallback(() => {
        const node = getNode(id);
        if (id !== 'start') {
            const position = {
                x: node.position.x + 50,
                y: node.position.y + 50,
            };

            addNodes({...node, id: `${Date.now()}`, position});
        }
    }, [id, getNode, addNodes]);

    const dupliquerCheminement = useCallback(() => {
        const node = getNode(id);
        if (id !== 'start') {
            const nouveauCheminement = {
                noeuds: [],
                liens: [],
            };

            let currentNodeId = id;
            let i = 0;


            while (currentNodeId !== null && i < edges.length) {
                let j = 0;

                while (edges[j].target !== currentNodeId && j < edges.length - 1) {
                    j++
                }

                if (edges[j].target === currentNodeId) {
                    const nodeInfo = getNode(currentNodeId)
                    const newEdgeId = Date.now() + '' + Math.random()

                    //create edge
                    if (nouveauCheminement.noeuds.length !== 0) {
                        let lastEntry = nouveauCheminement.noeuds.slice(-1);

                        nouveauCheminement.liens.push(
                            {
                                type: 'step',
                                source: newEdgeId,
                                target: lastEntry[0].id,
                                style: {
                                    color: "#b2b2b2",
                                    width: "40px"
                                },
                                markerEnd: {
                                    type: "arrow",
                                    color: "#b2b2b2",
                                    width: "30px",
                                    height: "30px"
                                },
                            }
                        )
                    }

                    //createNode
                    const position = {
                        x: 0,
                        y: 0,
                    };
                    nouveauCheminement.noeuds.push(
                        {
                            id: newEdgeId,
                            type: 'custom',
                            data: nodeInfo.data,
                            position: position
                        }
                    )
                    currentNodeId = edges[j].source
                }
                i++;

            }

            if (currentNodeId === 'start') {
                let lastEntry = nouveauCheminement.noeuds.slice(-1);
                nouveauCheminement.liens.push(
                    {
                        type: 'step',
                        source: currentNodeId,
                        target: lastEntry[0].id,
                        style: {
                            color: "#b2b2b2",
                            width: "40px"
                        },
                        markerEnd: {
                            type: "arrow",
                            color: "#b2b2b2",
                            width: "30px",
                            height: "30px"
                        },
                    }
                )
                addNodes(nouveauCheminement.noeuds);
                addEdges(nouveauCheminement.liens);
            }
            // onLayout('TB')

        }

    }, [id, getNode, addNodes]);

    const isConnectedWithStart = () => {
        const node = getNode(id);

        if (id !== 'start') {
            let allEdges = getEdges()
            let currentNodeId = id;
            let i = 0;


            while (currentNodeId !== null && i < allEdges.length) {
                let j = 0;

                while (allEdges[j].target !== currentNodeId && j < allEdges.length - 1) {
                    j++
                }

                if (allEdges[j].target === currentNodeId) {
                    currentNodeId = allEdges[j].source
                }
                i++;
            }

            return currentNodeId === 'start'
        }
        return false;

    };

    const readAllStepTrace = () => {
        const node = getNode(id);
        const nodesInfos = []
        if (id !== 'start') {


            let currentNodeId = id;
            let i = 0;


            while (currentNodeId !== null && i < edges.length) {
                let j = 0;

                while (edges[j].target !== currentNodeId && j < edges.length - 1) {
                    j++
                }

                if (edges[j].target === currentNodeId) {
                    const nodeInfo = getNode(currentNodeId)
                    nodesInfos.push(nodeInfo.data)
                    currentNodeId = edges[j].source
                }
                i++;
            }

        }
        nodesInfos.reverse()
        navigate("/read_step_trace", {state: {steps: nodesInfos}});
    }


    const deleteNode = useCallback(() => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        setEdges((edges) => edges.filter((edge) => edge.source !== id));
    }, [id, setNodes, setEdges]);

    const btnExecutions = () => {
        let elems = <><Button onClick={() => {
            props.setOpenDialogExecuteAll(true)
        }}>Tout exécuter</Button></>
        const haveChildLink = edges.filter((edge) => edge.source === id)
        if (id !== 'start' && isConnectedWithStart() && haveChildLink.length === 0) {
            elems = <>
                <Button onClick={duplicateNode}>Execute ce cheminement</Button>
                <Button onClick={readAllStepTrace}>Voir les étapes du cheminement</Button>
            </>
        }
        return elems
    }
    const btnCopy = () => {
        let elems = <></>
        if (id !== 'start') {
            elems = <>
                <Divider sx={{margin: '2px'}}/>
                <Button onClick={duplicateNode}>Dupliquer le noeud</Button>
            </>
            if (isConnectedWithStart()) {
                elems = <>
                    <Divider sx={{margin: '2px'}}/>
                    <Button onClick={duplicateNode}>Dupliquer le noeud</Button>
                    <Button onClick={dupliquerCheminement}>Dupliquer le cheminement</Button>
                </>
            }
        }
        return elems
    }
    const btnDelete = () => {

        const connectedEdges = edges.filter(edge => edge.source === id || edge.target === id);

        let elems = <></>
        if (id !== 'start') {
            elems = <>
                <Divider sx={{margin: '2px'}}/>
                <Button onClick={deleteNode}>Supprimer le noeud</Button>
            </>
            if (connectedEdges.length > 0) {
                elems = <>
                    <Divider sx={{margin: '2px'}}/>
                    <Button onClick={deleteNode}>Supprimer le noeud</Button>
                    <Button onClick={deleteNode}>Supprimer tout le cheminement</Button>
                </>
            }
        }
        return elems
    }


    return (
        <>
            {btnExecutions()}
            {btnCopy()}
            {btnDelete()}
        </>
    )
        ;
}
