import * as React from 'react';
import Card from "@mui/material/Card";
import {CardActionArea, Tooltip} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from "@mui/material/Box";

export default function CardDevelopmentUtils(props) {



    const handleClick = ()=>{
        props.onClickAct(props.code)
    }
    return (

        <Card sx={{width:'100%', marginTop: 3}} onClick={handleClick}>
            <CardContent>
                <Box sx={{
                    display:'flex',
                    flexDirection:' row',
                    justifyContent:'space-between'
                }}>
                    <Typography variant="body1" color="text.primary">
                        {props.text}
                    </Typography>

                    <Tooltip title={props.info}  placement="bottom-end">
                        <HelpOutlineIcon></HelpOutlineIcon>
                    </Tooltip>

                </Box>
            </CardContent>
        </Card>
    )
}