import * as React from "react";
import {LanguageEditor} from "../../../../../Pages/Admin/LanguageEditor";
import {useContext, useEffect} from "react";
import {LanguageContext} from "../../../../../Context/LanguageContext";

export function StepOne(props) {


    const _langContext = useContext(LanguageContext)

    useEffect(() => {
        props.setDisableButtonNext(_langContext.availableLangList.length <= 0)
    }, [_langContext.availableLangList]);
    return (
            <LanguageEditor />
    )

}
