import * as React from "react";
import {createRef, useContext, useEffect, useState} from "react";
import {ColorPicker, useColor} from "react-color-palette";
import {
    Card, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Icon, InputAdornment, InputLabel, OutlinedInput,
    Paper,
    Tab,
    TextField
} from "@mui/material";
import CardDeveloper from "../../Components/Card/developer/CardDeveloper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import {InputSearch} from "../../Components/InputSearch";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import "react-color-palette/css";
import {Image, Label} from "@mui/icons-material";
import {TranslationContext} from "../../Context/TranslationContext";

let init = false;

export function CategoryActionList(props) {
    const {translation} = useContext(TranslationContext);

    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'Common.json', variables)
    }

    const [searchValue, setSearchValue] = useState([])
    const handleSearch = () => {

    }

    const [actionsFiltred, setActionsFiltred] = useState([])
    const [actions, setActions] = useState([])
    const [actionSelected, setActionSelected] = useState(null)
    const [openUpdateAction, setOpenUpdateAction] = useState(false)

    const handleCloseUpdateActionDialog = () => {
        setOpenUpdateAction(false)
    }

    const dialogTitle = () => {
        return actionSelected === null ? 'Création de la nouvelle categorie.' : 'Modification de la catégorie.';
    }

    const displayCardAcction = () => {

        let cardSteps = <List
            sx={{width: '100%', bgcolor: 'background.paper'}}
            component="nav"
        >
            {
                actionsFiltred.map((value, index) => (
                    <ListItem>
                        <Card sx={{
                            width: '100%',
                            height: '75px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '18px',
                            padding: '2%'
                        }}>
                            <Box>
                                {value.lang.FR}
                            </Box>
                            <Box sx={{marginLeft: 'auto'}}>
                                <button onClick={() => {
                                    //todo : Ouvrir dialog
                                }}>{t('common.update')}
                                </button>
                                <button
                                    onClick={() => {
                                        // deleteOneTestCase(value._id)
                                    }}
                                >{t('common.delete')}
                                </button>
                            </Box>
                        </Card>
                    </ListItem>
                ))
            }

        </List>

        if (actions.length === 0 && init) {
            cardSteps = <p>
                {t('common.test_case.no')}
            </p>
        }

        return cardSteps
    }
    const [color, setColor] = useColor("#561ecb");

    const addCateg = ()=>{
        setOpenUpdateAction(true)
    }

    return (
        <>
            <Box sx={{width: '100%'}}>

                        <InputSearch handleSearch={handleSearch} searchValue={searchValue}  btnAdd={true} addElement={addCateg}/>
                {displayCardAcction()}

            </Box>


            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openUpdateAction}
                onClose={handleCloseUpdateActionDialog}
            >
                <DialogTitle>
                    {dialogTitle()}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid xs={8} item>
                            <h3>Langues</h3>
                            <List>
                                <ListItem>
                                    <TextField
                                        label="FR"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <img
                                                    src="https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_France_%281794%E2%80%931815%2C_1830%E2%80%931974%29.svg"
                                                    height={'25px'}
                                                />
                                            </InputAdornment>,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid xs={4} item>
                            <h3>Choix de la couleur</h3>
                            <ColorPicker color={color} onChange={setColor} hideAlpha={true}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateActionDialog}>Close</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
