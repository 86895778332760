import * as React from "react";
import {useContext, useEffect, useState} from "react";

import Box from "@mui/material/Box";
import "react-color-palette/css";
import {WebSocketContext} from "../../Context/WebSocketContext";
import BasicTransferListLang from "../../Components/BasicTransfertList/BasicTransferListLang";
import Typography from "@mui/material/Typography";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Switch} from "@mui/material";
import tools from "../../Utils/tools";
import {element} from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../Context/LanguageContext";
import {MosaicList} from "../../Components/Mosaic/MosaicList";
import {TranslationContext} from "../../Context/TranslationContext";


export function LanguageEditor() {
    const {translation} = useContext(TranslationContext)

    const _langContext = useContext(LanguageContext)
    const allLangList = _langContext.allLangList

    const maxLang = 5
    const [availableLang, setAvailableLang] = useState(maxLang)
    const [openWarnDialog, setOpenWarnDialog] = useState(false)

    const [langs, setLangs] = useState(allLangList)

    const [isCountryLang, setIsCountryLang] = useState(false)
console.log(allLangList, _langContext.availableLangList)
    const [leftList, setLeftList] = useState(_langContext.not(allLangList, _langContext.availableLangList))
    const [rightList, setRightList] = useState(_langContext.intersection(allLangList, _langContext.availableLangList))

    useEffect(() => {
        if (allLangList.length > 0) {
            let availableLangForContext = []
            rightList.forEach((val, i) => {
                availableLangForContext.push(val.code)
            })
            _langContext.setAvailableLangList(availableLangForContext)
        }
    }, [rightList])


    const closeWarnDialog = () => {
        setOpenWarnDialog(false)
    }
    const toggleLangOrCountry = () => {
        if (rightList.length > 0 && !openWarnDialog) {
            setOpenWarnDialog(true)
        }

        if (openWarnDialog || rightList.length === 0) {
            setOpenWarnDialog(false)
            setIsCountryLang(!isCountryLang)

            setLeftList(getLeftList())
            setRightList(getRightList())
        }
    }



    const getLeftList = () => {
        let left = _langContext.not(allLangList, _langContext.availableLangList)

        if (!tools.isEmpty(left)) {
            if (!isCountryLang) {
                left = left.filter((element, i) => {
                    return !element.is_global_zone
                })
            } else {
                left = left.filter((element, i) => {
                    return (element.is_global_zone || tools.isEmpty(element.parent_zone))
                })
            }
        }


        return left;
    }

    const getRightList = () => {
        return _langContext.intersection(allLangList, _langContext.availableLangList);
    }

    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'AdminPanel.json', variables)
    }

    return (
        <>
            <Box sx={{width: '100%'}}>
                <Grid sx={{
                    display: 'flex',
                    width: '100%'
                }}
                      alignItems={'flex-start'}
                      justifyContent={'flex-start'}
                      direction="column"
                      container
                      spacing={2}
                >
                    <Grid item xs>

                        <Stack direction="column">
                            <Typography variant={"h6"}>
                                {t('admin.panel.languages.manage')}
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>
                                    {t('admin.panel.languages.manage.language')}
                                </Typography>
                                <Switch onClick={toggleLangOrCountry} checked={isCountryLang}/>
                                <Typography>
                                    {t('admin.panel.languages.manage.country')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs>
                        <Typography variant={"h6"}>
                            {t('admin.panel.languages.total_available', [availableLang,maxLang])}
                        </Typography>
                    </Grid>
                    <Grid item style={{
                        width: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <BasicTransferListLang
                                right={rightList}
                                setRight={setRightList}
                                left={leftList}
                                setLeft={setLeftList}
                                maxLang={maxLang}
                                setAvailableLang={setAvailableLang}
                                availableLang={availableLang}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openWarnDialog}
                onClose={closeWarnDialog}
            >
                <DialogTitle>Attention</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La modification du choix de langue rique d'entrainer une perte de données sur les traductions de
                        vos actions, cas de test et projects.
                        <br/>
                        Souhaitez-vous continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleLangOrCountry}>Continuer</Button>
                </DialogActions>
                <DialogActions>
                    <Button onClick={closeWarnDialog}>Annuler</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
