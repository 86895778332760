import * as React from 'react';
import FlvJs from 'flv.js';
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";
import ReactPlayer from "react-player";

export default function VideoPlayer(props) {



    let display = <Box sx={{width: '100%'}}>
        {props.text}
        <LinearProgress variant="determinate" value={props.progress}/>
    </Box>
    if (props.open) {

        display = <ReactPlayer
            playing
            url={props.url}
            width={'50vw'}
            height={'100%'}
            controls={true}
            style={{
                border: "1px solid grey",
                margin: '0 auto'
            }}
        />

    }


    return (
        <>
            {display}
        </>

    )
}