import './App.css';
import * as React from "react";
import {WebSocketProvider} from "./Context/WebSocketContext";
import {LanguageProvider} from "./Context/LanguageContext";
import {RouterApp} from "./Components/RouterApp";
import {LoadingInitAllInfo} from "./Pages/Loading/LoadingInitAllInfo";
import {useState} from "react";
import {DialogTutorial} from "./Components/Dialog/DialogTutorial/DialogTutorial";
import {LicenceProvider} from "./Context/LicenceContext";
import Box from "@mui/material/Box";
import {TranslationProvider} from "./Context/TranslationContext";
import {UserProvider} from "./Context/UserContext";
import {ProjectProvider} from "./Context/Hook/ProjectContext";
import {TestcaseProvider} from "./Context/Hook/TestCaseContext";
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";

function App() {


    const [initializeState, setInitializeState] = useState(0)
    const [updateDialogOpen, setUpdateDialogOpen] = React.useState(true);

    function loadInitialInfos() {
        switch (initializeState) {
            case 0:
                return <LoadingInitAllInfo
                    setLoadState={setInitializeState}
                />
            case 1:
                return <Box id={'backColor'} width={'100vw'} height={'100vh'}
                ><DialogTutorial
                    dialogOpen={updateDialogOpen}
                    setDialogOpen={setUpdateDialogOpen}
                    setLoadState={setInitializeState}
                />
                </Box>
            case 2:
                return <RouterApp/>
        }
    }

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const darkTheme = createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
            background: {
                default: prefersDarkMode ? '#252525' : '#f5f5f5',
            },
            common: {
                white: {
                    dark: '#cecece'
                },
            },
            greyTimeline: {
                main: '#121212',
            },
            appBackground: { //not found
                main: '#f5f5f5',
                light: '#f5f5f5',
                dark: '#252525'
            },
            sidebar: {
                dark: '#cecece',
                light: '#616161',
                main: '#616161'
            },


        },

    });


    return (
        <div className="App">
            <ThemeProvider theme={darkTheme}>
                <Box sx={{background: 'appBackground', minHeight: "100vh"}}>
                    <WebSocketProvider>
                        <LanguageProvider>
                            <LicenceProvider>
                                <UserProvider>
                                    <TestcaseProvider>
                                        <ProjectProvider>
                                            <TranslationProvider>
                                                {loadInitialInfos()}
                                            </TranslationProvider>
                                        </ProjectProvider>
                                    </TestcaseProvider>
                                </UserProvider>
                            </LicenceProvider>
                        </LanguageProvider>
                    </WebSocketProvider>
                </Box>
            </ThemeProvider>
        </div>
    )
}

export default App;