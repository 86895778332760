import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {useState} from "react";
import Button from "@mui/material/Button";
import {WelcomeInit} from "./Componants/FirstConnection/WelcomeInit";
import {StepMenu} from "./Componants/FirstConnection/StepMenu";

export function DialogTutorial(props) {

    const handleDialogClose = () => {
        props.setDialogOpen(false)
    }

    const [dialogContentId, setDialogContentId] = useState(0)

    const nextDialogInfo = () => {
        setDialogContentId(dialogContentId + 1)
    }


    const getDialogContent = () => {
        switch (dialogContentId) {
            case 0:
                return <WelcomeInit
                        nextDialogInfo={nextDialogInfo}
                    />
            case 1:
                return <StepMenu
                    nextDialogInfo={nextDialogInfo}
                    setLoadState={props.setLoadState}
                ></StepMenu>
        }
    }


    return (
        <Dialog
            open={props.dialogOpen}
            fullWidth={true}
            maxWidth={'xl'}
            hideBackdrop={true}
        >
            <DialogTitle>
                {/*Configuration initial*/}
            </DialogTitle>
                {getDialogContent()}

            {/*<DialogActions>*/}
            {/*    <Button disable={disableButtonNext} onClick={changeToNextStep}>Suivant</Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    )

}
