import * as React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import {Alert, Dialog, Input, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router";
import {useContext, useEffect, useState} from "react";
import tools from "../../../Utils/tools";
import {DialogLoader} from "../../Dialog/DialogLoader/DialogLoader";
import Snackbar from "@mui/material/Snackbar";
import {WebSocketContext} from "../../../Context/WebSocketContext";
import {LanguageContext} from "../../../Context/LanguageContext";




export function CardNewProject(props) {

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectNameIsEmpty, setNewProjectNameIsEmpty] = useState(false);
    const [websocketMsg, setWebSocketMsg] = useState('');
    const [countProjectNameIsEmpty, setCountProjectNameIsEmpty] = useState(0);
    const navigate = useNavigate();
    const _languageContext = useContext(LanguageContext)
    const _webSocketContext = useContext(WebSocketContext)

    const wsCreateProject = () =>{

        const createProject = {
            path:'project/create',
            info:{
                name:newProjectName,
                lang:_languageContext.lang.toLowerCase()
            }
        }

        const createProjectId = _webSocketContext.send(createProject, (message)=>{
            switch (message.code_execution) {
                case "PROJECT_CREATE_0001": //Initialisation translation key
                    setWebSocketMsg('Initialisation translation key')
                    break;
                case "PROJECT_CREATE_0002": //create project
                    setWebSocketMsg('Creation project')
                    break;
                case "PROJECT_CREATE_0003": //redirect
                    setWebSocketMsg('finish')
                    props.setCurrentProject(message.info.project_id)

                    navigate("/update_project")
                    //_webSocketContext.unsubscribe(createProjectId)
                    break;
            }
        })

    }

    const handleCloseProjectNameIsEmpty = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNewProjectNameIsEmpty(false);
    };

    const initProject =() =>{
        setCountProjectNameIsEmpty(countProjectNameIsEmpty+1)
        if(tools.isEmpty(newProjectName)){
            setNewProjectNameIsEmpty(true)
        }
        else {
            setOpenBackdrop(true)
            setWebSocketMsg('Envoie des données vers le serveur')
            wsCreateProject()
            // navigate("/update_project");
        }

    }

    const TextFieldInput = ()=>{
        let isValid = <TextField style={{width:'100%'}}  onInput={(e)=>{
            let txt = e.target.value;
            setNewProjectName(txt)
        }}
                   label="Nom du nouveau projet"
                   variant="standard"/>

        if(countProjectNameIsEmpty>0 && tools.isEmpty(newProjectName)){
            isValid = <TextField style={{width:'100%'}}  onInput={(e)=>{
                let txt = e.target.value;
                setNewProjectName(txt)
            }}
                                 error
                                 helperText="Le nom du projet est obligatoire."
                                 label="Nom du nouveau projet"
                                 variant="standard"/>
        }
        return isValid
    }

    return (
        <>
            <Card sx={{ width: 345 }}>
                <Box height={140} width={'100%'}
                     style={{
                         background:'grey',
                         alignItems:'center'
                     }}
                     display={'flex'}
                     justifyContent={'center'}
                >
                    <AddIcon sx={{
                        color: "white",
                        fontSize:'100px'
                    }}  />
                </Box>
                <CardContent
                    style={{
                        width:'100%',
                        textAlign: 'left'}}
                >
                    {/*<Input style={{width:'100%'}} type={'text'} onInput={(e)=>{*/}
                    {/*    let txt = e.target.value;*/}
                    {/*        setNewProjectName(txt)*/}
                    {/*}} />*/}

                    {
                        TextFieldInput()
                    }


                </CardContent>
                <CardActions>
                    <Button size="small" onClick={()=>{
                        initProject()
                    }}>
                        Ajouter</Button>
                    <Button size="small">Supprimer</Button>
                </CardActions>
                <DialogLoader setDialogLoader={setOpenBackdrop} dialogOpen={openBackdrop} text={websocketMsg}/>
            </Card>
            <Snackbar
                open={newProjectNameIsEmpty}
                onClose={handleCloseProjectNameIsEmpty}
                // TransitionComponent={}
                // key={state.Transition.name}
                autoHideDuration={5000}
            >

                <Alert
                    onClose={handleCloseProjectNameIsEmpty}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Le nom du project est obligatoire !
                </Alert>
            </Snackbar>
        </>

    )
}
