import * as React from 'react';
import {
    Alert,
    Card, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Input, InputAdornment, Paper, Stack,
    Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import tools from "../../Utils/tools";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import DevelopmentEditor from "./developmentEditor";
import BasicTabs from "../../Components/Tabs/BasicTabs";
import CardDevelopmentUtils from "../../Components/Card/developer/CardDevelopmentUtils";
import Snackbar from "@mui/material/Snackbar";
import {WebSocketContext} from "../../Context/WebSocketContext";
import {BackdropLoader} from "../../Components/BackdropLoader/BackdropLoader";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../Context/LanguageContext";
import Button from "@mui/material/Button";
import {UserContext} from "../../Context/UserContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function DevelopementActionUpdate(props) {

    const [init, setInit] = React.useState(false);

    const [actionDev, setActionDev] = React.useState('');
    const [varGlob, setVarGlob] = React.useState([]);


    const [helperTabState, setHelperTabState] = React.useState('devHelper');
    const [helperClickBtn, setHelperClickBtn] = React.useState(false);
    const [helperClickBtnInfo, setHelperClickBtnInfo] = React.useState('');
    const [actionNameIsEmpty, setActionNameIsEmpty] = React.useState(false);

    const [openDialogTranslationName, setOpenDialogTranslationName] = useState(false);
    const [openDialogAddVarGlob, setOpenDialogAddVarGlob] = useState(false);

    const [helperNameVarGlob, setHelperNameVarGlob] = useState('');
    const [newNameVarGlob, setNewNameVarGlob] = useState('');

    const [helperValueVarGlob, setHelperValueVarGlob] = useState('');
    const [newValueVarGlob, setNewValueVarGlob] = useState('');


    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [backdropText, setBackdropText] = useState('');

    const _languageContext = useContext(LanguageContext)
    const _webSocketContext = useContext(WebSocketContext)
    const _userContext = useContext(UserContext)

    const [toolTipsHelpAction, setToolTipsHelpAction] = useState(false)

    const addVarGlob = (name) => {
        let helper = '';

        if (tools.isEmpty(name)) {
            helper = 'le nom est vide'
        }
        if (tools.getFirstElement(varGlob, (info, i) => {
            return info.name === name
        }) !== null) {
            helper = 'La variable existe déjà'
        }
        setHelperNameVarGlob(helper)
        return helper
    }
    const addVarGlobValue = (value) => {
        let helper = '';

        if (tools.isEmpty(value)) {
            helper = 'La valeur est vide'
        }


        // eslint-disable-next-line no-control-regex
        const regExp = /^((?<o>{((?<s>\"([^\0-\x1F\"\\]|\\[\"\\\/bfnrt]|\\u[0-9a-fA-F]{4})*\"):(?<v>\g<s>|(?<n>-?(0|[1-9]\d*)(.\d+)?([eE][+-]?\d+)?)|\g<o>|\g<a>|true|false|null))?\s*((?<c>,\s*)\g<s>(?<d>:\s*)\g<v>)*})|(?<a>\[\g<v>?(\g<c>\g<v>)*\])|('.*')|(".*")|([0-9]+\.[0-9]+)|([0-9]+))|(null|true|false|undefined)$/
        if (regExp.exec(value) === null) {
            helper = 'La valeur n\'est pas valide'
        }

        setHelperValueVarGlob(helper)
        return helper
    }


    useEffect(() => {
        if (init) {
            const updateAction = {
                path: 'action/update',
                info: {
                    id: props.id,
                    updates: actionDev
                }
            }

            const updateId = _webSocketContext.send(updateAction, (message) => {
            })
            //_webSocketContext.unsubscribe(updateId)
        }
    }, [actionDev]);


    useEffect(() => {
        if (!init) {
            setOpenBackdrop(true)
            setBackdropText("Chargement des données de l'action")

            const getVarGlob = {
                path: 'variable_global/list'
            }

            const getVarGlobId = _webSocketContext.send(getVarGlob, (message) => {
                setVarGlob(message.info.variable_global)
            })

            const getAction = {
                path: 'action/get',
                info: {
                    id: props.currentAction
                }
            }

            const getActionId = _webSocketContext.send(getAction, (message) => {

                setActionDev(message.info.action)
                setInit(true)
                setOpenBackdrop(false)
            })
            //_webSocketContext.unsubscribe(getActonId)

        }
    }, []);

    const handleCloseDialogAddVarGlob = () => {
        setNewNameVarGlob('')
        setHelperNameVarGlob('')
        setOpenDialogAddVarGlob(false)
    }

    const handleCloseDialogLangNames = () => {
        setOpenDialogTranslationName(false)
    }
    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const readNameActionByLang = (lang) => {
        let name = '';
        if (Object.keys(actionDev).length !== 0 && actionDev.lang[lang] !== undefined) {
            const actionName = actionDev.lang[lang]
            name = actionName === '' ? '' : actionName
        }

        return name;
    }

    const languageInput = (info) => {

        return <Grid item xs={4}>

            <TextField
                value={
                    readNameActionByLang(info.code.toLowerCase())
                }
                onInput={(e) => {
                    setActionTitle(info.code.toLowerCase(), e.target.value)
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <img
                            src={'data:image/png;base64, ' + info.image_binary}
                            height={'25px'}
                        />
                    </InputAdornment>,
                }}
            />
        </Grid>
    }

    const helpClick = (txt) => {
        if (actionNameIsEmpty) {
            setActionNameIsEmpty(true)
            return
        }
        setHelperClickBtnInfo(txt);
        setHelperClickBtn(true);
    }

    const devPanel = () => {
        let panel = <></>

        if (!openBackdrop && init) {
            panel = <DevelopmentEditor
                helperClickBtn={helperClickBtn}
                helperClickBtnInfo={helperClickBtnInfo}
                setHelperClickBtn={setHelperClickBtn}
                id={props.currentAction}
                actionDev={actionDev}
                setActionDev={setActionDev}
                varGlob={varGlob}
            />
        }
        return panel
    }

    const setActionTitle = (lang, newName) => {

        let newInfo = {
            ...actionDev
        }
        newInfo.lang[lang.toLowerCase()] = newName
        setActionDev(newInfo)
    }

    const addVariableGlobal = () => {
        const helperName = addVarGlob(newNameVarGlob)
        const helperValue = addVarGlobValue(newValueVarGlob)

        if (helperName === '' &&
            helperValue === ''
        ) {


            let newVar = {name: newNameVarGlob, value: newValueVarGlob}

            const createVarGlob = {
                path: 'variable_global/create',
                info: newVar
            }

            const createVarGlobID = _webSocketContext.send(createVarGlob, (message) => {
                newVar['_id'] = message.info.id

                let newList= []
                if(varGlob.length > 0){
                    newList = [
                        ...varGlob
                    ]
                }
                newList.push(newVar)
                setVarGlob(newList)
            })
            handleCloseDialogAddVarGlob()
        }
    }

    const deleteVarGlob = (id)=>{

        const deleteVarGlob = {
            path: 'variable_global/delete',
            info: {
                _id:id
            }
        }


        const deleteVarGlobId = _webSocketContext.send(deleteVarGlob, (message) => {
            if(message.code_execution === 'VARIABLE_GLOBAL_DELETE_0001'){
                let index = tools.getFirstIndexElement(varGlob, (elem, i)=>{
                    return elem._id === id
                })
                if(index!== -1){
                    let newList = [...varGlob]
                    delete newList[index]
                    setVarGlob(newList)
                }
            }

        })
    }

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item md={8} sm={12} flexGrow={1}>
                        <Box>
                            <Card sx={{width: '100%', padding: 3, marginBottom: 3}}>
                                <Grid container justifyContent={"center"} spacing={2}
                                      alignItems="center">

                                    <Grid item xs={11}>
                                        <TextField value={readNameActionByLang(_userContext.userlang())}
                                                   onChange={(e) => {

                                                       setActionTitle(_userContext.userlang(), e.target.value)

                                                   }}
                                                   variant="standard"
                                                   label="Nom de l'action"
                                                   helperText=""

                                                   fullWidth={true}></TextField>
                                    </Grid>
                                    <Grid item alignItems={"center"} xs={1}

                                          onClick={() => {
                                              setOpenDialogTranslationName(true)
                                          }}


                                    >
                                        <Typography color={'blue'} style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline blue',
                                        }}>{_userContext.userlang().toUpperCase()}</Typography>
                                    </Grid>
                                </Grid>
                            </Card>


                            <Card id={'containerCode'}
                                  sx={{width: '100%', paddingTop: 3, paddingBottom: 3, textAlign: 'left'}}
                                  elevation={10}>

                                {devPanel()}

                            </Card>
                        </Box>
                    </Grid>
                    <Grid item md={4} sm={12} flexGrow={1}>
                        <Card sx={{width: '100%'}}>
                            <Tabs value={helperTabState} centered onChange={(e, newValue) => {
                                setHelperTabState(newValue)
                            }}>
                                <Tab label="Boite à outils" value={'devHelper'}/>
                                <Tab label="Variables globales" value={'varGlobal'}/>
                            </Tabs>
                            <Box>

                                <BasicTabs index={'devHelper'} value={helperTabState}>
                                    {/*<InputSearch handleSearch={handleSearch} searchValue={searchValue}/>*/}
                                    <Divider sx={{margin: 2}}/>
                                    <CardDevelopmentUtils onClickAct={helpClick} text={'Helper.clickById( {string} )'}
                                                          info={'Cliquer sur l\'élément en fonction de son id CSS.'}
                                                          code={'await Helper.clickById("");'}
                                    ></CardDevelopmentUtils>
                                    <CardDevelopmentUtils onClickAct={helpClick}
                                                          text={'Helper.clickByXpath( {string} )'}
                                                          info={'Cliquer sur l\'élément en fonction de son xpath.'}
                                                          code={'await Helper.clickByXpath("");'}
                                    ></CardDevelopmentUtils>
                                    <CardDevelopmentUtils onClickAct={helpClick}
                                                          text={'Helper.dragAndDropByIdAndId( {string}, {string} )'}
                                                          info={'Déplacer un élément via son id vers la zone où le déposé.'}
                                                          code={'await Helper.dragAndDropByIdAndId("", "");'}
                                    ></CardDevelopmentUtils>
                                </BasicTabs>

                                <BasicTabs index={'varGlobal'} value={helperTabState}>
                                    {/*<InputSearch handleSearch={handleSearch} searchValue={searchValue}/>*/}
                                    <Button fullWidth={true} variant={'outlined'}
                                            onClick={() => {
                                                setOpenDialogAddVarGlob(true)
                                            }}
                                    >Ajouter</Button>
                                    <Divider sx={{margin: 2}}/>


                                    {varGlob.length > 0 && (

                                        <TableContainer>
                                            <Table sx={{width: '100%'}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontWeight: 'bold'}}
                                                                   align="left">Nom</TableCell>
                                                        <TableCell style={{fontWeight: 'bold'}} align="left">Valeur
                                                            initial</TableCell>
                                                        <TableCell style={{fontWeight: 'bold'}}
                                                                   align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {varGlob.map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                onClick={()=>{
                                                                helpClick('global_variable.'+row.name)
                                                            }}>
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={()=>{
                                                                helpClick('global_variable.'+row.name)
                                                            }}>{row.value}</TableCell>
                                                            <TableCell align="right" onClick={()=>{deleteVarGlob(row._id)}}><DeleteOutlineIcon/></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}

                                </BasicTabs>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>

                <Snackbar
                    open={(actionNameIsEmpty && init)}
                    onClose={() => setActionNameIsEmpty(false)}
                    // TransitionComponent={}
                    // key={state.Transition.name}
                    autoHideDuration={5000}
                >

                    <Alert
                        onClose={() => setActionNameIsEmpty(false)}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        Le nom de l'action est obligatoire !
                    </Alert>
                </Snackbar>
                <BackdropLoader backdropOpen={openBackdrop} setBackdropOpen={(e) => {
                }} text={backdropText}/>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    scroll={'paper'}
                    open={openDialogTranslationName}
                    onClose={handleCloseDialogLangNames}
                >
                    <DialogTitle>Le nom du projet par langue</DialogTitle>
                    <DialogContent dividers={true}>

                        <Grid container spacing={3}>
                            {
                                allAvailableLangWithInfo().map((languague, i) => (
                                    languageInput(languague)
                                ))
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogLangNames}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    scroll={'paper'}
                    open={openDialogAddVarGlob}
                    onClose={handleCloseDialogAddVarGlob}
                >
                    <DialogTitle>Ajouter une variable globale</DialogTitle>
                    <DialogContent dividers={true}>
                        <Stack
                            direction="column"
                            spacing={2}
                        >

                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography style={{color: 'blue'}}>
                                    var
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    variant={'standard'}
                                    placeholder={'Nom de la variable'}
                                    value={newNameVarGlob}
                                    error={helperNameVarGlob !== ''}
                                    onInput={(e) => {
                                        const name = e.target.value.trim()
                                        setNewNameVarGlob(name)
                                        addVarGlob(name)
                                    }}
                                    helperText={helperNameVarGlob}
                                />

                                <Typography>
                                    :
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    variant={'standard'}
                                    placeholder={'Valeur initial'}
                                    value={newValueVarGlob}
                                    error={helperValueVarGlob !== ''}
                                    onInput={(e) => {
                                        const value = e.target.value.trim()
                                        setNewValueVarGlob(value)
                                        addVarGlobValue(value)
                                    }}
                                    helperText={helperValueVarGlob}
                                />

                                <Typography>
                                    {';'}
                                </Typography>

                                <Tooltip placement={'left'}
                                         onClose={()=>{setToolTipsHelpAction(false)}}
                                         open={toolTipsHelpAction}
                                         title={
                                    <Stack
                                        direction="column"
                                        spacing={0}
                                    >
                                        <Typography>
                                            Type de valeur autorisées :
                                        </Typography>
                                        <List sx={{listStyleType: 'disc', margin: 0, padding: '0 0 0 25px'}}>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                String (avec guillemets ou apostrophes)
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                Entier
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                Décimal
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                Objet
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                Tableau
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                Boolean
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                null
                                            </ListItem>
                                            <ListItem sx={{display: 'list-item', margin: 0, padding: 0}}>
                                                undefined
                                            </ListItem>
                                        </List>
                                    </Stack>
                                }>
                                    <HelpOutlineIcon color={'info'} onTouchStart={()=>{
                                        setToolTipsHelpAction(true)
                                    }}
                                                     onMouseEnter={()=>{
                                                         setToolTipsHelpAction(true)
                                                     }}

                                    />
                                </Tooltip>

                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={addVariableGlobal}>Ajouter</Button>
                        <Button onClick={handleCloseDialogAddVarGlob}>Annuler</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }