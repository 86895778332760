import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {Card, Grid, Stack, TextField} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import {render} from "@testing-library/react";
import {TranslationContext} from "../Context/TranslationContext";
import Button from "@mui/material/Button";

export function InputSearch(props) {
    const {translation} = useContext(TranslationContext);


    const t = (translationKey, variables = []) => {
        return translation(translationKey, 'Common.json', variables)
    }

    return (
        <Card
            className={"Md-InputSearch"}
            sx={{
                background: 'paper',
                padding: "5px 10px 20px 10px",
                marginBottom:'25px',
                width:'100%'
        }}
      >
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={1}
            >
                <Grid item xs>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <SearchIcon/>
                        <TextField
                            sx={{
                                width: '100%'
                            }}
                            value={
                                props.searchValue
                            }

                            label={t('common.search')}
                            onInput={(e) => {
                                e.preventDefault();
                                let txt = e.target.value;
                                props.handleSearch(txt)
                            }}
                            variant="standard"
                        >
                        </TextField>
                    </Stack>
                </Grid>

                {
                    props.btnAdd &&(
                        <Grid item  minWidth={'90px'}>
                            <Button
                                className={"Md-Button Md-Button-Add"}
                                onClick={() => {
                                    props.addElement()
                                }}
                            >{t('common.add')}</Button>
                        </Grid>
                    )
                }
            </Grid>


        </Card>
    )
}
