import * as React from 'react';
import Box from '@mui/material/Box';
import {Card, CardContent, CardHeader, Stack, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

import {motion} from "framer-motion"
import {useContext, useEffect, useRef, useState} from "react";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";

const tools = require('../../../Utils/tools');

let lastDropzone = -1
export default function CardSentence(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const ref = useRef()

    const [newPosition, setNewPosition] = useState({x: 0, y: 0});
    const [initPos, setInitPos] = useState({x: 0, y: 0});
    const [init, setInit] = useState(false);


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (!init) {
            setInit(true)
            const infoPosition = ref.current.getBoundingClientRect()
            setInitPos({
                x: infoPosition.x + (infoPosition.width / 2),
                y: infoPosition.y + (infoPosition.height / 2)
            })

        }
    }, [])

    useEffect(() => {
        const infoPosition = ref.current.getBoundingClientRect()
        setInitPos({
            x: infoPosition.x + (infoPosition.width / 2),
            y: infoPosition.y + (infoPosition.height / 2)
        })

    }, [windowDimensions.width, windowDimensions.height])

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }


    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        let name = <Box>{current}</Box>

        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = _languageContext.intersection(_languageContext.allLangList, [_languageContext.lang])[0]
            let flag = <></>

            if (_userContext.userlang() !== _languageContext.lang) {
                flag = <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            name = <Stack direction={'row'} spacing={2}>
                {flag}

                <Box>{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }

    function mouseDown(e) {
        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('touchmove', touchMove)
        document.addEventListener('pointerup', pointerUp)
        document.addEventListener('touchend', pointerUp)
    }


    const movement = (newX, newY) => {

        const initX = initPos.x
        const initY = initPos.y


        const index = tools.getFirstIndexElement(props.dropzoneLocation, (positionsDrop, i) => {

            if (parseInt(i) === lastDropzone && lastDropzone !== -1) {
                return newX >= positionsDrop.xStart - 150 && newX <= positionsDrop.xEnd + 150
                    && newY >= positionsDrop.yStart - 50 && newY <= positionsDrop.yStart + 150

            } else {

                return newX >= positionsDrop.xStart - 150 && newX <= positionsDrop.xEnd + 150
                    && newY >= positionsDrop.yStart - 50 && newY <= positionsDrop.yEnd + 50
            }


        })


        if (parseInt(index) !== -1) {

            lastDropzone = parseInt(index)
            // setLastDropzone(parseInt(index))
            const element = props.dropzoneLocation[index].current

            if (element.classList.contains('DropZoneScenario')) {
                element.classList.add("hover");
                element.classList.add("animate");
            }
        } else {

            if (lastDropzone !== -1) {

                props.dropzoneLocation.forEach((element, i) => {
                    const current = element.current
                    current.classList.remove("hover");
                    current.classList.remove("animate");
                })
            }
            lastDropzone = -1
        }

        setNewPosition({
            x: (newX - initX),
            y: (newY - initY)
        })
    }

    function mouseMove(e) {
        let newX = e.clientX
        let newY = e.clientY

        movement(newX, newY)

    }
    const supportsPassive = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const wheelOpt = supportsPassive ? {passive: false} : false;

    function touchMove(e) {
        let newX = e.touches[0].clientX
        let newY = e.touches[0].clientY
        document.body.style.overflow = 'hidden';

        movement(newX, newY)

    }

    const removeListerner = () => {
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('touchmove', touchMove,)
        document.removeEventListener('pointerup', pointerUp)
        document.removeEventListener('touchend', pointerUp)
    }


    const pointerUp = (e) => {
        removeListerner()
        document.body.style.overflow = 'visible';
        if (lastDropzone !== -1) {

            const element = props.dropzoneLocation[lastDropzone].current
            if (element.classList.contains('DropZoneScenario')) {
                element.classList.remove("hover");
                element.classList.remove("animate");
            }

            props.pushToList(lastDropzone, props.info)
            lastDropzone = -1
        }
        setNewPosition({
            x: (0),
            y: (0)
        })
    }


    return (

        <Card
            style={{
                // position:'fixed',
                userSelect: 'none',
                cursor: "pointer",
                width: '100%',
                height: '120px',
                transform: `translate(${newPosition.x}px, ${newPosition.y}px)`,
                zIndex:9999
            }}
            elevation={4}
            onMouseDown={mouseDown} onTouchStart={mouseDown} ref={ref}
        >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}
                >

                    <Typography component="div" style={{fontSize: "10px"}}>
                        Id : {props.info._id}
                    </Typography>

                    <Typography component="div" style={{
                        fontSize: "9px",
                        border: '1px solid #FF7233',
                        borderRadius: '10%',
                        padding: '2px',
                        color: props.color
                    }}>

                        {props.info.categ}

                    </Typography>
                </Box>
                {/*<Box*/}
                {/*>*/}
                {/*    <Typography justifyContent={"flex-center"} style={{fontSize:"10px"}} color="text.secondary" component="div">*/}
                {/*        Titre : {props.title}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}

                <Box
                    sx={{
                        paddingTop: 2
                    }}
                >
                    <Typography justifyContent={"flex-center"} style={{fontSize: "18px"}} color="text.primary"
                                component="div">
                        {txtLang(props.info.lang)}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}