import * as React from 'react';
import {styled} from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Card,
    CardContent,
    Chip,
    Dialog, DialogActions,
    DialogTitle,
    FormControl, FormControlLabel,
    FormGroup, FormLabel,
    Grid, MenuItem, Radio, RadioGroup, Select, Slide,
    Stack, Switch,
    Tooltip
} from "@mui/material";
import Button from "@mui/material/Button";
import CalendarDialog from "../CalendarDialog";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {TranslationContext} from "../../../Context/TranslationContext";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {Person2Outlined} from "@mui/icons-material";
import {UserContext} from "../../../Context/UserContext";
import {LanguageContext} from "../../../Context/LanguageContext";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import { useTheme } from '@mui/material/styles';
import tools from "../../../Utils/tools";
import {LicenceContext} from "../../../Context/LicenceContext";
import {useNavigate} from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function SidebarContent(props) {
    const { mode, setMode } = useState();

    const[version, setVersion] = useState('0.0.0')
    const navigate = useNavigate();

    const theme = useTheme();
    const {translation} = useContext(TranslationContext)
    const _licenceContext = useContext(LicenceContext)
    const _userContext = useContext(UserContext)
    const _langContext = useContext(LanguageContext)

    const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
    const [openDialogInfo, setOpenDialogInfo] = React.useState(false);

    const [langs, setLangs] = useState([])


    useEffect(() => {
        setLangs(_langContext.intersection(_langContext.allLangList, _langContext.availableLangList))
    }, [_langContext.availableLangList])


    const linkNav = [
        {
            name: 'menu.dashboard',
            link: '/',
            icon: <AnalyticsOutlinedIcon sx={{color: "#FF7233"}}/>
        },
        {
            name: 'menu.admin_panel',
            link: '/admin_panel',
            icon: <AdminPanelSettingsOutlinedIcon sx={{color: "#FF7233"}}/>
        },
        {
            name: 'menu.dev_panel',
            link: '/develop_panel',
            icon: <DeveloperBoardOutlinedIcon sx={{color: "#FF7233"}}/>
            // icon: <DataObjectOutlinedIcon sx={{color: "#FF7233"}}/>
        },
        {
            name: 'menu.project',
            link: '/projects',
            icon: <AssignmentOutlinedIcon sx={{color: "#FF7233"}}/>
        },
        {
            name: 'menu.test_case',
            link: '/list_test_case',
            icon: <ListOutlinedIcon sx={{color: "#FF7233"}}/>
        },
        // {
        //     name: 'Ajouter un cas de test',
        //     link: '/update_testcase',
        //     icon: <PlaylistAddOutlinedIcon sx={{color: "#FF7233"}}/>
        // },
    ]


    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2),
        width: "100%",

        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    }));

    const toggleDrawer = (open) => () => {
        props.setIsOpen(open);
    };

    const t = (translationKey, variables) => {
        // console.log(translation(translationKey, 'Menu.js'))
        // return translationKey
        return translation(translationKey, 'Sidebar.json', variables)
    }
    const handleClickOpenDialogProfile = () => {
        setOpenDialogProfile(true);
    };

    const handleCloseDialogProfile = () => {
        setOpenDialogProfile(false);
    };
    const handleClickOpenDialogInfo = () => {
        setOpenDialogInfo(true);
    };

    const handleCloseDialogInfo = () => {
        setOpenDialogInfo(false);
    };

    console.log(theme.palette.mode)

    return (
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                // alignItems="center"
                height={'100%'}
                width={'100%'}
                spacing={0}
                sx={{
                    margin: 0,
                    padding: 0
                }}
            >

                <Box
                    sx={{
                        margin: 0,
                        padding: 0
                    }}
                >
                    <DrawerHeader>


                        {
                            props.isOpen && (
                                <>
                                    <h2 style={{color: "#FF7233"}}>{t('menu.title')}</h2>
                                    <IconButton
                                        onClick={toggleDrawer(false)}>
                                        <ChevronLeftIcon/>
                                    </IconButton>
                                </>
                            )
                        }

                        {
                            !props.isOpen && (
                                <IconButton
                                    onClick={toggleDrawer(true)}>
                                    <ChevronRightIcon/>
                                </IconButton>
                            )
                        }
                    </DrawerHeader>
                    <Divider style={{width: props.width}}/>
                    <List>
                        {linkNav.map((nav) => (
                            <ListItem disablePadding sx={{display: 'block'}} /*component={Link} to={nav.link}*/
                                      onClick={()=>{
                                          navigate(nav.link)
                                      }}
                            >
                                <Tooltip title={props.isOpen ? '' : t(nav.name)} placement="left">
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: props.isOpen ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: props.isOpen ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {nav.icon}
                                        </ListItemIcon>
                                        <Typography sx={{
                                            display: props.isOpen ? 'initial' : 'none',
                                        }}  color={`sidebar.${theme.palette.mode}`}>
                                            {t(nav.name)}
                                        </Typography>
                                        {/*<ListItemText*/}
                                        {/*    color={`sidebar.${theme.palette.mode}`}*/}
                                        {/*    primary={t(nav.name)}*/}
                                        {/*              sx={{*/}
                                        {/*                  opacity: props.isOpen ? 1 : 0,*/}
                                        {/*}}/>*/}
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Stack>

                    <Box>
                        <Divider/>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Grid item xs textAlign={'center'}>

                                <Tooltip title={props.isOpen ? '' : t("sidebar.account")} placement="left">
                                    <IconButton aria-label="Person2Outlined" onClick={handleClickOpenDialogProfile}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: 0,
                                                    mr: props.isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    color: "#FF7233"
                                                }}

                                    >

                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"

                                        >

                                            <Person2Outlined/>
                                            {
                                                props.isOpen && (<Typography color={`sidebar.${theme.palette.mode}`}>
                                                    {t("sidebar.account")}
                                                </Typography>)
                                            }
                                        </Stack>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs textAlign={'center'}>
                                <Tooltip title={props.isOpen ? '' : t("sidebar.about")} placement="left">
                                    <IconButton aria-label="Person2Outlined" onClick={handleClickOpenDialogInfo}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: 0,
                                                    mr: props.isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    color: "#FF7233"
                                                }}

                                    >

                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"

                                        >

                                            <HelpOutlineOutlinedIcon/>
                                            {
                                                props.isOpen && (<Typography color={`sidebar.${theme.palette.mode}`}>
                                                    {t("sidebar.about")}
                                                </Typography>)
                                            }
                                        </Stack>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider/>

                        <Tooltip title={props.isOpen ? '' : t("sidebar.account.disconnected")} placement="left">
                            <IconButton aria-label="Logout"
                                        sx={{
                                            width: '100%',
                                            borderRadius: 0,
                                            mr: props.isOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: "#FF7233"
                                        }}

                            >
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <LogoutIcon/>
                                    {
                                        props.isOpen && (<Typography color={`sidebar.${theme.palette.mode}`}>
                                            {t("sidebar.account.disconnected")}
                                        </Typography>)
                                    }
                                </Stack>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Stack>
            </Stack>


            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openDialogProfile}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialogProfile}
                aria-describedby="Dialog profile"

            >
                <DialogTitle>
                    <Typography gutterBottom variant="h4" component="div">
                        {/*    fixme : a changer pour mettre la variable de nom*/}
                        {t("sidebar.account.title", ['Mickaël'])}
                    </Typography>
                </DialogTitle>
                <Divider></Divider>
                <Grid container
                      spacing={3}
                      padding={2}
                >
                    <Grid item xs={8} minWidth={'325px'}>
                        <Card elevation={0} style={{border: '1px solid #b2b2b2'}}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {t("sidebar.account.setting.title")}
                                </Typography>
                                <Grid container
                                      direction="column"
                                      justifyContent="left"
                                      alignItems="left">
                                    <Grid item>
                                        <Grid container direction="row" justifyContent={'center'} alignItems={'center'}>
                                            <Grid item xs={2}>
                                                <Typography variant="subtitle1">RTF</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" column>
                                                        <FormControlLabel
                                                            value="0"
                                                            disabled={true}
                                                            control={<Switch color="primary"/>}
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justifyContent={'center'} alignItems={'center'}>
                                            <Grid item xs={2}>
                                                <Typography
                                                    variant="subtitle1">{t("sidebar.account.setting.langugage")}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Select
                                                    value={_userContext.userlang()}
                                                    onChange={(event) => {
                                                        _userContext.setUserLang(event.target.value)
                                                    }}
                                                    fullWidth
                                                >
                                                    {
                                                        langs.map((lang) => (
                                                            <MenuItem value={lang.code}>
                                                                <Grid container direction="row"
                                                                      justifyContent={'center'}
                                                                      alignItems={'center'}>
                                                                    <Grid item xs={2}>
                                                                        <img
                                                                            src={'data:image/png;base64, ' + lang.image_binary}
                                                                            height={'25px'}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        {lang.name}
                                                                    </Grid>
                                                                </Grid>
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={2}>
                                            <Typography
                                                variant="subtitle1">mode</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormControl>
                                                <FormLabel id="demo-theme-toggle">Theme</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-theme-toggle"
                                                    name="theme-toggle"
                                                    row
                                                    value={mode}
                                                    onChange={(event) => setMode(event.target.value)}
                                                >
                                                    <FormControlLabel value="system" control={<Radio />} label="System" />
                                                    <FormControlLabel value="light" control={<Radio />} label="Light" />
                                                    <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs minWidth={'200px'}>
                        <Card elevation={0} style={{border: '1px solid #b2b2b2'}}>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                    <LinkIcon style={{color: '#FF7233'}}/>
                                    <Typography variant="h5" component="div">
                                        {t("sidebar.account.helpful_link")}
                                    </Typography>
                                </Box>
                                <List>
                                    <Button
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                            color: '#FF7233',
                                            borderColor: '#FF7233'
                                        }}
                                        variant="outlined"
                                        href="#outlined-buttons">
                                        {t("sidebar.account.helpful_link.shop")}
                                    </Button>
                                    <Button style={{
                                        width: '100%',
                                        marginTop: '10px',
                                        color: '#FF7233',
                                        borderColor: '#FF7233'
                                    }}
                                            variant="outlined"
                                            href="#outlined-buttons">
                                        {t("sidebar.account.helpful_link.GCU")}
                                    </Button>
                                    <Button style={{
                                        width: '100%',
                                        marginTop: '10px',
                                        color: '#FF7233',
                                        borderColor: '#FF7233'
                                    }}
                                            variant="outlined"
                                            href="#outlined-buttons">
                                        {t("sidebar.account.helpful_link.FAQ")}
                                    </Button>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <DialogActions sx={{
                    justifyContent: 'flex-start'
                }}>
                    <Button style={{color: '#FF7233'}}
                            onClick={handleCloseDialogProfile}>{t("sidebar.account.disconnected")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openDialogInfo}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialogInfo}
                aria-describedby="Dialog profile"

            >
                <DialogTitle>
                    <Typography gutterBottom variant="h4" component="div">
                        {t('sidebar.about.title')}
                    </Typography>
                </DialogTitle>
                <Divider></Divider>

                <Card elevation={0} style={{border: '1px solid #b2b2b2'}}>
                    <CardContent>
                        <List>
                            <Button
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    color: '#FF7233',
                                    borderColor: '#FF7233'
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleCloseDialogInfo()
                                    navigate('/changelog')
                                }}
                            >
                                {t('sidebar.about.version_note')}
                            </Button>
                            <Button style={{
                                width: '100%',
                                marginTop: '10px',
                                color: '#FF7233',
                                borderColor: '#FF7233'
                            }}
                                    variant="outlined"
                                    href="#outlined-buttons">
                                {t('sidebar.about.documentation')}
                            </Button>
                            <Button style={{
                                width: '100%',
                                marginTop: '10px',
                                color: '#FF7233',
                                borderColor: '#FF7233'
                            }}
                                    variant="outlined"
                                    href="#outlined-buttons">
                                {t('sidebar.about.GCU')}
                            </Button>
                        </List>
                    </CardContent>
                </Card>

                <DialogActions sx={{
                    justifyContent: 'flex-start'
                }}>
                    {t('sidebar.about.version', [_licenceContext.allInfo.version])}
                </DialogActions>
            </Dialog>

        </>
    )
}