import * as React from "react";
import {Card, Grid} from "@mui/material";
import {Children, useContext, useEffect, useRef, useState} from "react";


export function CardDraggableReactFlow(props) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [newPosition, setNewPosition] = useState({x: 0, y: 0});
    const [newPositionCss, setNewPositionCSS] = useState('relative');
    const [initPos, setInitPos] = useState({x: 0, y: 0});
    const ref = useRef()


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const infoPosition = ref.current.getBoundingClientRect()
        setInitPos({
            x: infoPosition.x + (infoPosition.width / 2),
            y: infoPosition.y + (infoPosition.height / 2)
        })

    }, [windowDimensions.width, windowDimensions.height])




    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }


    function mouseDown(e) {
        setNewPositionCSS('absolute')
            document.addEventListener('mousemove', mouseMove)
            document.addEventListener('touchmove', touchMove)
            document.addEventListener('mouseup', mouseUp)
            document.addEventListener('touchend', touchUp)

    }

    const movement = (newX, newY ) => {
        const initY = initPos.y
        const initX = initPos.x

        setNewPosition({
            x: (newX - initX ),
            y: (newY - initY )
        })
    }

    function mouseMove(e) {
        let newX = e.clientX
        let newY = e.clientY
        movement(newX, newY)
    }

    function touchMove(e) {
        let newY = e.touches[0].clientY
        let newX = e.touches[0].clientX
        document.body.style.overflow = 'hidden';

        movement(newX, newY)

    }

    const removeListerner = () => {
        setNewPositionCSS('relative')
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('touchmove', touchMove)
        document.removeEventListener('mouseup', mouseUp)
        document.removeEventListener('touchend', touchUp)
    }



    useEffect(() => {
        const infoPosition = ref.current.getBoundingClientRect()
        setInitPos({
            x: infoPosition.x + (infoPosition.width / 2),
            y: infoPosition.y + (infoPosition.height / 2)
        })


    }, [])




    const pointerUp = (e) => {
        removeListerner()
        document.body.style.overflow = 'visible';

        setNewPosition({
            x: (0),
            y: (0)
        })
    }

    const mouseUp = (e)=>{
        console.log('mouseUp')
        pointerUp(e)
        props.onDrop(e, true,props.dataInfo)
    }

    const touchUp = (e)=>{
        console.log('touchUp')
        pointerUp(e)
        props.onDrop(e, false,props.dataInfo)
    }


    const getChild = ()=>{

        return Children.map(props.children, child =>(
            <Grid item >
                {child}
            </Grid>)
        );
    }

    const cardClassName = ()=>{
        let className = 'dndnodeext'
        if(props.cardType === 'END'){
            className += ' endPath'
        }
        if(props.cardType === 'TEMPLATE'){
            className += ' template'
        }
        return className
    }

    return (
        <Card
            ref={ref}
            className={cardClassName()}
            style={{
                transform: `translate(${newPosition.x}px, ${newPosition.y}px)`,
                position:newPositionCss,
                userSelect: 'none',
                textAlign:'left'

            }}
            height={props.height}
            onPointerDown={mouseDown}


        >
            {getChild()}
        </Card>
    )
}