import * as React from "react";
import {Backdrop, CircularProgress, Grid} from "@mui/material";

export function BackdropLoader(props) {

    const handleBackdropClose = ()=>{
        if(!props.lockBackdropClose) {
            props.setBackdropOpen(false)
        }
    }
    return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.backdropOpen}
                // onClick={handleBackdropClose}
            >
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <CircularProgress style={{color:'white'}} />
                    </Grid>
                    <Grid item>
                        {props.text}
                    </Grid>
                </Grid>
            </Backdrop>
    )

}
