import React from 'react';
import {useHandleConnections, Handle, useNodeId, useStore} from '@xyflow/react';

const selector = (s) => ({
    nodeLookup: s.nodeLookup,
    edges: s.edges,
});

const NodeCustomHandle = (props) => {

    const connections = useHandleConnections({
        type: props.type,
    });



    return (<Handle {...props} isConnectable={connections.length < props.isConnectable|| props?.multiConnect === true}></Handle>);
};

export default NodeCustomHandle;
