import * as React from 'react';
import Box from '@mui/material/Box';
import {useEffect, useRef, useState} from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Dropzone(props) {
    const ref = useRef()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [scrollPosition, setScrollPosition] = useState(getScroll);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        function handleScroll() {
            setScrollPosition(getScroll())
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        changeDropzoneInfo()
    }, []);

    useEffect(() => {
        changeDropzoneInfo()
    }, [windowDimensions.width, windowDimensions.height]);

    useEffect(() => {
        changeDropzoneInfo()
    }, [scrollPosition.scrollY]);


    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    function getScroll() {
        const { scrollY: scrollY} = window;
        return {
            scrollY
        };
    }

    const changeDropzoneInfo = ()=>{

        if(ref.current){
            const info = ref.current.getBoundingClientRect()
            props.setLocation(props.position, {
                xStart: info.x,
                xEnd:info.x+info.width,
                yStart:info.y ,
                yEnd:(info.y  +info.height),
                current:ref.current
            })
        }

    }
    const getClassNames = ()=>{
        let classNames = ['DropZoneScenario']
        if(props.alone){
            classNames.push("hoverY")
        }
        return classNames.join(" ")
    }

    return (
        <Stack
            spacing={2}
            ref={ref}
            className={getClassNames()}
            alignItems={'center'}
            justifyContent={'center'}
            color={'grey'}
            direction="column"
        >
            {props.alone && (
                <>
                    <Typography>Déposer ici</Typography>
                    <FileDownloadIcon />
                </>
            )}
        </Stack>
    );
}