import React, {memo, useContext, useEffect, useState} from 'react';
import {Position} from '@xyflow/react';
import NodeCustomHandle from '../NodeCustomHandle/NodeCustomHandle';
import {Card, Dialog, InputAdornment, Radio, Stack, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import tools from "../../../Utils/tools";
import {LanguageContext} from "../../../Context/LanguageContext";
import {UserContext} from "../../../Context/UserContext";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const NodeCustomEnd = (props) => {

    const _userContext = useContext(UserContext)
    const _languageContext = useContext(LanguageContext)

    const [txt, setTxt] = useState('')
    const [txtIsEmpty, setTxtIsEmpty] = useState(false)


    const [openUpdateDialog, setOpenUpdateDialog] = useState(false)

    console.log(props?.data?.confirmed, props?.data?.confirmed === true)

    const classNameIsConfirmed = () => {
        let className = "dndnode  react-react-flow__node-custom  aside dndnodeext endPath"
        if (isConfirmed) {
            className += ' edit'
        }
        return className
    }

    const defaultLangInfo = () => {
        const currentLang = _languageContext.lang.toLowerCase()
        //allLangList intersection

        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }

    const checkCreateTestCaseTextIsEmpty = () => {
        let isEmpty = false;
        if (txt.trim() === '') {
            isEmpty = true;
        }
        setTxtIsEmpty(isEmpty)
        return isEmpty
    }


    const languageInput = () => {
        const defaultLang = defaultLangInfo()

        return <TextField
            style={{
                background: "white"
            }}
            error={txtIsEmpty}
            fullWidth={true}
            label={defaultLang.code.toUpperCase()}
            required={true}
            onInput={(e) => {
                setTxt(e.target.value)
                if (checkCreateTestCaseTextIsEmpty()) {
                    e.preventDefault()
                } else {
                    setTxtIsEmpty(false)
                }
            }}
            variant={'outlined'}
            InputProps={{
                startAdornment: <InputAdornment position="start">
                    <img
                        src={'data:image/png;base64, ' + defaultLang.image_binary}
                        height={'25px'}
                    />
                </InputAdornment>,
            }}
            helperText={txtIsEmpty ? 'Le nom par defaut est vide' : ''}
        />
    }

    const isConfirmed = () => {
        const lang = defaultLangInfo().code.toLowerCase()
        return props.data.hasOwnProperty('label') && props.data.label.hasOwnProperty(lang) &&
            !tools.isEmptyOrUndefined(props.data?.label[lang])
    }

    useEffect(() => {
        setConfirmed(isConfirmed())
    }, [props.data.label]);

    const validConfirm = () => {
        const confirm = !checkCreateTestCaseTextIsEmpty()
        if (confirm) {
            const lang = defaultLangInfo().code.toLowerCase()
            if(!props.data.hasOwnProperty('label')){
                props.data['label'] = {}
            }
            props.data.label[lang] = txt
        }
        setConfirmed(confirm)
    }

    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        let name = <Box>{current}</Box>

        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = _languageContext.intersection(_languageContext.allLangList, [_languageContext.lang.toLowerCase()])[0]
            let flag = <></>

            if (_userContext.userlang() !== _languageContext.lang) {
                flag = <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            name = <Stack direction={'row'} spacing={2}>
                {flag}

                <Box
                    style={{userSelect: 'none'}}
                >{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }

    const [confirmed, setConfirmed] = useState(isConfirmed())
    return (
        <>
            <Card className={classNameIsConfirmed()} style={{
                minWidth: '150px',
                maxWidth: '300px',
                textAlign: 'center'
            }}>
                <NodeCustomHandle className={'target endPath'} type="target" position={Position.Top} isConnectable={1}
                />

                {
                    confirmed && (
                        <Stack
                            direction="column"
                            spacing={0.5}
                            justifyContent={"space-between"}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent={"space-between"}
                                alignItems={'end'}
                            >

                                <CheckCircleOutlineIcon
                                color={'success'}
                                />
                                <EditIcon onClick={()=>setOpenUpdateDialog(true)}/>
                            </Stack>
                            <Typography>
                                {txtLang(props.data.label)}
                            </Typography>

                        </Stack>
                    )
                }

                {
                    !confirmed && (
                        <Stack spacing={2}>
                            {languageInput()}
                            <Button color={'warning'} variant={"contained"} onClick={validConfirm}>Confirmer</Button>
                        </Stack>
                    )
                }
            </Card>

            <Dialog
                open={openUpdateDialog}
                onClose={()=>setOpenUpdateDialog(false)}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <Stack>
                    <Stack>
                        <Typography>
                            Choisir un type de User Story pour ce cheminement :
                        </Typography>
                        <Stack>
                            <CheckCircleOutlineIcon
                                color={'success'}
                            />
                            <Radio
                                checked={false}
                                onChange={()=>{}}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
};

export default memo(NodeCustomEnd);
