import * as React from "react";
import Box from "@mui/material/Box";
import ReactPlayer from 'react-player'
import {Grid} from "@mui/material";
export function StepFour(props) {
    props.setDisableButtonNext(false)
    return (
        <Box sx={{width: '100%', alignContent:'center'}}>
            <Grid container sx={{width: '100%'}} justifyContent="center">
                <Grid item>
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                        playing={true}
                        controls={true}
                        volume={0.5}
                        muted={false}
                    />
                </Grid>
            </Grid>
        </Box>
    )

}
