import * as React from "react";
import { useContext, useEffect, useState} from "react";
import {
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, InputAdornment,
    Paper, Stack,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import '../Components/ZoneDropScenario/DropZoneScenario.css'
import {WebSocketContext} from "../Context/WebSocketContext";
import Button from "@mui/material/Button";
import tools from "../Utils/tools";
import {InputSearch} from "../Components/InputSearch";
import {useNavigate} from "react-router";
import {BackdropLoader} from "../Components/BackdropLoader/BackdropLoader";
import {LanguageContext} from "../Context/LanguageContext";
import {UserContext} from "../Context/UserContext";
import Typography from "@mui/material/Typography";
import {TestCaseContext} from "../Context/Hook/TestCaseContext";


export function ListTestCase(props) {

    const [init, setInit] = useState(false);

    const _webSocketContext = useContext(WebSocketContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const _testCaseContext = useContext(TestCaseContext)

    const navigate = useNavigate();

    const [testCases, setTestCase] = useState(_testCaseContext.listTestCase);
    const [testCasesFiltred, setTestCasesFiltred] = useState(testCases);

    const [searchValue, setSearchValue] = useState('');

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [backdropText, setBackdropText] = useState('');


    const [createTestCase, setCreateTestCase] = useState(false);
    const [createTestCaseNameEmpty, setCreateTestCaseNameEmpty] = useState(false);
    const [createTestCaseName, setCreateTestCaseName] = useState('');


    const [openDialogLangNames, setOpenDialogLangNames] = useState(false)
    const [infoDialogLangNames, setInfoDialogLangNames] = useState({})

    const languageInput = (info) => {
        if (Object.keys(infoDialogLangNames).length === 0) {
            return <></>
        } else {
            let name = ""
            if (!tools.isEmptyOrUndefined(infoDialogLangNames) && Object.keys(infoDialogLangNames.lang).indexOf(info.code.toLowerCase()) !== -1) {
                name = infoDialogLangNames.lang[info.code.toLowerCase()]
            }
            return <Grid item xs={4}>

                <TextField
                    aria-readonly={true}
                    value={
                        name
                    }
                    onChange={() => {
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <img
                                src={'data:image/png;base64, ' + info.image_binary}
                                height={'25px'}
                            />
                        </InputAdornment>,
                    }}
                />
            </Grid>
        }
    }

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const handleCloseDialogLangNames = () => {
        setOpenDialogLangNames(false)
    }

    // const getTestsCases = () => {
    //     setBackdropText("Récupération de la liste des cas de tests")
    //
    //     const testCaseList = {
    //         path: 'test_case/list'
    //     }
    //
    //     const testCaseListId = _webSocketContext.send(testCaseList, (message) => {
    //         setOpenBackdrop(false)
    //         setTestCases(message.info.test_cases)
    //     })
    //
    //     //_webSocketContext.unsubscribe(testCaseListId)
    // }

const actionFunc = (message) => {
    setOpenBackdrop(false)
    props.setCurrentTestCase(message.info.id)
    navigate('/update_testcase')
}
    const createTestCaseWS = (name) => {
        setOpenBackdrop(true)
        setBackdropText('Création du cas de test : "' + name + '"')

        const createTestCase = {
            path: 'test_case/create',
            info: {
                name: name,
                lang: _languageContext.lang.toLowerCase()
            }
        }
        const createTestCaseId = _webSocketContext.send(createTestCase, actionFunc)

        //_webSocketContext.unsubscribe(createTestCaseId)


    }

    const deleteOneTestCase = (_id) => {

        let newTestCasesArray = []
        testCases.forEach((v, i) => {
            if (v._id !== _id) {
                newTestCasesArray.push(v)
            }
        })

        // setTestCases(newTestCasesArray)
        handleSearch(searchValue)

        const deleteTestCase = {
            path: 'test_case/delete',
            info: {
                id: _id
            }
        }

        const deleteTestCaseId = _webSocketContext.send(deleteTestCase, (message) => {
        })
        //_webSocketContext.unsubscribe(deleteTestCaseId)
    }

    if (!init) {
        //Initialisation
        setInit(true)
        props.setCurrentTestCase(null)
        // getTestsCases()

        setOpenBackdrop(false)
    }

    useEffect(() => {
        setTestCase(_testCaseContext.listTestCase)

    }, [_testCaseContext.listTestCase])
    useEffect(() => {
        handleSearch(searchValue)
    }, [testCases])


    const handleSearch = (e) => {
        setSearchValue(e)
        let filteredData = testCases;

        if (!tools.isEmptyOrUndefined(e)) {
            filteredData = testCases.filter((item) => {
                return item.lang[_languageContext.lang.toLowerCase()].toLowerCase().includes(e.toLowerCase());
            });
        }
        setTestCasesFiltred(filteredData);
    };

    const currentTestCaseLang = (testLangs) => {

        const allDefaultLangInfo = defaultLangInfo()
        const defaultFlag = allDefaultLangInfo.image_binary

        let testCaseLang = <Stack direction="row" spacing={2}>
            <Tooltip title={"Langue : " + allDefaultLangInfo.name} placement={'top'}>
                <img
                    src={'data:image/png;base64, ' + defaultFlag}
                    height={'25px'}
                />
            </Tooltip>
            <Box>{testLangs[_languageContext.lang.toLowerCase()]}</Box>
        </Stack>;

        if (Object.keys(testLangs).indexOf(_userContext.userlang().toLowerCase()) !== -1) {
            const testCaseLangTmp = testLangs[_userContext.userlang().toLowerCase()]
            if (!tools.isEmptyOrUndefined(testCaseLang)) {
                testCaseLang = <Typography
                    fontSize={'14px'}
                    width={'100%'}
                    textAlign={"left"}
                >
                    {testCaseLangTmp}
                </Typography>
            }
        }
        return testCaseLang
    }

    const displayCardStep = () => {


        let cardSteps = <TableContainer component={Paper}>
            <Table aria-label="simple table">

                <TableBody>
                    {testCasesFiltred.map((value) => (
                        <TableRow
                            key={value.lang[_userContext.userlang().toLowerCase()]}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs={4}>
                                        {currentTestCaseLang(value.lang)}
                                    </Grid>

                                    <Grid item textAlign={'right'}>

                                        <ButtonGroup variant="outlined" size={'small'}>
                                            <Button
                                                style={{
                                                    fontSize: "12px"
                                                }}
                                                onClick={() => {
                                                    props.setCurrentTestCase(value._id)
                                                    navigate("/update_testcase")
                                                }}
                                            >Modifier</Button>
                                            {
                                                _languageContext.availableLangList.length > 1 && (
                                                    <Button
                                                        style={{
                                                            fontSize: "12px"
                                                        }}
                                                        onClick={() => {
                                                            setInfoDialogLangNames(value)
                                                            setOpenDialogLangNames(true)
                                                        }}>Autre langues</Button>
                                                )
                                            }
                                            <Button
                                                style={{
                                                    fontSize: "12px"
                                                }}
                                                onClick={() => {
                                                    // deleteOneTestCase(value._id)
                                                }}
                                            >Duppliquer</Button>
                                            <Button
                                                style={{
                                                    fontSize: "12px"
                                                }}
                                                onClick={() => {
                                                    deleteOneTestCase(value._id)
                                                }}
                                            >Supprimer</Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>


                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        if (testCasesFiltred.length === 0 && init) {
            cardSteps = <p>
                You don't have test cases.
            </p>
        }

        return cardSteps
    }

    const checkCreateTestCaseTextIsEmtpy = () => {
        let isEmpty = false;
        if (createTestCaseName.trim() === '') {
            isEmpty = true;
        }
        setCreateTestCaseNameEmpty(isEmpty)
        return isEmpty
    }

    /**
     *
     * @returns {{}|null}
     */
    const defaultLangInfo = () => {
        const currentLang = _languageContext.lang.toLowerCase()
        //allLangList intersection

        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }

    /**
     *
     * @returns {{}|null}
     */
    const currentLangInfo = () => {
        const currentLang = _userContext.userlang().toLowerCase()
        //allLangList intersection

        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }

    const [langInformation, setLangInformation] = useState(currentLangInfo());
    const [defaultLangInformation, setdefaultLangInformation] = useState(defaultLangInfo());


    useEffect(() => {
        setLangInformation(currentLangInfo)
    }, [_userContext.lang])

    const addTestCase = () => {
        props.setCurrentTestCase(null)
        setCreateTestCase(true)
    }

    return (
        <>
            <Box sx={{
                width: '100%',
            }}>

                <InputSearch handleSearch={handleSearch} searchValue={searchValue} addElement={addTestCase} btnAdd={true}/>

                {displayCardStep()}

            </Box>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={createTestCase}
                onClose={() => {
                    setCreateTestCase(false)
                }}
            >
                <DialogTitle>
                    {"Créer un nouveau cas de test"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nom du cas de test dans la langue par défaut
                    </DialogContentText>
                    <br/>
                    <Stack spacing={2} direction={'column'}>
                        <TextField
                            error={createTestCaseNameEmpty}
                            fullWidth={true}
                            label={defaultLangInformation.code.toUpperCase()}
                            required={true}
                            onInput={(e) => {
                                setCreateTestCaseName(e.target.value)
                                if (checkCreateTestCaseTextIsEmtpy()) {
                                    e.preventDefault()
                                } else {
                                    setCreateTestCaseNameEmpty(false)
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <img
                                        src={'data:image/png;base64, ' + defaultLangInformation.image_binary}
                                        height={'25px'}
                                    />
                                </InputAdornment>,
                            }}
                            helperText={createTestCaseNameEmpty ? 'Le nom par defaut est vide' : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCreateTestCaseName('')
                        setCreateTestCaseNameEmpty(false)
                        setCreateTestCase(false)
                    }}>Annuler</Button>
                    <Button onClick={() => {
                        if (!checkCreateTestCaseTextIsEmtpy()) {
                            setCreateTestCaseNameEmpty(false)
                            setCreateTestCase(false)
                            createTestCaseWS(createTestCaseName)
                            setCreateTestCaseName('')
                        }
                    }} autoFocus>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
            <BackdropLoader backdropOpen={openBackdrop} setBackdropOpen={(e) => {
            }} text={backdropText}/>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                open={openDialogLangNames}
                onClose={handleCloseDialogLangNames}
            >
                <DialogTitle>Le nom du cas de test par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}>
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogLangNames}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
