import * as React from "react";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Alert,
    Backdrop, ButtonGroup,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow,
    TextField, Tooltip
} from "@mui/material";
import tools from '../../../Utils/tools';
import {
    ReactFlow,
    addEdge,
    Background,
    ConnectionLineType,
    Controls,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    Panel,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from '@xyflow/react';

import Grid from '@mui/material/Grid2';

import '@xyflow/react/dist/style.css';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ContextMenuNode from "../../../Components/ReactFlowCustom/ContextMenuNode";
import dagre from 'dagre';
import Button from "@mui/material/Button";
import {DialogUpdateProject} from "../../../Components/Dialog/UpdateProject/DialogUpdateProject/DialogUpdateProject";
import './UpdateProject.css';
import {NodeCustom} from '../../../Components/ReactFlowCustom/NodeCustom/NodeCustom';
import {NodeCustomEnd} from '../../../Components/ReactFlowCustom/NodeCustom/NodeCustomEnd';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import {WebSocketContext} from "../../../Context/WebSocketContext";
import DialogVideoRealTime from "../../../Components/Dialog/VideoRealTimeDialog/DialogVideoRealTime";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {LanguageContext} from "../../../Context/LanguageContext";
import {TranslationContext} from "../../../Context/TranslationContext";
import {UserContext} from "../../../Context/UserContext";
import {CardDraggableReactFlow} from "../../../Components/ReactFlowCustom/CardDraggableReactFlow";
import {ProjectContext} from "../../../Context/Hook/ProjectContext";


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

import DialogTemplateList from "../../../Components/Dialog/DialogTemplate/DialogTemplateList";
import {TestCaseContext} from "../../../Context/Hook/TestCaseContext";
import Snackbar from "@mui/material/Snackbar";
import {NodeCustomNotExist} from "../../../Components/ReactFlowCustom/NodeCustom/NodeCustomNotExist";
import {element} from "prop-types";
import {NodeCustomTemplate} from "../../../Components/ReactFlowCustom/NodeCustom/NodeCustomTemplate";
import {ButtonEdge} from "../../../Components/ReactFlowCustom/EdgeCustom/ButtonEdge";
import VideoDialog from "../../../Components/Dialog/VideoDialog/VideoDialog";
import {UpdateTestCase} from "../../updateTestCase";
import {BackdropLoader} from "../../../Components/BackdropLoader/BackdropLoader";
import {styled} from "@mui/material/styles";


const nodeTypes = {
    custom: NodeCustom,
    templateCustom: NodeCustomTemplate,
    endPathCustom: NodeCustomEnd,
    notExist: NodeCustomNotExist,
};


const edgesCustomTypes = {
    classic: "smoothstep",
    customWithCross: ButtonEdge,
};


const getId = () => `dndnode_${Date.now() + Math.random().toString().replaceAll('.', '')}`;



const FullHeightDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root ': {
        height: '100%',
    }
}));

export function UpdateProject(props) {
    const _projectExecutionContext = useContext(ProjectContext)
    const _languageContext = useContext(LanguageContext)
    const _userContext = useContext(UserContext)
    const {translation, translationForce} = useContext(TranslationContext)
    const _webSocketContext = useContext(WebSocketContext)
    const _testCaseContext = useContext(TestCaseContext)
    const [colorMode, setColorMode] = useState('light');//light, dark, system
    const [allEnPath, setAllEndPath] = useState([]);

    const nodeStart = {
        id: 'start',
        _id: 'start',
        type: 'input',
        data: {label: translation('project.step.start', 'Projects.json')},
        deletable: false,
        position: {x: 0, y: 0},
    };

    const finalNode = {
        _id: 'END_TEST',
        type: 'END_TEST',
        lang: translation('project.step.end', 'Projects.json'),
        deletable: true,
    }

    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([nodeStart]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const [openSnackbarNotExist, setOpenSnackbarNotExist] = useState(false);


    const [interactibility, setInteractibility] = useState(true);

    const [openSettings, setOpenSettings] = useState(null);
    const [testCases, setTestCases] = useState(_testCaseContext.listTestCase);
    const [menu, setMenu] = useState(null);
    const [init, setInit] = useState(false);
    const [loadEdgeAndNode, setLoadEdgeAndNode] = useState(true);
    const ref = useRef(null);

    const boxPaginate = useRef(null);
    const rightHelper = useRef(null);
    const navigate = useNavigate();

    const [flowIsLoad, setFlowIsLoad] = useState(false);
    const [pageIsLoad, setPageIsLoad] = useState(false);

    const [openDialogExecuteAll, setOpenDialogExecuteAll] = useState(false);
    const [openDialogVideoRealTime, setOpenDialogVideoRealTime] = useState(false);
    const [openDialogTranslationName, setOpenDialogTranslationName] = useState(false);

    const [openDialogVideoStep, setOpenDialogVideoStep] = useState('');
    const [openDialogCreateStep, setOpenDialogCreateStep] = useState(false);
    const [openDialogUpdateStep, setOpenDialogUpdateStep] = useState(false);
    const openDialogUpdateStepRed = useRef(null);


    const [createTestCaseNameEmpty, setCreateTestCaseNameEmpty] = useState(false);
    const [createTestCaseName, setCreateTestCaseName] = useState('');

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [backdropText, setBackdropText] = useState('');
    const [currentTestCase, setCurrentTestCase] = useState('');

    const [maxItemsPaginate, setMaxItemsPaginate] = useState(0);
    const [offsetItemsPaginate, setOffsetItemsPaginate] = useState(0);
    const [paginateTestCases, setPaginateTestCases] = useState([]);
    const [paginateNextDisabled, setPaginateNextDisabled] = useState(false)
    const [paginateBeforeDisabled, setPaginateBeforeDisabled] = useState(true)

    const [projectInfo, setProjectInfo] = useState(_projectExecutionContext.getProjectExecutedById(props.currentProject))
    const [endPathExcluded, setEndPathExcluded] = useState([])


    const [removeProjectImage, setRemoveProjectImage] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState({})

    const [openDialogTemplateList, setOpenDialogTemplateList] = useState(false)

    const [playTestWs, setPlayTestWs] = useState(null)
    const [wsTimeRealUpdate, setWsTimeRealUpdate] = useState(Date.now())

    let schemaLasted = [];
    const itemHeight = 50;

    if (tools.isEmptyOrUndefined(props.currentProject)) {
        navigate("/projects")
    }

    useEffect(() => {
        setTestCases(_testCaseContext.listTestCase)
    }, [_testCaseContext.listTestCase]);

    useEffect(() => {
        if (loadEdgeAndNode) {
            initializeNodesAndEdges()
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {

        setNodes((nds) =>
            nds.map((node) => {

                if (node._id === 'start') {
                    // it's important that you create a new object here
                    // in order to notify react flow about the change
                    node.data = {
                        ...node.data,
                        label: translationForce(_userContext.userlang(), 'project.step.start', 'Projects.json'),
                    };

                    // }else{
                    //     node.data = {
                    //         ...node.data,
                    //         label: translationForce(_languageContext.lang,'project.step.start', 'Projects.json'),
                    //     };
                }
                return node;

            })
        );

    }, [_userContext, setNodes]);


    const calculMaxItem = () => {
        if (boxPaginate.current !== null && rightHelper.current != null) {
            const box = boxPaginate.current;
            const maxItems = Math.floor(box.offsetHeight / itemHeight);

            setMaxItemsPaginate(maxItems - 1)
        }
    }


    useEffect(() => {
        if (rightHelper.current) {
            setRemoveProjectImage(rightHelper.current.offsetHeight < 360)
            calculMaxItem()
        }
    }, [rightHelper, windowDimensions]);


    useEffect(() => {

        function handleResize() {
            calculMaxItem()
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        calculMaxItem()
    }, []);


    const getAllAvailableTemplate = () => {
        let templates = [];
        if (projectInfo?.template !== undefined) {
            const tmpltKeys = Object.keys(projectInfo.template)

            tmpltKeys.forEach((index) => {
                const tmp = {
                    ...projectInfo.template[index],
                    _id: index,
                    type: 'TEMPLATE'
                }
                templates.push(tmp)
            })
        }
        return templates
    }


    const checkCreateTestCaseTextIsEmtpy = () => {
        let isEmpty = false;
        if (createTestCaseName.trim() === '') {
            isEmpty = true;
        }
        setCreateTestCaseNameEmpty(isEmpty)
        return isEmpty
    }

    /**
     *
     * @returns {{}|null}
     */
    const defaultLangInfo = () => {
        const currentLang = _languageContext.lang.toLowerCase()
        //allLangList intersection

        const langInfo = _languageContext.intersection(_languageContext.allLangList, [currentLang])
        return langInfo.length !== 0 ? langInfo[0] : null
    }

    const [defaultLangInformation, setdefaultLangInformation] = useState(defaultLangInfo());

    const initTestCases = () => {
        let testCasesArr = []
        testCases.forEach((testCase) => {
            const tmp = {
                ...testCase,
                type: 'STEP'
            }
            testCasesArr.push(tmp)
        })
        return testCasesArr
    }

    useEffect(() => {
        const allAvailableTemplate = getAllAvailableTemplate()
        const testCasesWithType = initTestCases()

        const allBlocks = allAvailableTemplate.concat(testCasesWithType)

        const current = allBlocks.slice(offsetItemsPaginate, offsetItemsPaginate + maxItemsPaginate)
        setPaginateTestCases(current)
        calculMaxItem()
    }, [offsetItemsPaginate, maxItemsPaginate, testCases, projectInfo.template]);


    const paginateNext = () => {
        const totalLength = testCases.length;
        const offset = offsetItemsPaginate;
        if (totalLength > offsetItemsPaginate + maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setOffsetItemsPaginate(offset + maxItemsPaginate)
        }
        setPaginateNextDisabled(totalLength <= offset + (maxItemsPaginate * 2))
    }
    const paginateBefore = () => {
        if (0 < offsetItemsPaginate - maxItemsPaginate) {
            setPaginateBeforeDisabled(false)
            setPaginateNextDisabled(false)
            setOffsetItemsPaginate(offsetItemsPaginate - maxItemsPaginate)
        } else {
            setOffsetItemsPaginate(0)
            setPaginateBeforeDisabled(true)
            setPaginateNextDisabled(false)
        }
    }

    const handleCloseDialogLangNames = () => {
        setOpenDialogTranslationName(false)
    }


    useEffect(() => {
        if (flowIsLoad) {
            setPaginateNextDisabled(maxItemsPaginate >= testCases.length)
            setPageIsLoad(true);
        }

    }, [flowIsLoad]);
    //
    // useEffect(() => {
    //     if (init) {
    //         const projectInfoContext = _projectExecutionContext.getProjectExecutedById(props.currentProject)
    //
    //         const test1 = {
    //             ...projectInfoContext,
    //             id: projectInfoContext._id
    //         }
    //         if (JSON.stringify(test1) !== JSON.stringify(projectInfo)) {
    //             setProjectInfo({
    //                 ...projectInfoContext,
    //                 fromWebsocket: Date.now()
    //             })
    //
    //             //Fixme refaire temp reel
    //
    //             // const currentTimeStamp = Date.now()
    //             // if (nodeTimestapm === null || nodeTimestapm <= currentTimeStamp - 50) {
    //             //
    //             //     setNodeTimestamp(currentTimeStamp)
    //             //
    //             //     //Add 1 for start step
    //             //     if (nodes.length === countElements(projectInfoContext.steps) + 1) {
    //             //         updateNodePosition(projectInfoContext)
    //             //     } else {
    //             //         initializeNodesAndEdges(projectInfoContext.steps)
    //             //     }
    //             // }
    //
    //         }
    //     }
    //
    // }, [_projectExecutionContext.listProject]);


    useEffect(() => {
        setTestCases(_testCaseContext.listTestCase)
    }, [_testCaseContext.listTestCase]);

    const StreamTest = (restrictions = []) => {
        setOpenDialogVideoRealTime(true)

        const startProject = {
            path: 'project/play',
            info: {
                project_id: props.currentProject,
                restriction: restrictions
            }
        }
        const startProjectId = _webSocketContext.send(startProject, (message) => {
            setPlayTestWs(message)
        })
        //_webSocketContext.unsubscribe(startProjectId)

    }

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge({
                        ...params,
                        // type: ConnectionLineType.Step,
                        type: 'customWithCross',
                        deletable: true,
                        style: {color: '#b2b2b2', width: '40px'},
                        animated: false,
                        markerEnd: {type: 'arrow', color: '#b2b2b2', width: '30px', height: '30px'},

                    },
                    eds
                )
            ),
        [setEdges]
    );

    const initializeNodesAndEdges = () => {

        const steps = projectInfo.schema.nodes
        if (steps.length > 0) {
            let initNodes = [];

            let containNotExist = false
            steps.forEach((step, i) => {
                const newNodeId = getId()
                // let tc = testCases.filter((testCase) => );
                let tc = tools.getFirstElement((testCases), (testCase) => {
                    return testCase._id === step?.data?.internalCaseId
                });

                let newNode = {}

                if (
                    (step.type === 'custom' && tc === null) ||
                    (step.type === 'templateCustom' && Object.keys(projectInfo.template).indexOf(step?.data?.internalCaseId) === -1)
                ) {
                    newNode = {
                        id: newNodeId,
                        type: 'notExist',
                        data: {
                            label: 'Not exist',
                            internalCaseId: 'NaN',
                        },
                        deletable: true,
                        position: {
                            x: step.position.x, y: step.position.y
                        },
                    }
                    containNotExist = true
                } else {
                    newNode = step
                }

                newNode = {
                    ...newNode,
                    hidden: false
                }
                initNodes.push(newNode)

            })


            setNodes(initNodes)
            setEdges(projectInfo.schema.edges)
        }
        setInit(true)
        setLoadEdgeAndNode(false)
        // setOpenSnackbarNotExist(containNotExist)
    }

    useEffect(() => {
        if (init) {
            if (reactFlowInstance && !loadEdgeAndNode) {
                reactFlowInstance?.fitView();
                setFlowIsLoad(true)
            }
        }
    }, [loadEdgeAndNode, init, reactFlowInstance]);


    const onDrop = useCallback(
        (event, isComputer, type) => {
            event.preventDefault();

            let pos = {}

            if (isComputer) {
                pos.x = event.clientX;
                pos.y = event.clientY;

            } else {
                pos.x = event.changedTouches[0].clientX;
                pos.y = event.changedTouches[0].clientY;
            }

            const refX = ref.current.offsetLeft;
            const refWidth = ref.current.offsetWidth;
            const refY = ref.current.offsetTop;
            const refHeight = ref.current.offsetHeight;

            if (!(pos.x >= refX && pos.x < refX + refWidth && pos.y >= refY && pos.y < refY + refHeight)) {
                return null
            }


            if (type === null) {
                return;
            }

            type = JSON.parse(type)
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
            // and you don't need to subtract the reactFlowBounds.left/top anymore
            // details: https://reactflow.dev/whats-new/2023-11-10
            const position = reactFlowInstance.screenToFlowPosition(pos);
            let newNode = {
                id: getId(),
                type: 'custom',
                position,
                data: {
                    label: type.lang,
                    internalCaseId: type._id,
                },
                deletable: true,
                hidden: false
            };

            if (type.type === "END_TEST") {
                newNode.type = 'endPathCustom'
                newNode.data.label = {}
            }

            if (type.type === "TEMPLATE") {
                newNode.type = 'templateCustom'
                newNode.data.multiConnect = true
                newNode.data.label = type.lang
            }
            setNodes((nds) => nds.concat(newNode));


        },
        [reactFlowInstance],
    );


    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onNodesDelete = useCallback(
        (deleted) => {
            setEdges(
                deleted.reduce((acc, node) => {
                    const incomers = getIncomers(node, nodes, edges);
                    const outgoers = getOutgoers(node, nodes, edges);
                    const connectedEdges = getConnectedEdges([node], edges);

                    const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

                    const createdEdges = incomers.flatMap(({id: source}) =>
                        outgoers.map(({id: target}) => ({
                            id: `${source}->${target}`,
                            source,
                            target,
                            type: 'customWithCross'
                        }))
                    );

                    return [...remainingEdges, ...createdEdges];
                }, edges)
            );
        },
        [nodes, edges]
    );


//Custom right click nodes
    const onNodeContextMenu = useCallback(
        (event, node) => {
            // Prevent native context menu from showing
            event.preventDefault();

            // Calculate position of the context menu. We want to make sure it
            // doesn't get positioned off-screen.
            const pane = ref.current.getBoundingClientRect();

            const menuWidth = 200
            const menuHeight = 200

            let top = event.clientY
            if (top > pane.height - menuHeight) {
                top = pane.height - menuHeight
            }

            let bottom = event.clientY
            if (bottom < pane.height) {
                bottom = pane.height
            }

            let left = event.clientX

            let right = event.clientX
            if (right > pane.width - menuWidth) {
                right = event.clientX - menuWidth
            }


            // setMenu({
            //     id: node.id,
            //     top: top,
            //     left: right,
            //     right: left,
            //     bottom: bottom
            // });

            setMenu({
                id: node.id,
                top: event.clientY < (pane.height - menuHeight) && event.clientY - 5,
                left: event.clientX < pane.width && event.clientX - 320,
                right: event.clientX >= pane.width && pane.width - event.clientX - 320,
                bottom:
                bottom,
            });

            // setMenu({
            //     id: node.id,
            //     top: event.clientY < pane.height && event.clientY - 100,
            //     left: event.clientX  < pane.width && event.clientX - 300,
            //     right: event.clientX >= pane.width && event.clientX - 300,
            //     bottom:
            //         event.clientY >= pane.height - 100 && event.clientY - 100,
            // });
        },
        [setMenu],
    );

// Close the context menu if it's open whenever the window is clicked.
    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);


    const getLayoutedElements = (nodes, edges) => {

        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));

        dagreGraph.setGraph({rankdir: 'TB', edgesep: 50, ranksep: 100, nodesep: 100});

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, {width: node.measured.width, height: node.measured.height});
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        // nodes.forEach((node) => {
        //     const nodeWithPosition = dagreGraph.node(node.id);
        //
        //     node.targetPosition = 'top';
        //     node.sourcePosition = 'bottom';
        //
        //     // We are shifting the dagre node position (anchor=center center) to the top left
        //     // so it matches the React Flow node anchor point (top left).
        //     node.position = {
        //         x: nodeWithPosition.x,
        //         y: nodeWithPosition.y - nodeHeight / 2,
        //     };
        //
        //     return node;
        // });
        //
        // return {nodes, edges};


        const newNodes = nodes.map((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            const newNode = {
                ...node,
                targetPosition: 'top',
                sourcePosition: 'bottom',
                // We are shifting the dagre node position (anchor=center center) to the top left
                // so it matches the React Flow node anchor point (top left).
                position: {
                    x: nodeWithPosition.x - node.measured.width / 2,
                    y: nodeWithPosition.y - node.measured.height / 2,
                },
            };

            return newNode;
        });

        return {nodes: newNodes, edges};
    };

    const onLayout = useCallback(
        (direction) => {
            const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(
                nodes,
                edges,
                direction
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );


    const handleClickSettingsOpen = () => {
        setOpenSettings(true);
    };

    const handleSettingsClose = () => {
        setOpenSettings(false);
    };

    /*    //Fixme: too much recursion lors du chargement

        const searchPathChild = (source, currentPath, paths, allInfo) => {
            const edgesConnected = allInfo.edges.filter((edge) => edge.source === source)

            const currentNode = tools.getFirstElement(allInfo.nodes, (node) => {
                return node.id === source
            })

            if (source !== 'start' && currentNode) {
                currentPath.push({
                    type: currentNode.type,
                    key: currentNode.data.internalCaseId,
                    front_id: currentNode.id,
                    front_parent_id: currentPath.length > 0 ? currentPath[currentPath.length - 1].front_id : "",
                    position: {
                        x: currentNode.position.x,
                        y: currentNode.position.y,
                    }
                })
            }

            if (edgesConnected.length !== 0) {
                edgesConnected.forEach((edge, i) => {
                        paths = searchPathChild(edge.target, [...currentPath], paths, allInfo)
                    }
                )
            } else {
                let nodePath = {
                    id:'',
                    lang: {},
                    steps: currentPath,
                    description:{}
                }
                if (currentNode.type === finalNode.type) {
                    //fixme mise en place du nom du cheminement dans un objet
                }

                //changer currentPath, pour un objet
                paths.push(currentPath)
            }

            return paths;
        }*/


    const onSave = useCallback(() => {
        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            if (JSON.stringify(flow) !== JSON.stringify(schemaLasted)) {
                schemaLasted = flow
                let tmpProjectInfo = {
                    ...projectInfo,
                    schema: flow
                }
                setProjectInfo(tmpProjectInfo)
            }

            /*       let schema = [];
                   const edgesConnected = flow.edges;

                   edgesConnected.forEach((edgeInfo, i) => {

                       if (edgeInfo.source === 'start') {
                           schema = searchPathChild('start', [], [], flow)
                       }
                   })
                   if (JSON.stringify(schema) !== JSON.stringify(schemaLasted)) {
                       schemaLasted = schema
                       let tmpProjectInfo = {
                           ...projectInfo,
                           steps: schema
                       }
                       if (edgesConnected.length !== edges.length) {
                           setProjectInfo(tmpProjectInfo)
                       }
                   }
       */
        }
    }, [reactFlowInstance]);

    useEffect(() => {

        const haveFromWs = Object.keys(projectInfo).indexOf('fromWebsocket') !== -1

        if (init && reactFlowInstance !== null &&
            (!haveFromWs || wsTimeRealUpdate === projectInfo.fromWebsocket)) {

            let tmpPjtInfo = projectInfo
            tmpPjtInfo.id = projectInfo._id


            const updateProject = {
                path: 'project/update',
                info: tmpPjtInfo
            }
            _webSocketContext.send(updateProject, (message) => {
            })
        }

        if (haveFromWs) {
            setWsTimeRealUpdate(projectInfo.fromWebsocket)
        }
    }, [projectInfo, init]);

    const readNameProject = () => {
        let name = '';
        const currentLang = _userContext.userlang().toLowerCase()

        if (Object.keys(projectInfo).length !== 0 && projectInfo.lang[currentLang] !== undefined) {
            name = projectInfo.lang[currentLang]
        }
        return name;
    }


    useEffect(() => {
        if (pageIsLoad) {
            onSave()
        }
    });

    const allAvailableLangWithInfo = () => {
        return _languageContext.intersection(_languageContext.allLangList, _languageContext.availableLangList)
    }

    const readNameProjectByLang = (lang) => {
        let name = '';

        if (Object.keys(projectInfo).length !== 0 && projectInfo.lang[lang] !== undefined) {
            const projectName = projectInfo.lang[lang]
            name = projectName === '' ? '' : projectName
        }

        return name;
    }

    const languageInput = (info) => {

        return <Grid size={4}>

            <TextField
                value={
                    readNameProjectByLang(info.code.toLowerCase())
                }
                onInput={(e) => {
                    updateProjectNameByLang(info.code.toLowerCase(), e.target.value)
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <img
                            src={'data:image/png;base64, ' + info.image_binary}
                            height={'25px'}
                        />
                    </InputAdornment>,
                }}
            />
        </Grid>
    }

    const updateProjectNameByLang = (lang, name) => {

        const updatedProjectInfo = {...projectInfo, lang: {...projectInfo.lang, [lang]: name}};
        setProjectInfo(updatedProjectInfo)

        //_webSocketContext.unsubscribe(updateProjectId)
    }

    const txtLang = (elem) => {
        let current = "";
        if (Object.keys(elem).indexOf(_userContext.userlang()) !== -1) {
            if (elem[_userContext.userlang()] !== '') {
                current = elem[_userContext.userlang()]
            }
        }
        let name = <Box>{current}</Box>

        if (tools.isEmptyOrUndefined(current)) {

            const defaultLangInfo = _languageContext.intersection(_languageContext.allLangList, [_languageContext.lang.toLowerCase()])[0]
            let flag = <></>

            if (_userContext.userlang() !== _languageContext.lang) {
                flag = <Tooltip title={
                    'Langue : ' + defaultLangInfo.name
                } placement="top">
                    <img height={'25px'} src={'data:image/png;base64, ' + defaultLangInfo.image_binary}
                         alt={defaultLangInfo.code}/>
                </Tooltip>

            }

            name = <Stack direction={'row'} spacing={2}>
                {flag}

                <Box
                    style={{userSelect: 'none'}}
                >{elem[_languageContext.lang]}</Box>
            </Stack>
        }
        return name
    }


    const projectNameAndUpdate = () => {
        let elem = <>
            <Grid item>
                <Input readOnly={true} value={readNameProject()}></Input>
            </Grid>
            <Grid item alignItems={"center"}

                  onClick={() => {
                      setOpenDialogTranslationName(true)
                  }}


            >
                <Typography color={'blue'} style={{
                    cursor: 'pointer',
                    textDecoration: 'underline blue',
                }}>{_userContext.userlang().toUpperCase()}</Typography>
            </Grid>
        </>

        if (_languageContext.availableLangList.length === 1) {

            elem = <>
                <Grid item>
                    <Input value={readNameProject()}
                           onInput={(e) => {
                               updateProjectNameByLang(_userContext.userlang().toLowerCase(), e.target.value)
                           }}
                    ></Input>

                </Grid>
            </>
        }


        return <Grid container justifyContent={"center"} spacing={2}
                     alignItems="center">
            {elem}
        </Grid>

    }


    const actions = [
        {
            icon: <ZoomInIcon/>,
            name: 'Zoom in',
            condition: true,
            function: () => reactFlowInstance?.zoomIn({duration: 400})
        },
        {
            icon: <ZoomOutIcon/>,
            name: 'Zoom Out',
            condition: true,
            function: () => reactFlowInstance?.zoomOut({duration: 400})
        },
        {
            icon: <CropFreeOutlinedIcon/>, name: 'focus', condition: true, function: () => {
                const {x, y, zoom} = reactFlowInstance.getViewport()
                reactFlowInstance.fitView({x, y, zoom, duration: 1000})
            }
        },
        {
            icon: <LockOutlinedIcon/>, name: 'Lock', condition: interactibility, function: () => {
                setInteractibility(false)

            }
        },
        {
            icon: <LockOpenOutlinedIcon/>, name: 'Unlock', condition: !interactibility, function: () => {
                setInteractibility(true)
            }
        },
        {
            icon: <AccountTreeOutlinedIcon/>, name: 'Reorder', condition: interactibility, function: () => {
                onLayout('TB')
            }
        },
        {
            icon: <SchemaOutlinedIcon/>, name: 'Templates', condition: interactibility, function: () => {
                setOpenDialogTemplateList(true)
            }
        },
        {
            icon: <DescriptionOutlinedIcon/>, name: 'Documentation', condition: true, function: () => {
                //todo
            }
        },
    ];


    useEffect(() => {
        if (init && !loadEdgeAndNode) {
            const newEdges = edges.map(edge => {
                return {
                    ...edge,
                    type: interactibility ? 'customWithCross' : 'smoothstep'
                };
            });
            setEdges(newEdges);

            if (!interactibility) {
                listNodeFinish()
            } else {
                setEndPathExcluded([])
                showAllNodes()
            }
        }
    }, [interactibility]);


    const listNodeFinish = () => {
        const flow = reactFlowInstance.toObject();

        const schema = searchPathEndParent('start', [], {node: [], edge: []}, flow, [])
        //search all end path
        let endPath = []
        schema.node.forEach((path) => {
            const length = path.length
            if (length > 0) {
                const nodeEndInfo = tools.getFirstElement(flow.nodes, (node) => node.id === path[length - 1])
                if (nodeEndInfo !== null && nodeEndInfo.data.hasOwnProperty('label')) {
                    endPath.push(nodeEndInfo)
                }
            }
        })
        setAllEndPath(endPath)

        let allNodeVisible = []
        //search all end path
        schema.node.forEach((path) => {
            const length = path.length
            if (length > 0) {
                allNodeVisible.push(...path)

            }
        })
        hideNodes(allNodeVisible)
    }


    const searchPathEndParent = (source, currentPathNode, paths, allInfo, excludeEnd) => {
        const edgesConnected = allInfo.edges.filter((edge) => edge.source === source)
        const currentNode = tools.getFirstElement(allInfo.nodes, (node) => node.id === source)

        if (source !== 'start' && currentNode !== null) {
            currentPathNode.push(currentNode.id)
        }

        // console.log(currentNode)
        if (edgesConnected.length !== 0) {
            edgesConnected.forEach((edge, i) => {

                    paths.edge.push(edge.id)
                    paths = searchPathEndParent(edge.target, [...currentPathNode], paths, allInfo, excludeEnd)
                }
            )
        } else if (currentNode != null && currentNode?.type === 'endPathCustom' && !excludeEnd.includes(currentNode.id)) {
            paths.node.push(currentPathNode)
        }
        return paths;
    }

    const toggleHideNodes = (endNodeId) => {
        const index = endPathExcluded.indexOf(endNodeId)
        let allEndPathExcluded = endPathExcluded
        if (index === -1) {
            allEndPathExcluded = endPathExcluded.concat([endNodeId])
        } else {
            endPathExcluded.splice(index, 1)
            allEndPathExcluded = endPathExcluded
        }
        setEndPathExcluded(allEndPathExcluded)

        const flow = reactFlowInstance.toObject();

        let allNodeVisible = []
        const schema = searchPathEndParent('start', [], {node: [], edge: []}, flow, allEndPathExcluded)
        //search all end path
        schema.node.forEach((path) => {
            const length = path.length
            if (length > 0) {
                allNodeVisible.push(...path)

            }
        })
        hideNodes(allNodeVisible)
    }

    const hideNodes = (allNodeVisible) => {

        setNodes((nds) =>
            nds.map((node) => {

                let updateNode = {
                    ...node,
                    hidden: true
                };
                if (allNodeVisible.includes(node.id) || node.id === "start") {
                    // it's important that you create a new node object
                    // in order to notify react flow about the change
                    return {
                        ...node,
                        hidden: false
                    };
                }

                return updateNode;
            }),
        );
        tools.sleep(100).then(() => {
            const {x, y, zoom} = reactFlowInstance.getViewport()
            reactFlowInstance.fitView({x, y, zoom, duration: 1000})
        })

    }

    const showAllNodes = () => {


        setNodes((nds) =>
            nds.map((node) => {
                return {
                    ...node,
                    hidden: false
                };

            }),
        );
        tools.sleep(100).then(() => {
            const {x, y, zoom} = reactFlowInstance.getViewport()
            reactFlowInstance.fitView({x, y, zoom, duration: 1000})
        })
    }

    const openVideoStep = (stepId) => {
        // setOpenDialogVideoStep(`http://75.119.139.248:8080/api/${projectInfo.id}/getVideo/${stepId}`)
        console.log(stepId)
        setOpenDialogVideoStep(stepId)

    }


    const actionFunc = (message) => {
        setOpenBackdrop(false)
        setCurrentTestCase(message.info.id)
        // navigate('/update_testcase')
        setOpenDialogUpdateStep(true)
    }
    const createTestCaseWS = (name) => {
        setOpenBackdrop(true)
        setBackdropText('Création du cas de test : "' + name + '"')

        const createTestCase = {
            path: 'test_case/create',
            info: {
                name: name,
                lang: _languageContext.lang.toLowerCase()
            }
        }

        const createTestCaseId = _webSocketContext.send(createTestCase, actionFunc)


    }



    return (
        <>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={!pageIsLoad}
            >

                <CircularProgress color="inherit"/>
            </Backdrop>

            <Grid
                container
                justifyContent={'center'}
                spacing={2}
                style={{width: '100%'}}
            >
                <Grid size={'grow'} minWidth={360}>

                    <Card style={{height: '100%'}}>
                        <ReactFlow
                            ref={ref}
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgesCustomTypes}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}


                            colorMode={colorMode}

                            onConnect={onConnect}
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            onNodesDelete={onNodesDelete}
                            onDragOver={onDragOver}

                            fitView
                            className="touchdevice-flow"

                            onPaneClick={onPaneClick}
                            onNodeContextMenu={onNodeContextMenu}
                            connectionLineType={interactibility ? edgesCustomTypes.customWithCross : edgesCustomTypes.classic}

                            edgesUpdatable={interactibility}
                            edgesFocusable={interactibility}
                            nodesDraggable={interactibility}
                            nodesConnectable={interactibility}
                            nodesFocusable={interactibility}
                            elementsSelectable={interactibility}
                        >
                            <Panel position="top-right">

                                <SpeedDial
                                    ariaLabel="SpeedDial basic example"
                                    direction={'down'}
                                    icon={<SpeedDialIcon/>}
                                >
                                    {actions.map((action) => {
                                        if (action.condition) {
                                            return <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={action.function}
                                            />
                                        }
                                    })}
                                </SpeedDial>

                                {/*<Stack*/}
                                {/*    direction="column">*/}

                                {/*    <button onClick={() => onLayout('TB')}>Réorganiser</button>*/}
                                {/*    <button onClick={() => onLayout('TB')}>Templates</button>*/}
                                {/*</Stack>*/}
                            </Panel>
                            <Background/>

                            {menu &&
                                <ContextMenuNode onLayout={onLayout} onClick={onPaneClick} {...menu}
                                                 setOpenDialogExecuteAll={setOpenDialogExecuteAll}/>}
                            {/*<Controls  activeChange={setInteractibility}/>*/}
                        </ReactFlow>
                    </Card>

                </Grid>
                <Grid size={3} minWidth={330}>
                    <Card sx={{padding: '2px'}}>

                        <Stack
                            direction="column"
                            justify="space-between"
                            alignItems="center"
                            width={'100%'}
                            height={'95vh'}
                            ref={rightHelper}
                        >
                            <Box width={'100%'}>
                                {
                                    !removeProjectImage && (
                                        <>
                                            <Box style={{
                                                backgroundImage: "url('https://img.freepik.com/vecteurs-libre/homme-affaires-crayon-femme-affaires-debout-ordinateur_1262-21451.jpg?w=1380&t=st=1711008411~exp=1711009011~hmac=cd94c666261e1e11026a667c7eba3ed4529650a2c2b846f03eacdcd776ef9549')",
                                                backgroundRepeat: 'none',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                width: '100%',
                                                height: '150px',
                                                textAlign: 'right'
                                            }}>
                                            </Box>
                                            {
                                                projectNameAndUpdate()
                                            }
                                            <Divider sx={{margin: '5px 2px'}}></Divider>
                                        </>
                                    )
                                }

                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-around'}
                                    alignContent={'center'}
                                    sx={{
                                        marginTop: removeProjectImage ? '5px' : '0'
                                    }}
                                >

                                    {
                                        projectInfo.urlExecution === '' && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (!projectInfo.isExecuted) {
                                                        setOpenDialogExecuteAll(true)
                                                    }
                                                }}
                                                disabled={projectInfo.isExecuted}
                                                sx={{
                                                    borderColor: '#FF7233',
                                                    background: '#FF7233'
                                                }}
                                            >
                                                {!projectInfo.isExecuted ? 'Tout exécuter' : 'En cours d\'exécution'}
                                            </Button>)
                                    }

                                    {
                                        projectInfo.urlExecution !== '' && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setOpenDialogVideoRealTime(true)
                                                }}

                                                sx={{
                                                    borderColor: '#FF7233',
                                                    background: '#FF7233'
                                                }}
                                            >
                                                Visualiser
                                            </Button>)
                                    }
                                    <Button variant="contained"
                                            onClick={handleClickSettingsOpen}

                                            sx={{
                                                borderColor: '#FF7233',
                                                background: '#FF7233'
                                            }}
                                    >Plus d'options </Button>


                                </Stack>

                                <Divider sx={{margin: '5px 2px'}}></Divider>

                                <Box sx={{
                                    display: 'block',
                                    margin: '5px'
                                }}>
                                    {/*<InputSearch/>*/}

                                </Box>
                            </Box>
                            {interactibility && (
                                <>
                                    <Box ref={boxPaginate} width={'100%'} height={'100%'}>

                                        <Button variant={'outlined'} fullWidth={true} sx={{
                                            borderColor: '#FF7233',
                                            color: '#FF7233'
                                        }}
                                                onClick={() => {
                                                    setOpenDialogCreateStep(true)
                                                }}

                                        >
                                            Créer une étape
                                        </Button>

                                        <CardDraggableReactFlow
                                            dataInfo={JSON.stringify(finalNode)}
                                            height={itemHeight + 'px'}
                                            onDrop={onDrop}
                                            cardType='END'
                                        >
                                            {
                                                translation('project.step.ending', 'Projects.json')
                                            }
                                        </CardDraggableReactFlow>


                                        <Divider variant="middle"/>
                                        {
                                            paginateTestCases.map((v, i) => (

                                                <CardDraggableReactFlow
                                                    dataInfo={JSON.stringify(v)}
                                                    height={itemHeight + 'px'}
                                                    onDrop={onDrop}
                                                    cardType={v.type}
                                                >
                                                    {
                                                        txtLang(v.lang)
                                                    }
                                                </CardDraggableReactFlow>
                                            ))
                                        }
                                    </Box>
                                    <Box sx={{display: 'block', alignItems: 'center', width: '100%'}} bottom={0}>
                                        <Divider/>
                                        <div style={{padding: "10px",}}>
                                            <IconButton
                                                aria-label="previous"
                                                fontSize="large"

                                                style={{
                                                    color: paginateBeforeDisabled ? 'grey' : "#FF7233"
                                                }}
                                                onClick={() => {
                                                    paginateBefore()
                                                }}
                                            >
                                                <NavigateBeforeIcon/>
                                            </IconButton>
                                            <span style={{width: "5vw", display: 'inline-block'}}/>
                                            <IconButton aria-label="next"
                                                        style={{
                                                            color: paginateNextDisabled ? 'grey' : "#FF7233"
                                                        }}
                                                        onClick={() => {
                                                            paginateNext()
                                                        }}
                                                        disabled={paginateNextDisabled}
                                            >
                                                <NavigateNextIcon/>
                                            </IconButton>
                                        </div>
                                    </Box>
                                </>
                            )
                            }
                            {!interactibility && (
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>

                                            {allEnPath.map((endPathInfo) => (
                                                    <TableRow
                                                        key={0}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Grid size={4}>
                                                                    {txtLang(endPathInfo.data?.label)}
                                                                </Grid>

                                                                <Grid item textAlign={'right'}>
                                                                    <ButtonGroup variant="outlined" size={'small'}>
                                                                        {projectInfo?.videos &&
                                                                            projectInfo.videos.hasOwnProperty(endPathInfo.id) &&
                                                                            projectInfo.videos[projectInfo.videos] !== '' &&
                                                                            (
                                                                                <Button
                                                                                    style={{
                                                                                        fontSize: "10px"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        // toggleHideNodes(endPathInfo.id)
                                                                                        // playVideoTest(endPathInfo.id)
                                                                                        openVideoStep(endPathInfo.id)
                                                                                    }}
                                                                                >

                                                                                    <PlayArrowIcon/>

                                                                                </Button>
                                                                            )
                                                                        }
                                                                        <Button
                                                                            style={{
                                                                                fontSize: "10px"
                                                                            }}
                                                                            onClick={() => {
                                                                                // toggleHideNodes(endPathInfo.id)
                                                                                StreamTest([endPathInfo.id])

                                                                            }}
                                                                        >
                                                                            <BugReportOutlinedIcon/>
                                                                        </Button>
                                                                        <Button
                                                                            style={{
                                                                                fontSize: "10px"
                                                                            }}
                                                                            onClick={() => {
                                                                                toggleHideNodes(endPathInfo.id)
                                                                            }}
                                                                        >
                                                                            <AnalyticsOutlinedIcon/>
                                                                        </Button>
                                                                        <Button
                                                                            style={{
                                                                                fontSize: "10px"
                                                                            }}
                                                                            onClick={() => {
                                                                                toggleHideNodes(endPathInfo.id)
                                                                            }}
                                                                        >
                                                                            {
                                                                                endPathExcluded.includes(endPathInfo.id) ?
                                                                                    <VisibilityOffIcon/> :
                                                                                    <VisibilityIcon/>
                                                                            }
                                                                        </Button>

                                                                    </ButtonGroup>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}


                        </Stack>


                    </Card>


                </Grid>
            </Grid>
            <DialogUpdateProject handleSettingsClose={handleSettingsClose}
                                 openSettings={openSettings}></DialogUpdateProject>

            <Dialog
                open={openDialogExecuteAll}
                onClose={() => {
                    setOpenDialogExecuteAll(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Souhaitez-vous exécuter tout le projet ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        L'exécution manuelle s'avère plus lente en raison de l'absence de simultanéité dans le
                        traitement
                        des cheminements.<br/><br/>

                        <span style={{
                            color: 'red',
                            textDecoration: 'underline'
                        }}>Attention :</span>
                        <span> L'exécution intégrale du projet peut entrainer une baisse conséquante de vos crédits.<br/><br/></span>

                        Souhaitez-vous continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialogExecuteAll(false)
                    }}>
                        Annuler
                    </Button>
                    <Button onClick={() => {
                        setOpenDialogExecuteAll(false)
                        StreamTest()
                    }} autoFocus>Continuer</Button>
                </DialogActions>
            </Dialog>

            {openDialogVideoRealTime && (<DialogVideoRealTime
                isOpen={openDialogVideoRealTime}
                setIsOpen={setOpenDialogVideoRealTime}
                playTestWs={playTestWs}
                url={projectInfo.urlExecution}
            ></DialogVideoRealTime>)}


            {openDialogVideoStep !== '' && (<VideoDialog
                isOpen={openDialogVideoStep !== null}
                setIsOpen={setOpenDialogVideoStep}
                stepId={openDialogVideoStep}
                projectInfo={projectInfo}
                schema={reactFlowInstance.toObject()}
            ></VideoDialog>)}


            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                sx={{height: '100% !important'}}
                scroll={'paper'}
                open={openDialogTranslationName}
                onClose={handleCloseDialogLangNames}
            >
                <DialogTitle>Le nom du projet par langue</DialogTitle>
                <DialogContent dividers={true}>

                    <Grid container spacing={3}>
                        {
                            allAvailableLangWithInfo().map((languague, i) => (
                                languageInput(languague)
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogLangNames}>Close</Button>
                </DialogActions>
            </Dialog>

            <FullHeightDialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openDialogUpdateStep}
                onClose={() => {
                    setOpenDialogUpdateStep(false)
                }}
                scroll={'paper'}
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Subscribe
                </DialogTitle>
                <DialogContent ref={openDialogUpdateStepRed}
                               sx={{overflow:'hidden'}}>
                    <UpdateTestCase
                        isOpen={props.isOpen}
                        isComputer={props.isComputer}
                        widthSidebar={props.widthSidebar}
                        widthClosedSidebar={props.widthClosedSidebar}
                        setCurrentTestCase={setCurrentTestCase}
                        currentTestCase={currentTestCase}
                        parentRef={openDialogUpdateStepRed?.current?.clientHeight}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialogUpdateStep(false)
                    }}>Fermer</Button>
                </DialogActions>
            </FullHeightDialog>

            <DialogTemplateList
                open={openDialogTemplateList}
                setOpen={setOpenDialogTemplateList}
                projectInfo={projectInfo}
                setProjectInfo={setProjectInfo}
                testCases={testCases}
            />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openDialogCreateStep}
                onClose={() => {
                    setOpenDialogCreateStep(false)
                }}
            >
                <DialogTitle>
                    {"Créer un nouveau cas de test"}

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nom du cas de test dans la langue par défaut
                    </DialogContentText>
                    <br/>
                    <Stack spacing={2} direction={'column'}>
                        <TextField
                            error={createTestCaseNameEmpty}
                            fullWidth={true}
                            label={defaultLangInformation.code.toUpperCase()}
                            required={true}
                            onInput={(e) => {
                                setCreateTestCaseName(e.target.value)
                                if (checkCreateTestCaseTextIsEmtpy()) {
                                    e.preventDefault()
                                } else {
                                    setCreateTestCaseNameEmpty(false)
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <img
                                        src={'data:image/png;base64, ' + defaultLangInformation.image_binary}
                                        height={'25px'}
                                    />
                                </InputAdornment>,
                            }}
                            helperText={createTestCaseNameEmpty ? 'Le nom par defaut est vide' : ''}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCreateTestCaseName('')
                        setCreateTestCaseNameEmpty(false)
                        setOpenDialogCreateStep(false)
                    }}>Annuler</Button>
                    <Button onClick={() => {
                        if (!checkCreateTestCaseTextIsEmtpy()) {
                            setCreateTestCaseNameEmpty(false)
                            setOpenDialogCreateStep(false)
                            createTestCaseWS(createTestCaseName)
                            setCreateTestCaseName('')
                        }
                    }} autoFocus>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
            <BackdropLoader backdropOpen={openBackdrop} text={backdropText}/>
            <Snackbar open={openSnackbarNotExist} autoHideDuration={6000} onClose={
                () => {
                    setOpenSnackbarNotExist(false)
                }
            }>
                <Alert
                    onClose={() => {
                        setOpenSnackbarNotExist(false)
                    }}
                    severity="error"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Des case de test n'existent plus, veuillez corriger les cheminements pour pouvoir re-exécuter le
                    cheminement en erreur.
                </Alert>
            </Snackbar>
        </>

    )

}
