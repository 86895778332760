import * as React from "react";
import {Dialog, DialogContent, LinearProgress} from "@mui/material";
import DialogContext from "@mui/material/Dialog/DialogContext";

export function DialogLoader(props) {

    const handleDialogClose = ()=>{
        props.setDialogOpen(false)
    }
    return (
            <Dialog
                open={props.dialogOpen}
                // onClick={handleDialogClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogContent>
                    {props.text}
                    <LinearProgress variant="determinate" value={props.progress} />
                </DialogContent>
            </Dialog>
    )

}
