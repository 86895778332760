import * as React from 'react';
import Box from "@mui/material/Box";
import {Card, Grid} from "@mui/material";
import CardDeveloper from "../Components/Card/developer/CardDeveloper";


export default function AdminPanel() {

    return (
        <Grid container
              direction="row"
              justifyContent="space-around"
              alignItems="space-around"
        >
            <Grid Item xs={3.5} minWidth={"200px"}>
                <CardDeveloper card={'language'}></CardDeveloper>
            </Grid>
            <Grid Item xs={3.5}  minWidth={"200px"}>
                <CardDeveloper card={'default_language'}></CardDeveloper>
            </Grid>
            {/*<Grid Item >*/}
            {/*    <CardDeveloper card={'cicd'}></CardDeveloper>*/}
            {/*</Grid>*/}
            {/*<Grid Item >*/}
            {/*    <CardDeveloper card={'task_planned'}></CardDeveloper>*/}
            {/*</Grid>*/}
            {/*<Grid Item >*/}
            {/*    <CardDeveloper card={'doc_dev'}></CardDeveloper>*/}
            {/*</Grid>*/}
        </Grid>
    )
}